import React, { useEffect, useMemo, useState } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { GET_CONTENT_TREE } from "components/Section/Design/Advertising/graphql/queries/signage";
import Icon from "components/Icon";
import { DestinationType } from "../utils/utils";
import { useTranslation } from "react-i18next";

const GET_DESIGNS_DATA = gql`
    query GetDesignsData {
        designs {
            results {
                id
                isAssignedFromCorporate: corporateDefault
                isAssigned
                name
            }
            projectAssignment {
                defaultDesignID
                designID
                projectID
                projectRef
                projectName
            }
        }
    }
`;

const AlertIcon = ({ marginClass, tooltip, iconType, iconSize = 1.5 }) => (
    <div className={`${marginClass} flex items-center justify-center`}>
        <Icon tooltip={tooltip} type={iconType} size={iconSize} />
    </div>
);

export const useScreenLinkAlert = () => {
    const [designApplied, setDesignApplied] = useState(null);
    const [screensDirectory, setScreensDirectory] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    const [fetchDesigns, { data: designsData, error: designsError }] = useLazyQuery(GET_DESIGNS_DATA);
    const [fetchContentTree, { data: contentTreeData }] = useLazyQuery(GET_CONTENT_TREE);

    const designs = useMemo(() => designsData?.designs?.results || [], [designsData]);
    const projectAssignment = useMemo(() => designsData?.designs?.projectAssignment || null, [designsData]);

    const corporateAssignedDesign = useMemo(() => {
        if (!projectAssignment?.designID) return null;
        return designs.find((design) => design.id === projectAssignment.designID) || null;
    }, [designs, projectAssignment]);

    useEffect(() => {
        fetchDesigns();
    }, [fetchDesigns]);

    useEffect(() => {
        if (!designs.length) return;
        const foundDesign = designs.find((d) => d.isAssigned || d.isAssignedFromCorporate) || corporateAssignedDesign;
        setDesignApplied((prevDesign) => {
            const isNewDesign = foundDesign && prevDesign?.id !== foundDesign.id;
            return isNewDesign ? foundDesign : prevDesign;
        });
    }, [designs, corporateAssignedDesign]);

    useEffect(() => {
        if (designApplied?.id) {
            fetchContentTree({ variables: { designID: designApplied.id } });
        }
    }, [designApplied?.id, fetchContentTree]);

    useEffect(() => {
        if (contentTreeData?.contentTree?.screens?.dir) {
            setScreensDirectory(contentTreeData.contentTree.screens.dir);
        }
    }, [contentTreeData]);

    useEffect(() => {
        if (designApplied?.id && screensDirectory) {
            setLoading(false);
        }
    }, [designApplied?.id, screensDirectory]);

    const getContentById = (contentId) => {
        if (!screensDirectory?.contents) return null;

        const directMatch = screensDirectory.contents.find((item) => item.id === contentId);
        if (directMatch) return directMatch;

        for (const subdir of screensDirectory.subDirs || []) {
            const matchInSubdir = subdir.contents?.find((item) => item.id === contentId);
            if (matchInSubdir) return matchInSubdir;
        }

        return null;
    };

    const renderNavigationInterestPointAlert = (navigationAction) => {
        const hasNavigationAction = navigationAction && navigationAction.type !== DestinationType.DISABLED;
        const navigationContentId = navigationAction?.type === DestinationType.SCREEN ? navigationAction?.value : null;
        const navigationContentLost = navigationContentId && !getContentById(Number(navigationContentId));

        if (navigationContentLost && !loading) {
            return (
                <AlertIcon
                    marginClass="mr-2"
                    tooltip={t("the-navigation-link-has-been-lost-due-to-the-applied-design-changed")}
                    iconType="warning"
                />
            );
        } else if (hasNavigationAction && !loading) {
            return <AlertIcon marginClass="mr-2" iconType="action" />;
        }
        return null;
    };

    const renderMapNavigationAlert = (interestPoints) => {
        const navigationContentLost = interestPoints.some((interestPoint) => {
            if (interestPoint.navigationAction?.type === DestinationType.SCREEN) {
                const navigationContentId = interestPoint.navigationAction.value;
                return navigationContentId && !getContentById(Number(navigationContentId));
            }
            return false;
        });

        return navigationContentLost && !loading ? (
            <AlertIcon marginClass="ml-2" tooltip={t("lost-link-to-my-screen")} iconType="warning" />
        ) : null;
    };

    const renderSignageNavigationAlert = (contents) => {
        const navigationContentLost = contents?.some(
            (content) => content?.type === "SCREEN" && !getContentById(Number(content?.ref))
        );

        return navigationContentLost && !loading ? (
            <AlertIcon marginClass="ml-2" tooltip={t("lost-link-to-my-screen")} iconType="warning" />
        ) : null;
    };

    const renderBannerNavigationAlert = (contentAction) => {
        if (contentAction?.type === DestinationType.SCREEN) {
            const contentId = contentAction?.value;
            if (contentId && !getContentById(Number(contentId)) && !loading) {
                return <AlertIcon marginClass="ml-2" tooltip={t("lost-link-to-my-screen")} iconType="warning" />;
            }
        }
        return null;
    };

    return {
        designApplied,
        screensDirectory,
        getContentById,
        designsError,
        loading,
        renderNavigationInterestPointAlert,
        corporateAssignedDesign,
        projectAssignment,
        renderMapNavigationAlert,
        renderSignageNavigationAlert,
        renderBannerNavigationAlert,
    };
};
