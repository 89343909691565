import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useSearchParams, useNavigate as useHistory } from "react-router-dom";
import { safeJsonParse, safeJsonStringify } from "hooks/Utils/Utils";

import ErrorInfo from "components/ErrorInfo";
import Table from "components/ZafiroTable";
import Tag from "components/Tag";
import Icon from "components/Icon";
import Button from "components/Button";

import { Header } from "sections/playground";

const API_URL = "https://gitlab.zafiro.link/api/v4";
const PROJECT_QA = 88;
const TOKEN = "glpat-qcwzyE1MWNCMjJWAsSN4";

const LOCALE = "es-ES";

const CriticalPriority = "1 - Critical priority";
const HighPriority = "2+ High priority";
const NormalPriority = "2 - Normal priority";
const MinorPriority = "3 - Minor priority";
const Dismissed = "4 - Very Low(dismissed)";
const IssuePriority = [CriticalPriority, HighPriority, NormalPriority, MinorPriority, Dismissed];

const OriginRegression = "Regresion";
const OriginSprint = "Tarjeta";
const Origin = [OriginSprint, OriginRegression];

const getOriginName = (origin) => {
    if (origin === OriginRegression) return "Regression";
    if (origin === OriginSprint) return "Sprint task";
    return origin;
};

const Teams = {
    Rocket: [12, 3],
    TV: [8, 11, 15, 56],
    Mobile: [28, 41, 65],
    Network: [14, 16, 68, 69],
    UX: [22, 51, 53, 66],
    QA: [19, 46, 60, 67],
    ["R&D"]: [4],
    Devops: [2, 27, 30, 34, 72, 90],
    Technical: [36, 54, 73, 91],
    Boss: [62, 64],
    Datacenter: [61, 71],
    Marketing: [42],
    Account: [63, 52],
    Out: [17, 31, 49, 50, 55, 57, 33],
};

const CommonNames = ["juan", "santiago", "sara", "carlos", "eduardo", "eva", "jorge", "daniel"];

const sprintStart = { sprint: 87, date: new Date("2025-02-13T00:00:00Z") };
const sprintDuration = 28; // days

const currentSprint = (() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const diff = Math.floor((yesterday - sprintStart.date) / (1000 * 60 * 60 * 24));
    const sprint = Math.floor(diff / sprintDuration) + sprintStart.sprint;
    const date = new Date(sprintStart.date);
    date.setDate(date.getDate() + (sprint - sprintStart.sprint) * sprintDuration);
    return { sprint, date };
})();

const lastSprint = currentSprint?.sprint;
const lastSprintStart = currentSprint?.date;

const encodeBase64 = (str) => {
    return Buffer.from(str, "utf-8").toString("base64");
};

const decodeBase64 = (str) => {
    return Buffer.from(str, "base64").toString("utf-8");
};

const Gitlab = () => {
    const tableRef = useRef(null);

    const [searchParams] = useSearchParams();
    const history = useHistory();

    const { load: loadIssues, data: openIssues, loading: loadingIssues, error: errorIssues } = useGitlabAPI("issues");
    const { load: loadUsers, data: users, loading: loadingUsers, error: errorUsers } = useGitlabAPI("members/all");
    const {
        load: loadClosedIssues,
        data: closed,
        loading: loadingClosedIssues,
        error: errorClosedIssues,
    } = useGitlabAPI("issues");

    const filters = searchParams?.get("filters");

    const activeFilters = (() => {
        return filters ? safeJsonParse(decodeBase64(filters)) : null;
    })();

    const [dateRange, setDateRange] = useState({ from: null, to: null });

    const activeTab = searchParams?.get("tab");

    const isAllIssues = !["open", "closed"].includes(activeTab);
    const isOpenIssues = activeTab === "open";
    const isClosedIssues = activeTab === "closed";
    const isStats = activeTab === "stats";

    const setActiveTab = (tab) => {
        const search = new URLSearchParams(searchParams);
        if (tab) {
            search.set("tab", tab);
        } else {
            search.delete("tab");
        }
        if (search?.toString() !== searchParams?.toString()) {
            history({ search: search.toString() });
        }
    };

    const loadData = () => {
        loadUsers({ state: "active" });
        loadIssues({ state: "opened", type: "ISSUE" });
        loadClosedIssues({
            state: "closed",
            type: "ISSUE",
            order_by: "updated_at",
            sort: "desc",
            updated_after: lastSprintStart,
        });
    };

    const setActiveFilters = (filters) => {
        if (typeof filters === "function") {
            filters = filters(activeFilters);
        }
        const search = new URLSearchParams(searchParams);
        const cleanFilters = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v !== undefined));
        if (cleanFilters && Object.keys(cleanFilters)?.length) {
            const encodedFilters = encodeBase64(safeJsonStringify(cleanFilters));
            search.set("filters", encodedFilters);
        } else {
            search.delete("filters");
        }
        if (search?.toString() !== searchParams?.toString()) {
            history({ search: search.toString() });
        }
    };

    useEffect(loadData, []);

    const loading = loadingIssues || loadingUsers || loadingClosedIssues;
    const error = errorIssues || errorUsers || errorClosedIssues;

    const closedIssues = closed?.filter((issue) => lastSprintStart <= new Date(issue.closed_at));

    const allIssues = [...(openIssues || []), ...(closedIssues || [])];

    const currentIssues = isAllIssues ? allIssues : isOpenIssues ? openIssues : closedIssues;

    const filteredIssues =
        dateRange?.from || dateRange?.to
            ? currentIssues?.filter((issue) => {
                  const date = new Date(issue.created_at);
                  const from = dateRange?.from ? new Date(dateRange?.from) : null;
                  const to = dateRange?.to ? new Date(dateRange?.to) : null;
                  return (!from || date >= from) && (!to || date <= to);
              })
            : currentIssues;

    const milestoneOptions = allIssues?.length
        ? allIssues
              .map((i) => i?.milestone?.title)
              .reduce((acc, i) => {
                  if (!acc.find((m) => m === i)) {
                      acc.push(i);
                  }
                  return acc;
              }, [])
        : null;

    const userOptions = users?.length
        ? users
              .map((u) => {
                  const team = getTeam(u.id);
                  if (team === "Out") return null;
                  // Exception for user with id 8 (Juan Lavilla) because he has defined in Gitlab as "Juan"
                  return {
                      value: Number(u.id),
                      label: <DisplayUser id={u.id} name={u.id === 8 ? "Juan Lavilla" : u.name} />,
                      alt: u.name,
                      selectedLabel: u.name,
                  };
              })
              .filter((u) => u)
              .sort((a, b) => a.alt.localeCompare(b.alt))
              //Group by team
              .reduce((acc, u) => {
                  const team = getTeam(u.value);
                  if (!acc.find((t) => t.id === team)) {
                      acc.push({
                          id: team,
                          label: <DisplayTeam id={team} alt={team} />,
                          options: [],
                      });
                  }
                  acc.find((t) => t.id === team).options.push(u);
                  return acc;
              }, [])
        : [];

    const dateRangeInfo =
        dateRange?.from && dateRange?.to
            ? `From ${new Date(dateRange.from).toLocaleDateString(LOCALE)} to ${new Date(
                  dateRange.to
              ).toLocaleDateString(LOCALE)}`
            : dateRange?.from
            ? `From ${new Date(dateRange.from).toLocaleDateString(LOCALE)}`
            : dateRange?.to
            ? `To ${new Date(dateRange.to).toLocaleDateString(LOCALE)}`
            : "All time";

    const filterAddedInSprint = (i) => {
        const date = new Date(i.created_at);
        return date >= lastSprintStart;
    };

    const statsAllIssues = allIssues?.length
        ? allIssues?.filter((r) => {
              const checkSearch = !(
                  activeFilters?.search && !r?.title?.toLowerCase().includes(activeFilters?.search.toLowerCase())
              );
              const checkDev = !(
                  activeFilters?.dev?.length && !r?.assignees?.some((a) => activeFilters?.dev.includes(a.id))
              );
              const checkQA = !(activeFilters?.qa?.length && !activeFilters?.qa.includes(r?.author?.id));
              const checkOrigin = !(
                  activeFilters?.origin?.length && !r?.labels?.some((l) => activeFilters?.origin.includes(l))
              );
              const checkMilestone = !(
                  activeFilters?.milestone?.length && !activeFilters?.milestone.includes(r?.milestone?.title)
              );
              const checkPriority = !(
                  activeFilters?.priority?.length && !r?.labels?.some((l) => activeFilters?.priority.includes(l))
              );
              return checkDev && checkQA && checkOrigin && checkPriority && checkSearch && checkMilestone;
          })
        : null;
    const statsOpenIssues = statsAllIssues?.filter((i) => i?.state === "opened");
    const statsOpenIssuesCard = statsOpenIssues?.filter((i) => i?.labels.includes(OriginSprint));
    const statsClosedIssues = statsAllIssues?.filter((i) => i?.state === "closed");

    const statsAdded = statsAllIssues?.filter(filterAddedInSprint).length;
    const statsClosed = statsClosedIssues?.filter(filterAddedInSprint).length;

    const filterTasks = (i) => {
        const currentSprint = filterAddedInSprint(i);
        const isSprintTask = i.labels.includes(OriginSprint);
        return currentSprint && isSprintTask;
    };
    const statsAddedTasks = statsAllIssues?.filter(filterTasks).length;
    const statsClosedTasks = statsClosedIssues?.filter(filterTasks).length;

    const filterOthers = (i) => {
        const currentSprint = filterAddedInSprint(i);
        const isRegression = !i.labels.includes(OriginSprint);
        return currentSprint && isRegression;
    };
    const statsAddedOthers = statsAllIssues?.filter(filterOthers).length;
    const statsClosedOthers = statsClosedIssues?.filter(filterOthers).length;

    return (
        <>
            <Header
                title={
                    <div className="flex items-center justify-items-start space-x-2">
                        <Icon type="fab fa-gitlab" size={1.7} />
                        <div className="leading-none">Gitlab issues</div>
                        <div className="text-base flex-1 text-right font-normal">
                            <div>Sprint {lastSprint}</div>
                            <div className="text-xs leading-none opacity-75">
                                {lastSprintStart.toLocaleDateString(LOCALE, {
                                    month: "long",
                                    year: "numeric",
                                    day: "numeric",
                                    weekday: "long",
                                })}
                            </div>
                        </div>
                    </div>
                }
            />
            <div className="p-10">
                {error ? <ErrorInfo useDefault={false}>{error}</ErrorInfo> : null}

                <div className="flex items-center space-x-2 pb-5 justify-between">
                    <div className="flex items-center space-x-2">
                        <Button
                            id="all-issues"
                            onClick={() => setActiveTab(null)}
                            design={activeTab ? "blue-outline" : "blue"}
                        >
                            All issues
                        </Button>

                        <Button
                            id="open-issues"
                            onClick={() => setActiveTab("open")}
                            design={isOpenIssues ? "blue" : "blue-outline"}
                        >
                            <div>Open issues</div>
                            <div className="text-xs pt-1">({dateRangeInfo || "All time"})</div>
                        </Button>
                        <Button
                            id="closed-issues"
                            onClick={() => setActiveTab("closed")}
                            design={isClosedIssues ? "blue" : "blue-outline"}
                        >
                            <div>Closed issues</div>
                            <div className="text-xs pt-1">(from {lastSprintStart.toLocaleDateString(LOCALE)})</div>
                        </Button>
                        <Button
                            id="stats"
                            onClick={() => setActiveTab("stats")}
                            design={isStats ? "blue" : "blue-outline"}
                        >
                            Stats
                        </Button>
                    </div>
                    <div>
                        <Button id="refresh" onClick={loadData} design="blue-outline" narrow={true}>
                            <Icon type="fas fa-sync" />
                        </Button>
                    </div>
                </div>
                <Table
                    key={activeTab}
                    ref={tableRef}
                    id="issues"
                    className="main-container"
                    style={isStats ? { minHeight: "auto" } : {}}
                    showCount={true}
                    loading={loading}
                    search={!isStats}
                    customFilters={true}
                    cols={[
                        "link",
                        "created",
                        ...(!isAllIssues ? [isClosedIssues ? "closed" : "updated"] : ["closed", "updated"]),
                        "qa",
                        "milestone",
                        "priority",
                        "origin",
                        "dev",
                        "team",
                    ]}
                    sort={{ field: "updated", criteria: "desc" }}
                    header={{
                        link: { title: "ID", sortable: true },
                        created: { title: "Created", sortable: true },
                        closed: { title: "Closed", sortable: true },
                        updated: { title: "Updated", sortable: true },
                        milestone: { title: "Milestone", sortable: true },
                        priority: { title: "Priority", sortable: true },
                        origin: { title: "Origin", sortable: true },
                        qa: { title: "Reporter", sortable: true },
                        dev: { title: "Assigned", sortable: true },
                        team: { title: "Team", sortable: true },
                    }}
                    filters={[
                        {
                            id: "priority",
                            title: "Priority",
                            multiple: true,
                            value: activeFilters?.priority,
                            options: IssuePriority.map((p) => ({
                                value: p,
                                label: <DisplayPriority id={p} />,
                            })),
                            onChange: (values) => setActiveFilters((current) => ({ ...current, priority: values })),
                            onFilter: (values, row) =>
                                values?.length ? row?.labels?.some((l) => values.includes(l)) : true,
                        },
                        {
                            id: "origin",
                            title: "Origin",
                            multiple: true,
                            value: activeFilters?.origin,
                            options: Origin.map((o) => ({
                                value: o,
                                label: o,
                            })),
                            onChange: (values) => setActiveFilters((current) => ({ ...current, origin: values })),
                            onFilter: (values, row) => (values?.length ? values.includes(row?.originID) : true),
                        },
                        {
                            id: "milestone",
                            title: "Milestone",
                            multiple: true,
                            value: activeFilters?.milestone,
                            options: milestoneOptions,
                            onChange: (values) => setActiveFilters((current) => ({ ...current, milestone: values })),
                            onFilter: (values, row) => (values?.length ? values.includes(row?.milestoneID) : true),
                        },
                        {
                            id: "qa",
                            title: "Reporter",
                            multiple: true,
                            value: activeFilters?.qa,
                            options: userOptions,
                            onChange: (values) => setActiveFilters((current) => ({ ...current, qa: values })),
                            onFilter: (values, row) => (values?.length ? values.includes(row?.author?.id) : true),
                        },
                        {
                            id: "dev",
                            title: "Assigned",
                            multiple: true,
                            value: activeFilters?.dev,
                            options: userOptions,
                            onChange: (values) => setActiveFilters((current) => ({ ...current, dev: values })),
                            onFilter: (values, row) =>
                                values?.length ? row?.assignees?.some((a) => values.includes(a.id)) : true,
                        },
                    ]}
                    customRangeSelector={
                        isStats
                            ? null
                            : {
                                  value: { from: null, to: null },
                                  onChange: setDateRange,
                                  hideTimePicker: false,
                                  hideFutureDates: true,
                              }
                    }
                    rows={
                        filteredIssues?.length
                            ? filteredIssues.map((row) => {
                                  const foundPriority = IssuePriority.find((priority) =>
                                      row?.labels?.includes(priority)
                                  );
                                  const { priority, name: priorityName } = getPriorityInfo(foundPriority);
                                  const origin = row?.labels?.some((l) => l === OriginSprint)
                                      ? OriginSprint
                                      : OriginRegression;

                                  const team = getTeam(row?.assignees?.[0]?.id);

                                  return {
                                      ...row,
                                      link: (
                                          <a
                                              href={`https://gitlab.zafiro.link/zafiro/qa/-/issues/${row?.iid}`}
                                              alt={row?.iid}
                                              target="_blank"
                                              className="text-zafiro-600 hover:text-zafiro-300"
                                              data-tip={row?.title}
                                              data-for="default-tooltip"
                                          >
                                              {row?.iid}
                                          </a>
                                      ),
                                      created: row.created_at ? <DisplayDate date={row.created_at} /> : null,
                                      updated: row.updated_at ? <DisplayDate date={row.updated_at} /> : null,
                                      closed: row.closed_at ? <DisplayDate date={row.closed_at} /> : null,
                                      milestoneID: row?.milestone?.title,
                                      milestone: (
                                          <a
                                              href={row?.milestone?.web_url}
                                              alt={row?.milestone?.title}
                                              target="_blank"
                                              className="text-zafiro-600 hover:text-zafiro-300"
                                          >
                                              {row?.milestone?.title}
                                          </a>
                                      ),
                                      priorityID: priority,
                                      priority: (
                                          <DisplayPriority alt={`${priority} ${priorityName}`} id={foundPriority} />
                                      ),
                                      originID: origin,
                                      origin: origin ? (
                                          <DisplayOrigin alt={`${origin} ${getOriginName(origin)}`} id={origin} />
                                      ) : null,
                                      qa: (
                                          <DisplayUser
                                              alt={row?.author?.name}
                                              id={row?.author?.id}
                                              name={row?.author.id === 8 ? "Juan Lavilla" : row?.author?.name}
                                              fullName={false}
                                          />
                                      ),
                                      dev: (
                                          <div alt={row?.assignees?.[0]?.name}>
                                              {row?.assignees?.length
                                                  ? row?.assignees?.map((a) => (
                                                        <DisplayUser
                                                            key={a.id}
                                                            id={a.id}
                                                            name={a.id === 8 ? "Juan Lavilla" : a?.name}
                                                            fullName={false}
                                                        />
                                                    ))
                                                  : null}
                                          </div>
                                      ),
                                      team: team ? <DisplayTeam alt={team} id={team} /> : null,
                                  };
                              })
                            : []
                    }
                    displayBody={activeTab !== "stats"}
                />

                {isStats ? (
                    <Table
                        id="stats"
                        className="main-container mt-2"
                        style={{ minHeight: "auto" }}
                        loading={loading}
                        cols={["concept", "count", "closed"]}
                        rows={[
                            {
                                concept: "Open issues",
                                count: statsOpenIssues?.length,
                                closed: (
                                    <>
                                        {`${statsOpenIssuesCard?.length} sprint tasks`} <br />
                                        {`${statsOpenIssues?.length - statsOpenIssuesCard?.length} regression`}
                                    </>
                                ),
                            },
                            {
                                concept: "Issues closed in current sprint",
                                count: statsClosedIssues?.length,
                                closed: (
                                    <>
                                        {`${statsClosed} from current`} <br />
                                        {`${statsClosedIssues?.length - statsClosed} from previous`}
                                    </>
                                ),
                            },
                            {
                                concept: "Issues added in current sprint",
                                count: statsAdded,
                                closed: (
                                    <>
                                        {`${statsClosed} closed`} <br />
                                        {`${statsAdded - statsClosed} still open`}
                                    </>
                                ),
                            },
                            {
                                concept: "Issues added in current sprint (Sprint tasks)",
                                count: statsAddedTasks,
                                closed: (
                                    <>
                                        {`${statsClosedTasks} closed`} <br />
                                        {`${statsAddedTasks - statsClosedTasks} still open`}
                                    </>
                                ),
                            },
                            {
                                concept: "Issues added in current sprint (Regression)",
                                count: statsAddedOthers,
                                closed: (
                                    <>
                                        {`${statsClosedOthers} closed`} <br />
                                        {`${statsAddedOthers - statsClosedOthers} still open`}
                                    </>
                                ),
                            },
                        ]}
                    />
                ) : null}
            </div>
        </>
    );
};

const getTeam = (id) => {
    return Object.entries(Teams).find(([, members]) => members.includes(id))?.[0];
};

const getTeamColor = (team) => {
    if (team === "Rocket") return "orange-500";
    if (team === "TV") return "blue-300";
    if (team === "Mobile") return "green-600";
    if (team === "Network") return "red-600";
    if (team === "UX") return "purple-600";
    if (team === "QA") return "black";
    return "gray-700";
};

const DisplayTeam = ({ id }) => {
    const color = getTeamColor(id);
    return (
        <Tag className={`text-xs px-1 leading-5 text-center text-white `} color={`bg-${color}`}>
            {id}
        </Tag>
    );
};

const DisplayUser = ({ id, name, fullName = true }) => {
    const firstName = name?.split(" ")[0];
    const secondNameInitial = name?.split(" ")[1]?.[0] || null;
    const shortName = `${firstName}${
        CommonNames.includes(secondNameInitial && firstName?.toLowerCase()) ? " " + secondNameInitial : ""
    }`;
    const color = getTeamColor(getTeam(id));
    return (
        <div
            className={`flex items-center space-x-1 text-sm rounded capitalize whitespace-no-wrap text-${color}`}
            data-tip={name}
            data-for="default-tooltip"
        >
            <Icon type="far fa-user" size={0.75} className="leading-none" />
            <div className="leading-none">{fullName ? name : shortName}</div>
        </div>
    );
};

const DisplayOrigin = ({ id }) => {
    const origin = Origin.find((o) => o === id);
    const originName = getOriginName(origin);
    return (
        <Tag
            className="text-xs p-1 whitespace-no-wrap"
            color={classNames({
                "bg-red-600": origin === OriginSprint,
                "bg-orange-500": origin === OriginRegression,
            })}
        >
            {originName}
        </Tag>
    );
};

const getPriorityInfo = (id) => {
    const priority = IssuePriority.indexOf(id) + 1;
    const name = id?.match(/^\d?\+*\s*-*\s*(.+)/)?.[1] || id;
    return { priority, name };
};

const DisplayPriority = ({ id: foundPriority }) => {
    const { priority, name: priorityName } = getPriorityInfo(foundPriority);
    return (
        <Tag
            color={classNames({
                "bg-red-600": priority === 1,
                "bg-red-400": priority === 2,
                "bg-orange-500": priority === 3,
                "bg-yellow-500": priority === 4,
                "bg-gray-600": priority >= 5,
                "bg-black": !priority,
            })}
            className="text-xs p-1 whitespace-no-wrap"
            tooltip={foundPriority}
        >
            {priorityName || priority || foundPriority}
        </Tag>
    );
};

const DisplayDate = ({ date }) => {
    const dateObj = date instanceof Date ? date : new Date(date);
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;

    const todayDay = new Date();
    todayDay.setHours(0, 0, 0, 0);
    const currentDay = new Date(dateObj);
    currentDay.setHours(0, 0, 0, 0);
    const daysAgo = (todayDay - currentDay) / 86400000;

    const isToday = daysAgo < 1;
    const isYesterday = !isToday && daysAgo < 2;

    const tooltip = {
        "data-tip": new Date(date).toLocaleString(LOCALE),
        "data-for": "default-tooltip",
    };

    if (isToday) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Hoy <small className="text-sm text-gray-700">({time})</small>
            </div>
        );
    }

    if (isYesterday) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Ayer <small className="text-sm text-gray-700">({time})</small>
            </div>
        );
    }

    if (daysAgo < 16) {
        return (
            <div className="whitespace-nowrap" {...tooltip}>
                Hace {Math.floor(daysAgo)} días
            </div>
        );
    }

    return <div {...tooltip}>{new Date(date).toLocaleDateString(LOCALE)}</div>;
};

const useGitlabAPI = (endpoint) => {
    const [params, setParams] = useState(null);
    const [data, setData] = useState(null);
    const [count, setCount] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadAll = async (params) => {
        setLoading(true);
        setData(null);
        setError(null);
        setParams(params);
        setNextPage(1);
    };

    const load = async (params, page) => {
        const cleanParams = Object.fromEntries(
            Object.entries({
                page,
                per_page: 100,
                ...params,
            }).filter(([_, v]) => v !== undefined)
        );
        const urlParams = new URLSearchParams(cleanParams);
        const urlParamsString = urlParams?.size ? `?${urlParams.toString()}` : "";
        try {
            const response = await fetch(`${API_URL}/projects/${PROJECT_QA}/${endpoint}${urlParamsString}`, {
                headers: {
                    "Content-Type": "application/json",
                    "PRIVATE-TOKEN": TOKEN,
                },
            });
            setCount(Number(response.headers.get("X-Total")));
            if (params?.page) {
                // if page is defined, it means we not continue loading the next page
                setNextPage(null);
            } else {
                setNextPage(Number(response.headers.get("X-Next-Page")));
            }
            const json = await response.json();
            if (!response.ok) {
                throw new Error(json?.error || `${response.status}: ${response.statusText}`);
            }
            setError(null);
            setData((prevData) => (prevData ? [...prevData, ...json] : json));
        } catch (err) {
            setError(err);
            setData(null);
            setCount(null);
            setNextPage(null);
        }
    };

    useEffect(() => {
        if (nextPage) {
            load(params, nextPage);
        } else {
            setLoading(false);
        }
    }, [nextPage]);

    return {
        load: loadAll,
        data,
        error,
        count: count ?? data?.length,
        loading,
    };
};

export default Gitlab;
