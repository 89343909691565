import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";

import Icon from "components/Icon";

function useInputText({
    id,
    name,
    ph,
    noTranslatePh,
    value,
    adjust,
    action,
    disabled = false,
    changeAction,
    isNumeric,
    numericProps,
    phRight,
    defaultValue,
    onFocus,
    forceBgColor,
    updateActionValuesOnChange,
    fitHeightToParent = false,
    setTimeoutBeforeType = false,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [_value, set_value] = useState(value);
    const [timer, setTimer] = useState();
    const timeToExecuteFunction = 1000;

    useEffect(() => {
        set_value(value);
    }, [value]);

    useEffect(() => {
        if (updateActionValuesOnChange && name) {
            dispatch(changeActionValues({ [name]: _value }));
        }
        if (action && setTimeoutBeforeType) {
            setTimer(setTimeout(action, timeToExecuteFunction));
        }
    }, [_value]);

    const handleValue = (e) => {
        e.preventDefault();
        set_value(e.currentTarget.value);
        if (changeAction) {
            changeAction(e.currentTarget.value, id);
        }
        if (timer && setTimeoutBeforeType) {
            clearTimeout(timer);
        }
    };

    const handleBlur = (e) => {
        e.preventDefault();
        set_value(e.currentTarget.value);
        if (action) {
            action(e.currentTarget.value, id);
        }
    };

    const handleOnFocus = (e) => {
        e.preventDefault();
        if (onFocus) {
            onFocus();
        }
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            //Write you validation logic here
        }
    };

    return (
        <>
            <div className={`relative ${fitHeightToParent && "h-full"}`}>
                <Icon type="refactor" tooltip="Please, use new TextInput component" />
                <input
                    id={id}
                    type={!isNumeric ? "text" : "number"}
                    className={`rounded w-full bg-gray-200 p-2 ${name === "name" ? " w-full" : " long"} ${adjust}
                        ${disabled ? "text-gray-700" : ""}
                        ${phRight ? "pr-10" : ""}
                    `}
                    style={{ backgroundColor: forceBgColor }}
                    placeholder={getPh({ ph, name, noTranslatePh, t })}
                    defaultValue={defaultValue}
                    value={_value}
                    disabled={disabled}
                    onChange={handleValue}
                    onBlur={handleBlur}
                    onFocus={handleOnFocus}
                    onKeyDown={handleKeyPress}
                    {...numericProps}
                ></input>
                {phRight ? (
                    <span className=" absolute py-2 pr-1 text-gray-600 " style={{ top: "0%", right: "0" }}>
                        {phRight}
                    </span>
                ) : null}
            </div>
        </>
    );
}

export default useInputText;

const getPh = (props = {}) => {
    const { ph, name, noTranslatePh, t } = props;
    let response = "";
    if (ph) {
        response = noTranslatePh ? ph : t(ph);
    } else if (name) {
        response = noTranslatePh ? name : t(name);
    }
    return response;
};
