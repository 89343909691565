import React, { useEffect, useState } from "react";
import { TickerTapesContent } from "./TickerTapesContent";
import UseSectionHeader from "components/useSectionHeader";
import Switch from "components/Switch";
import {
    useListTickerTapes,
    useSetAvailabilityTickerTape,
    useTheme,
    useUpdateTickerTapes,
} from "../graphql/useTickerTapes";
import AddFiltersCard from "../AddFiltersCard";
import ScheduleCard from "../ScheduleCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TICKER_TAPE_TYPE, ALL_PROPERTY_FILTER, FILTERS } from "../constants";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";
import useModalScheduleTickerTapeBanner from "../modals/signages/useModalScheduleTickerTapeBanner";
import { toast } from "react-toastify";
import TickerTapeDisplayMode from "./TickerTapeDisplayMode";
import { UseModalAddFilters } from "../modals/useModalAddFilters";
import { useScheduleSignage } from "../graphql/useSignages";

function hasValidContent(tickerTape) {
    const contentObj = JSON.parse(tickerTape?.content ?? "{}");
    return Object.entries(contentObj).length > 0;
}

function canBeAvailable(tickerTape) {
    return hasValidContent(tickerTape) && tickerTape?.filtersNum > 0 && tickerTape?.scheduleStart;
}

const TickerTapeDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { getTickerTapes, tickerTapes, loading, refetchTickerTapes, idDesignApplied } = useListTickerTapes();
    const { getTheme, theme } = useTheme();
    const { setAsUnavailable } = useSetAvailabilityTickerTape({
        onCompleted: () => {
            fetchTickerTape();
        },
    });

    const { open: openModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalScheduleTickerTapeBanner } = useModalScheduleTickerTapeBanner();
    const { open: openModalAddFilters } = UseModalAddFilters();

    const [isAvailable, setIsAvailable] = useState(false);
    const [tickerTape, setTickerTape] = useState({});

    const fetchTickerTape = () => {
        getTickerTapes({ variables: { id } });
    };

    useEffect(() => {
        fetchTickerTape();
    }, [id]);

    useEffect(() => {
        if (idDesignApplied) {
            getTheme({
                variables: {
                    filter: { designID: idDesignApplied },
                },
            });
        }
    }, [idDesignApplied, getTheme]);

    useEffect(() => {
        if (!tickerTape) return;

        const noContentOrNoFilters = !hasValidContent(tickerTape) || tickerTape.filtersNum === 0;

        if (noContentOrNoFilters && tickerTape.available) {
            setAsUnavailable({ variables: { ids: [id] } });
        }
    }, [tickerTape, id, setAsUnavailable]);

    useEffect(() => {
        if (tickerTapes) {
            setTickerTape(tickerTapes?.[0]);
            setIsAvailable(tickerTapes?.[0]?.available);
        }
    }, [tickerTapes]);

    const couldBeAvailable = canBeAvailable(tickerTape);

    const {
        scheduleDaysOfWeek,
        scheduleDaysType,
        scheduleStart,
        scheduleEnd,
        scheduleFrequencyDisplay,
        scheduleFrequencyLimited,
        timeRanges,
        scheduleObtrusiveMode,
        name,
        scheduleFrequencyRepetitions,
        displayScrollSpeed,
        displayTextSize,
        displayStyles,
    } = tickerTape || {};

    const handleAvailabilityChange = (val) => {
        openModalChangeAvailability({
            name,
            available: isAvailable,
            type: TICKER_TAPE_TYPE,
            id,
            useChangeAvailabilityHook: useSetAvailabilityTickerTape,
            callback: () => {
                fetchTickerTape();
            },
            onCancel: () => {
                setIsAvailable(tickerTape?.available);
            },
        });
        setIsAvailable(val.checked);
    };

    const handleScheduleModal = () => {
        openModalScheduleTickerTapeBanner({
            onCompleted: () => {
                refetchTickerTapes();
                toast.success(t("operation-successful"));
            },
            data: {
                id,
                type: TICKER_TAPE_TYPE,
                modalTitle: "schedule-ticker-tape",
                scheduleDaysOfWeek,
                scheduleDaysType,
                scheduleStart,
                scheduleEnd,
                scheduleFrequencyDisplay,
                scheduleFrequencyLimited,
                timeRanges,
                scheduleObtrusiveMode,
                scheduleFrequencyRepetitions,
            },
        });
    };

    return (
        <div>
            <div className="flex justify-between items-end">
                <UseSectionHeader
                    title={name}
                    buttonColor="btn-blue"
                    action="function"
                    value="demo"
                    navToSection="/design/advertising/tickertapes"
                    customBreadCrumbs={[
                        {
                            name: "design",
                            route: "/design",
                        },
                        {
                            name: "advertising",
                            route: "/design/advertising/",
                        },
                        {
                            name: "tickertapes",
                            route: "/design/advertising/tickertapes/",
                            respectRoute: true,
                        },
                        {
                            name: "details",
                            route: `/design/advertising/tickertapes/details/${id}`,
                        },
                    ]}
                />

                <div className="mb-8 flex gap-4">
                    <Switch
                        onChange={handleAvailabilityChange}
                        tooltip={
                            !couldBeAvailable && !isAvailable
                                ? t("the-ticker-tape-will-be-able-to-set-as-available")
                                : null
                        }
                        disabled={!isAvailable && !couldBeAvailable}
                        checked={isAvailable}
                        label={t("available")}
                        className="flex flex-row-reverse gap-2 font-bold"
                        value={isAvailable}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <ScheduleCard
                    data={tickerTapes}
                    loading={loading}
                    type={TICKER_TAPE_TYPE}
                    title="schedule-ticker-tape"
                    tooltip="the-default-sequence-cannot-be-programmed"
                    subtitle="set-up-the-ticker-tape-schedule-to-be-displayed-in-the-tv-interface"
                    display="ticker-tape-display"
                    openModal={handleScheduleModal}
                />
                <AddFiltersCard
                    data={tickerTapes}
                    loading={loading}
                    subtitle="set-up-the-filters-ticker-tape"
                    openModal={openModalAddFilters}
                    onlyWithGuest={false}
                    type={TICKER_TAPE_TYPE}
                    getData={fetchTickerTape}
                    customFilters={[ALL_PROPERTY_FILTER(t), ...FILTERS(t)]}
                    updateHook={useUpdateTickerTapes}
                />

                <TickerTapesContent getData={fetchTickerTape} translations={tickerTape?.content} loading={loading} />
                <TickerTapeDisplayMode
                    hasContent={
                        Object.entries(JSON.parse(tickerTape?.content || "{}")).map(([key, value]) => {
                            return { languageCode: key, name: value };
                        }).length > 0
                    }
                    displayScrollSpeed={displayScrollSpeed}
                    displayTextSize={displayTextSize}
                    displayStyles={displayStyles}
                    theme={theme}
                />
            </div>
        </div>
    );
};

export default TickerTapeDetails;
