import { useEffect } from "react";
import { MOBILE_RATIO, TV_RATIO } from "./Widgets";
import { DEVICE } from "constants/editor";
import { VISIBILITY } from "constants/design";
import { CORPORATE_REF } from "constants/permissions";

import { useAuth } from "hooks/Session/auth";
import useBrands from "hooks/Data/useBrands";
import { capitalizeFirst, formatBytes, remToPixel } from "hooks/Utils/Utils";

const WIDGET_OUTLINE = 2; // 2px del outline del widget
const DEFAULT_WIDGET_TOOLBAR_WIDTH = 28; // 28rem
// const TOOLBAR_CONTAINER_SELECTOR = `#designToolbarContainer`;
const SCREEN_CONTAINER_SELECTOR = `#editor_screen_container`;
const SCREEN_EDITOR_AREA = `#editor_screen_area`;

// Ensure that toolbar is inside the edition area
export const ensureToolbarPos = (widgetID) => {
    const toolbarSelector = `#toolbar_${widgetID}`;
    const handlerSelector = `#item_label_${widgetID}`;
    const widgetElm = document.querySelector(`#widget_${widgetID}`);
    const isScreenToolbar = widgetID === "bg";
    const screenContainer = document.querySelector(SCREEN_CONTAINER_SELECTOR);
    const scrollTop = screenContainer ? screenContainer.scrollTop : -1;

    if (widgetID && !isScreenToolbar) {
        const toolbarElm = document.querySelector(toolbarSelector);
        if (!toolbarElm) {
            return;
        }

        const toolbarSizes = getElementRects(toolbarElm);
        if (!toolbarSizes) {
            return;
        }

        const isTopBar =
            widgetElm &&
            widgetElm.parentNode &&
            widgetElm.parentNode.parentNode &&
            widgetElm.parentNode.parentNode.id === "top-mobile-cover";
        const widgetBorder = parseInt(widgetElm ? getComputedStyle(widgetElm).borderTopWidth : 0) + WIDGET_OUTLINE;
        const toolbarMinWidth = remToPixel(DEFAULT_WIDGET_TOOLBAR_WIDTH);

        const widgetSizes = getElementRects(widgetElm);
        const editorSizes = getElementRects(SCREEN_CONTAINER_SELECTOR);
        const editorTop = editorSizes ? editorSizes.top : 0;

        // Set label position
        const handlerElm = document.querySelector(handlerSelector);
        const handlerSizes = getElementRects(handlerElm);
        if (handlerElm) {
            // Top mobile cover is not considered for top-bar handler position
            const topMobileSizes = isTopBar ? null : getElementRects("#top-mobile-cover");
            const topSpace = (widgetSizes ? widgetSizes.top : 0) - editorTop - (handlerSizes ? handlerSizes.height : 0);
            if (topSpace <= (topMobileSizes ? topMobileSizes.height : 0)) {
                handlerElm.style.top = `${widgetBorder}px`;
                handlerElm.style.left = `${widgetBorder}px`;
            } else {
                handlerElm.style.top = `calc(-1.6rem - ${widgetBorder}px)`;
                handlerElm.style.left = `-${widgetBorder}px`;
            }
            handlerElm.classList.remove("invisible");
        }
        //

        delete toolbarElm.dataset.placeAt;
        delete toolbarElm.dataset.bottomSide;
        delete toolbarElm.dataset.leftSide;
        delete toolbarElm.dataset.rightSide;
        delete toolbarElm.dataset.rightSideTop;
        delete toolbarElm.dataset.inSide;

        //Check horizontal adjust, posicionar toolbar a izquierda o derecha del widget
        const canPlaceAtRight = widgetSizes.right + toolbarMinWidth <= editorSizes.width;
        const canPlaceAtLeft = widgetSizes.left - toolbarMinWidth > editorSizes.left;
        const canPlaceAtTop =
            widgetSizes.top - toolbarSizes.height >= editorTop + remToPixel(4) &&
            widgetSizes.width - (handlerSizes ? handlerSizes.width : 0) >= toolbarSizes.width;
        const canPlaceAtBottom = widgetSizes.bottom + toolbarSizes.height <= editorSizes.height;

        toolbarElm.style.right = "auto";
        toolbarElm.style.left = "auto";
        toolbarElm.style.top = "auto";
        toolbarElm.style.bottom = "auto";

        if (canPlaceAtRight) {
            toolbarElm.dataset.placeAt = "right";
            toolbarElm.style.right = `-${toolbarSizes.width + widgetBorder}px`;
            toolbarElm.style.top = `-${widgetBorder}px`;
            toolbarElm.dataset.rightSide = true;
        } else if (canPlaceAtLeft) {
            const menuTvLabel = document.querySelector("#item_label_menu_tv");
            const menutTvId = document.querySelector(`[data-menu-id]`)?.getAttribute("data-menu-id");

            if (menuTvLabel && menutTvId && menutTvId === widgetID) {
                toolbarElm.dataset.placeAt = "top";
                toolbarElm.style.right = `-${widgetBorder}px`;
                toolbarElm.style.top = `-${widgetBorder + toolbarSizes.height}px`;
                toolbarElm.dataset.rightSide = true;
                toolbarElm.dataset.rightSideTop = true;
                return;
            }

            toolbarElm.dataset.placeAt = "left";
            toolbarElm.style.top = `-${widgetBorder}px`;
            toolbarElm.style.right = `${widgetSizes.width - widgetBorder + 2 * WIDGET_OUTLINE}px`;
            toolbarElm.dataset.leftSide = true;
        } else if (canPlaceAtTop && !canPlaceAtBottom) {
            toolbarElm.dataset.placeAt = "top";
            toolbarElm.style.right = `-${widgetBorder}px`;
            toolbarElm.style.top = `-${widgetBorder + toolbarSizes.height}px`;
            toolbarElm.dataset.rightSide = true;
            toolbarElm.dataset.rightSideTop = true;
        } else if (canPlaceAtBottom) {
            toolbarElm.dataset.placeAt = "bottom";
            toolbarElm.style.right = `-${widgetBorder}px`;
            toolbarElm.style.bottom = `-${widgetBorder + toolbarSizes.height}px`;
            toolbarElm.dataset.rightSide = true;
        } else {
            toolbarElm.dataset.placeAt = "inside";
            toolbarElm.style.top = `${widgetBorder + WIDGET_OUTLINE}px`;
            toolbarElm.style.right = `${widgetBorder + WIDGET_OUTLINE}px`;
            toolbarElm.dataset.leftSide = true;
            toolbarElm.dataset.inSide = true;
        }

        toolbarElm.classList.remove("invisible");
    } else if (widgetID && isScreenToolbar) {
        let toolbar = document.querySelector(`#toolbar_bg`);
        if (toolbar) toolbar.style.top = scrollTop + "px";

        const bgLabel = document.querySelector("#toolbar_bg_tag");
        if (bgLabel) bgLabel.style.top = scrollTop + "px";
    }
};

export const ensureWidgetOptionsPos = (widgetID, editorSize) => {
    const isScreenToolbar = widgetID === "bg";
    const optionContainer =
        document.querySelector(`#widgetOptionContainer_${widgetID}`) ||
        document.querySelector(`#widget_${widgetID} .rdw-editor-toolbar`);
    if (!optionContainer) {
        return;
    }

    const isEditorToolbar = optionContainer.classList.contains("rdw-editor-toolbar");
    const toolbar = document.querySelector(`#toolbar_${widgetID}`);
    const toolbarSizes = getElementRects(toolbar);
    const optionsSizes = getElementRects(optionContainer);
    const screenSizes = getElementRects(SCREEN_CONTAINER_SELECTOR);
    const topMobileSizes = getElementRects("#top-mobile-cover");
    if (!toolbar || !toolbarSizes || !optionsSizes || !screenSizes) {
        return;
    }

    const editionHeight = editorSize.height;
    const editionTop = screenSizes.top;
    const editionBottom = editorSize.height + editionTop;

    if (isScreenToolbar) {
        // Top right inside to down
        optionContainer.style.top = `${toolbarSizes.height}px`;
        optionContainer.style.right = "0";
        optionContainer.style.left = "auto";
        optionContainer.style.bottom = "auto";
        const maxHeight = editionBottom - toolbarSizes.bottom - 5;

        optionContainer.style.maxHeight = `${maxHeight}px`;
        optionContainer.classList.remove("invisible");
        return;
    }

    const placeAt = toolbar.dataset.placeAt;
    if (!isEditorToolbar) {
        optionContainer.style.top = `${toolbarSizes.height}px`;
        optionContainer.style.left = "0";
        optionContainer.style.right = "auto";
        optionContainer.style.bottom = "auto";

        if (placeAt === "top" || placeAt === "bottom" || placeAt === "inside") {
            optionContainer.style.left = `-${optionsSizes.width - toolbarSizes.width}px`;
        }
        if (placeAt === "left") {
            optionContainer.style.left = "-190px";
        }

        if (placeAt === "right") {
            const maxWidth = screenSizes.width + screenSizes.left - optionsSizes.left;
            optionContainer.style.maxWidth = `${maxWidth}px`;
        }

        const findTypeActions = document.querySelector('[data-type="actions"]');

        // Limit options box height
        if (toolbarSizes.top - screenSizes.top > editionHeight / 2) {
            // To up
            let maxSize = toolbarSizes.top - editionTop - 5 - (topMobileSizes ? topMobileSizes.height : 0);
            if (optionsSizes.height <= maxSize && !findTypeActions) {
                const qrWidget = document.querySelector(`#QR_${widgetID}`);
                if (qrWidget) {
                    maxSize = 245;
                } else {
                    maxSize = optionsSizes.height;
                }
            }

            if (findTypeActions && maxSize > 440) {
                maxSize = 450;
            }

            optionContainer.style.height = `${maxSize}px`;
            optionContainer.style.top = `-${maxSize}px`;
        } else if (optionsSizes.height + toolbarSizes.bottom > editionBottom || findTypeActions) {
            // To down limit size
            const maxSize = editionBottom - toolbarSizes.bottom;
            optionContainer.style.maxHeight = `${maxSize}px`;
        }

        optionContainer.classList.remove("invisible");
    }

    if (isEditorToolbar) {
        optionContainer.style.left = 0;
        optionContainer.style.right = "auto";
        optionContainer.style.bottom = "auto";
        const place = toolbar.dataset.placeAt;
        const canToUp = toolbarSizes.top > screenSizes.top + optionsSizes.height;
        const canToDown = screenSizes.height > toolbarSizes.bottom + optionsSizes.height + 30;
        if ((place === "top" || !canToDown) && canToUp) {
            // To up
            optionContainer.style.top = `${toolbarSizes.top - optionsSizes.height - 5}px`;
        } else {
            // To down
            optionContainer.style.top = `${toolbarSizes.bottom + 5}px`;
        }
        switch (place) {
            case "right":
                optionContainer.style.left = `${toolbarSizes.left}px`;
                break;
            case "top":
            case "bottom":
            case "left":
            case "inside":
            default:
                optionContainer.style.left = `${toolbarSizes.left - (optionsSizes.width - toolbarSizes.width)}px`;
        }
    }
};

export const scaleFontSize = (size, editorWidth) => {
    if (editorWidth) {
        return (parseInt(size) * editorWidth * 100) / 128000;
    }
    let editorContainer = document.querySelector(SCREEN_EDITOR_AREA);
    if (!editorContainer) {
        return size;
    }
    const editorPos = getElementRects(editorContainer);

    const factor = (editorPos.width * 100) / 128000; // Use base resolution of 1280px

    return parseInt(size) * factor;
};

export const px2vw = (size, width, zoom) => {
    if (!zoom) zoom = 1;
    if (width > 0) {
        return (size * 100) / width / zoom;
    } else {
        let editorContainer = document.querySelector(SCREEN_EDITOR_AREA);
        if (editorContainer) {
            const editorPos = getElementRects(editorContainer);
            if (editorPos) {
                return (size * 100) / editorPos.width / zoom;
            }
        }
    }
    return size;
};

export const px2vh = (size, height, zoom) => {
    if (!zoom) zoom = 1;
    if (height > 0) {
        return (size * 100) / height / zoom;
    } else {
        let editorContainer = document.querySelector(SCREEN_EDITOR_AREA);
        if (editorContainer) {
            const editorPos = getElementRects(editorContainer);
            if (editorPos) {
                return (size * 100) / editorPos.height / zoom;
            }
        }
    }
    return size;
};

export const vw2px = (size, width, zoom) => {
    if (!zoom) zoom = 1;
    if (width > 0) {
        return Math.round(((width * zoom) / 100) * size);
    } else {
        let editorContainer = document.querySelector(SCREEN_EDITOR_AREA);
        if (editorContainer) {
            const editorPos = getElementRects(editorContainer);
            if (editorPos) {
                return Math.round(((editorPos.width * zoom) / 100) * size);
            }
        }
    }
    return size;
};

export const calculateEditorSizes = (cDevice, cScreenData, setEditorSize, isWelcome = false) => {
    let sizes = {
        width: 0,
        height: 0,
        my: 0,
        mx: 0,
        cols: 0,
        rows: 0,
        isBounded: false,
    };

    if (cDevice && cScreenData) {
        switch (cDevice.type) {
            case "TV":
                sizes = calculateByRatio(cDevice, TV_RATIO);
                sizes.isBounded = true;
                if (isWelcome) {
                    const toolbarElement = document.querySelector("#designToolbar");
                    sizes.welcomeWidth = remToPixel(26);
                    sizes.welcomeHeight = sizes.height;
                    if (
                        sizes.width >
                        window.innerWidth - (toolbarElement ? toolbarElement.clientWidth : 0) - remToPixel(26) - 100
                    ) {
                        sizes.screenAreaWidth =
                            window.innerWidth -
                            (toolbarElement ? toolbarElement.clientWidth : 0) -
                            remToPixel(24) -
                            120;
                        sizes.screenAreaHeight = (sizes.screenAreaWidth * 9) / 16;
                    } else {
                        sizes.screenAreaWidth = sizes.width;
                        sizes.screenAreaHeight = sizes.height;
                    }
                } else {
                    sizes.screenAreaWidth = sizes.width;
                    sizes.screenAreaHeight = sizes.height;
                }
                break;
            case "Desktop":
                sizes = calculateByRatio(cDevice, TV_RATIO);
                sizes.isBounded = true;
                if (isWelcome) {
                    const toolbarElement = document.querySelector("#designToolbar");
                    sizes.welcomeWidth = remToPixel(26);
                    sizes.welcomeHeight = sizes.height;
                    if (
                        sizes.width >
                        window.innerWidth - (toolbarElement ? toolbarElement.clientWidth : 0) - remToPixel(26) - 100
                    ) {
                        sizes.screenAreaWidth =
                            window.innerWidth -
                            (toolbarElement ? toolbarElement.clientWidth : 0) -
                            remToPixel(24) -
                            120;
                        sizes.screenAreaHeight = (sizes.screenAreaWidth * 9) / 16;
                    } else {
                        sizes.screenAreaWidth = sizes.width;
                        sizes.screenAreaHeight = sizes.height;
                    }
                } else {
                    sizes.screenAreaWidth = sizes.width;
                    sizes.screenAreaHeight = sizes.height;
                }
                break;
            case "Mobile":
            case "Mobile med":
                sizes = calculateByRatio(cDevice, MOBILE_RATIO);
                sizes.isBounded = true;
                sizes.screenAreaWidth = sizes.width;
                sizes.screenAreaHeight = sizes.height;
                break;

            default:
                break;
        }
        sizes.width = sizes.screenAreaWidth;
        sizes.height = sizes.screenAreaHeight;
        const grid = cDevice ? cDevice.grid : null;
        const rows = grid ? grid.rows : 1;
        const cols = grid ? grid.cols : 1;
        //calculate row height
        const rowHeight =
            (sizes?.height -
                (cScreenData?.contentStyle?.margin ? cScreenData?.contentStyle?.margin?.h : 10) * 2 -
                (rows - 1) *
                    (cScreenData?.contentStyle?.itemsMargin ? cScreenData?.contentStyle?.itemsMargin?.h : 10)) /
            rows;
        const rowHeightNoMargin =
            (sizes?.height -
                (rows - 1) *
                    (cScreenData?.contentStyle?.itemsMargin ? cScreenData?.contentStyle?.itemsMargin?.h : 10)) /
            rows;

        const rowWidth =
            (sizes?.width -
                (cScreenData?.contentStyle?.margin ? cScreenData?.contentStyle?.margin?.w : 10) * 2 -
                (cols - 1) * (cScreenData?.contentStyle?.itemsMargin ? cScreenData?.contentStyle.itemsMargin?.w : 10)) /
            cols;
        const rowWidthNoMargin =
            (sizes?.width -
                (cols - 1) *
                    (cScreenData?.contentStyle?.itemsMargin ? cScreenData?.contentStyle?.itemsMargin?.w : 10)) /
            cols;
        sizes.rowHeight = rowHeight;
        sizes.rowHeightNoMargin = rowHeightNoMargin;
        sizes.rowWidth = rowWidth;
        sizes.rowWidthNoMargin = rowWidthNoMargin;
    }
    if (setEditorSize) {
        setEditorSize(sizes);
    }
    return sizes;
};

export const calculateContainerSizes = (itemId, gItem, setEditorSize) => {
    const DEFAULT_COLS = 16;
    const DEFAULT_ROWS = 9;
    const widgetItem = document.querySelector(`#widget_${itemId}`);
    const widgetItemZone = document.querySelector(`#widget_zone_${itemId}`);
    if (!widgetItem && !widgetItemZone) {
        return;
    }
    const widgetPos = getElementRects(widgetItem);
    const w = widgetPos?.width || 0;
    const h = widgetPos?.height || 0;
    const border = widgetItemZone.style.borderWidth || 0;
    let sizes = {
        width: w - 2 * parseInt(border),
        height: h - 2 * parseInt(border),
        my: 10,
        mx: 10,
        cols: gItem && gItem.data && gItem.data.grid ? gItem.data.grid.cols : DEFAULT_COLS,
    };

    const rows = gItem && gItem.data && gItem.data.grid ? gItem.data.grid.rows : DEFAULT_ROWS;
    const marginH = gItem?.data?.margin ? gItem.data.margin.h : 10;
    const itemsMarginH = gItem?.data?.itemsMargin ? gItem.data.itemsMargin.h : 10;

    //calculate row height
    const rowHeight = (sizes.height - marginH * 2 - (rows - 1) * itemsMarginH) / rows;
    sizes.rowHeight = rowHeight;
    sizes.rows = rows;
    setEditorSize(sizes);
};

export const translateWeatherLang = (lang) => {
    switch (lang) {
        case "es":
            return 4;
        case "fr":
            return 3;
        case "it":
            return 5;
        case "ge":
            return 2;

        default:
            return 1;
    }
};

export const getRGBA = (string) => {
    let match = string?.match(/^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/);
    return match
        ? {
              r: Number(match[1]),
              g: Number(match[2]),
              b: Number(match[3]),
              a: Number(match[4]),
              value: string,
          }
        : {
              r: 0,
              g: 0,
              b: 0,
              a: 1,
              value: `rgba(0,0,0,1)`,
          };
};

const stringColors = {
    black: "rgba(0,0,0,1)",
    white: "rgba(255,255,255,1)",
    red: "rgba(255,0,0,1)",
    green: "rgba(0,255,0,1)",
    blue: "rgba(0,0,255,1)",
    yellow: "rgba(255,255,0,1)",
    orange: "rgba(255,165,0,1)",
    purple: "rgba(128,0,128,1)",
    pink: "rgba(255,192,203,1)",
    brown: "rgba(165,42,42,1)",
    grey: "rgba(128,128,128,1)",
    cyan: "rgba(0,255,255,1)",
    lightblue: "rgba(173,216,230,1)",
    zafiro: "rgba(28,88,166,1)",
    magenta: "rgba(255,0,255,1)",
    lime: "rgba(0,255,0,1)",
    teal: "rgba(0,128,128,1)",
    indigo: "rgba(75,0,130,1)",
    violet: "rgba(238,130,238,1)",
    fuchsia: "rgba(255,0,255,1)",
    aqua: "rgba(0,255,255,1)",
    maroon: "rgba(128,0,0,1)",
    navy: "rgba(0,0,128,1)",
    olive: "rgba(128,128,0,1)",
    silver: "rgba(192,192,192,1)",
    gray: "rgba(128,128,128,1)",
};

export const ensureRGBA = (input) => {
    const str = String(input).toLowerCase();
    if (str && stringColors[str]) {
        return stringColors[str];
    }
    if (str.includes("rgba")) {
        return str;
    }
    if (str.includes("#")) {
        return hexToRgbA(str);
    }
    let match = str.match(/^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/);
    if (!match) {
        return "rgba(0,0,0,1)";
    }
    return `rgba(${match[1]},${match[2]},${match[3]},1)`;
};

export const hexToRgbA = (hex, opacity) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
            "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + (opacity ? opacity / 100 : 1) + ")"
        );
    }
    //throw new Error("Bad Hex");
};

export const setColorAlpha = (color, alpha) => {
    let rgba = getRGBA(ensureRGBA(color));
    rgba.a = alpha ? alpha / 100 : alpha === 0 ? 0 : 1;
    return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
};

export const rgbaToHex = (color, allowAlpha = true) => {
    if (color && /^rgb/.test(color)) {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

        // rgb to hex
        // eslint-disable-next-line no-bitwise
        let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
            .toString(16)
            .slice(1)}`;

        // added alpha param if exists
        if (allowAlpha && rgba[4]) {
            const alpha = Math.round(0o1 * 255);
            const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
            hex += hexAlpha;
        }

        return hex;
    }
    return color;
};
export const cleanGridPlaceholder = () => {
    //Cambio la posición del placeholder, porque si borro el div, al arrastrar un nuevo widget
    //el placeholder no aparece
    const reactPlaceHolders = document.getElementsByClassName("react-grid-placeholder");
    for (let placeholder of reactPlaceHolders) {
        placeholder.style.top = "-400px";
        placeholder.style.left = "-400px";
    }
};

export const calculateByRatio = (cDevice, ratio) => {
    //Calculate editor dimensions according to user screen
    const impersonatedElement = document.querySelector("#root #impersonated");
    const warningsElement = document.querySelector("#root #warnings");
    const submenuElement = document.querySelector("#designSubmenu");
    const devicesElement = document.querySelector("#devicesSubmenu");
    const toolbarElement = document.querySelector("#designToolbar");

    const salesPreviewElement = document.querySelector("#salesPreview");
    const salesToolbarElement = document.querySelector("#salesToolbar");

    let editorWidth, editorHeight;

    if (salesPreviewElement) {
        // Sales editor
        const previewContainer = salesPreviewElement.parentElement;
        const globalContainer = previewContainer?.parentElement;

        if (cDevice.type === DEVICE.TYPE.MOBILE) {
            // In Sales Mobile editor, the ratio is ignored by design0
            ratio = null;
        }

        if (!ratio) {
            editorWidth = previewContainer?.offsetWidth;
            editorHeight = previewContainer?.offsetHeight;
            if (salesToolbarElement) {
                editorHeight -= salesToolbarElement.offsetHeight;
            }
        } else {
            editorHeight = globalContainer?.offsetHeight;
            if (salesToolbarElement) {
                editorHeight -= salesToolbarElement.offsetHeight;
            }
            editorWidth = editorHeight * (ratio[0] / ratio[1]);
            if (editorWidth > previewContainer?.offsetWidth) {
                editorHeight = (previewContainer?.offsetWidth * ratio[1]) / ratio[0];
                editorWidth = previewContainer?.offsetWidth;
            }
        }
        salesPreviewElement.style.width = editorWidth + "px";
        salesPreviewElement.style.height = editorHeight + "px";
    } else {
        // Content editor
        editorHeight =
            window.innerHeight -
            (impersonatedElement ? impersonatedElement.clientHeight : 0) -
            (warningsElement ? warningsElement.clientHeight : 0) -
            (submenuElement ? submenuElement.clientHeight : 0) -
            (devicesElement ? devicesElement.clientHeight : null) -
            remToPixel(4) -
            24; // margin
        editorWidth = (editorHeight * ratio[0]) / ratio[1];
        if (editorWidth > window.innerWidth - (toolbarElement ? toolbarElement.clientWidth : 0) - 50) {
            editorWidth = window.innerWidth - (toolbarElement ? toolbarElement.clientWidth : 0) - 100;
            editorHeight = (editorWidth * ratio[1]) / ratio[0];
        }
    }

    const grid = cDevice ? cDevice.grid : null;
    const cols = grid ? grid.cols : 1;
    const rows = grid ? grid.rows : 1;

    return {
        width: Math.floor(editorWidth),
        height: Math.floor(editorHeight),
        my: 10,
        mx: 10,
        cols,
        rows,
    };
};

export const getElementRects = (element) => {
    const elm = typeof element == "string" ? document.querySelector(element) : element;
    if (elm) {
        const rects = elm.getClientRects();
        if (rects.length > 0) {
            return rects[0];
        }
    }
    return null;
};

export const GetThemeDefault = ({ theme, id }) => {
    const normal = (theme ? theme.default : null) || {};
    const focus = (theme ? theme.focus : null) || {};
    const active = (theme ? theme.active : null) || {};
    switch (id) {
        case "fgColor":
            return normal.rgbaFG;
        case "bgColor":
            return normal.rgbaBG;
        case "focusFgColor":
            return focus.rgbaFG;
        case "focusBgColor":
            return focus.rgbaBG;
        case "fgActiveColor":
            return active.rgbaFG;
        case "bgActiveColor":
            return active.rgbaBG;
        case "disabledFgColor":
        case "disabledFgActiveColor":
            return "rgb(118,136,155)";
        case "disabledBgColor":
        case "disabledBgActiveColor":
            return "rgb(211,218,225)";
        case "borderColor":
            return "";
        case "bgFocusColor":
            return focus.rgbaBG;
        case "fgFocusColor":
            return focus.rgbaFG;
        case "disabledBorderColor":
            return "rgba(0,0,0,.5)";
        case "iconColor":
            return normal.rgbaFG;
        case "iconActiveColor":
            return active.rgbaFG;
        case "iconFocusColor":
            return focus.rgbaFG;
        case "items.bullets.fgColor":
            return "rgb(251,251,251)";
        default:
            console.log("Not found def theme for: " + id);
            console.log(theme);
            return "";
    }
};

export const GetCustomizedScreenIDToRedirect = (id) => {
    const screens = [...document.querySelectorAll("div[data-screen]")].filter((element) =>
        element.getAttribute("data-screen").includes("GENERAL")
    );
    const screensIDS = screens.map((element) => element.getAttribute("data-screen").match(/(\d+)/)[0]);

    if (id !== screensIDS[0]) {
        return screensIDS[0];
    } else if (id === screensIDS[0] && screensIDS.length > 1) {
        return screensIDS[1];
    }
};

export const getDefaultFontSize = () => {
    const element = document.createElement("div");
    element.style.width = "1rem";
    element.style.display = "none";
    document.body.append(element);

    const widthMatch = window.getComputedStyle(element).getPropertyValue("width").match(/\d+/);

    element.remove();

    if (!widthMatch || widthMatch.length < 1) {
        return null;
    }

    const result = Number(widthMatch[0]);
    return !isNaN(result) ? result : null;
};

export const getElementMaxHeight = (parent) => {
    // start with maxHeight of 20rem, search lower if doesnt fit all element
    let remHeight = null;
    const parentTop = document.getElementById(parent)?.getBoundingClientRect().top;
    const parentHeight = document.getElementById(parent)?.getBoundingClientRect().height;
    const defaultFontSize = getDefaultFontSize();
    const maxWindowHeight = window.innerHeight;
    for (let index = 20; index > 0; index--) {
        if (defaultFontSize * index + parentTop + parentHeight < maxWindowHeight && !remHeight) {
            remHeight = index;
        }
    }
    if (!remHeight) {
        remHeight = 20;
    }
    return remHeight;
};

export const convertMsToTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
};

export const arrangeToastMessagesUploadingFiles = (t, filesUploading, totalFiles) => {
    const title = t("x/y files uploaded", {
        count: filesUploading
            ? filesUploading.filter((filesUploading) => {
                  return filesUploading.status === 2 || filesUploading.status === 3;
              }).length
            : 0,
        total: totalFiles,
    });
    const msgs = [];
    if (filesUploading && filesUploading.length > 0) {
        // eslint-disable-next-line
        filesUploading.map((fileUploading) => {
            let description = "";
            let icon = {};
            let size = fileUploading.size ? fileUploading.size : 0;
            let iconAdjust = "inline-grid w-1/12 text-xl";
            let spinner = false;
            let spinnerAdjust = "inline-grid w-1/12 text-xl";
            let spinnerStyle = { transform: "scale(0.3)", transformOrigin: "top left", width: "24px", height: "24px" };
            let link = null;
            if (fileUploading.ref && fileUploading.parentRef && fileUploading.sideBarRef) {
                link = `/design/library/${fileUploading.parentRef}/${fileUploading.ref}/${fileUploading.sideBarRef}`;
            }
            switch (fileUploading.status) {
                case 1:
                    description = capitalizeFirst(t("in progress"));
                    spinner = true;
                    break;
                case 2:
                    description = capitalizeFirst(t("completed"));
                    break;
                case 3:
                    description = capitalizeFirst(t("pending-transcoding"));
                    iconAdjust += " text-orange-100 ";

                    break;
                case 4:
                    let err = t("file x updated error", { name: fileUploading.name });
                    if (
                        fileUploading.errorMessage.includes("already exists") ||
                        fileUploading.errorMessage.includes("name not allowed") ||
                        fileUploading.errorMessage.includes("extension must be") ||
                        fileUploading.errorMessage.includes("name is empty") ||
                        fileUploading.errorMessage.includes("before uploading new files") ||
                        fileUploading.errorMessage.includes("before set the asset as") ||
                        fileUploading.errorMessage.includes("unknown extension") ||
                        fileUploading.errorMessage.includes("content type should be")
                    ) {
                        err = t("general:" + fileUploading.errorMessage);
                    }
                    description = capitalizeFirst(err);

                    break;
                default:
                    break;
            }
            msgs.push({
                adjust: " mb-2 flex justify-between w-full",
                ref: fileUploading.ref,
                text: fileUploading.name,
                textTooltip: fileUploading.name,
                textAdjust: "font-bold block w-full text-zafiro-600 truncate overflow-hidden ",
                textLink: link,
                description: fileUploading && fileUploading.status === 2 ? formatBytes(size) : description,
                descriptionAdjust: "d-ruby text-gray-900 text-left mr-2 float-left",
                descriptionStyle: {
                    margin: "0 0 0 auto",
                    width: spinner ? "40%" : fileUploading && fileUploading.status === 4 ? "auto" : "25%",
                },
                error: fileUploading && fileUploading.status === 4 ? true : false,
                icon:
                    fileUploading && fileUploading.status === 2
                        ? {
                              name: "save-circle",
                              values: "ok",
                          }
                        : fileUploading && fileUploading.status === 4
                        ? {
                              name: "delete-circle",
                              values: "ok",
                          }
                        : icon,
                iconAdjust: iconAdjust,
                spinner: spinner,
                spinnerAdjust: spinnerAdjust,
                spinnerStyle: spinnerStyle,
            });
        });
    }
    return [title, msgs];
};

export const isEmpty = (v) => v === null || v === undefined || isNaN(v) || ("" + v).length === 0;

/**
 * Generates an array with the preset information to display message list in Toast
 *
 * @param {Array} items - The array of items to show messages
 *
 * @returns {Array} - Array list with each message
 */
export const arrangeToastMessagesWhenFailsBatchActions = (items = []) => {
    const msgs = [];
    if (items && items.length > 0) {
        items.forEach((item) => {
            const { name = "", productId = null, storeId = null } = item;

            let textLink = `/services/sales/shop/products/${storeId}`;

            if (productId) {
                textLink = `/services/sales/shop/${storeId}/product/${productId}`;
            }

            msgs.push({
                adjust: " mb-2 flex justify-between items-center w-full",
                error: true,
                icon: {
                    name: "delete-circle",
                    values: "ok",
                },
                iconAdjust: "inline-grid w-1/12 text-xl",
                text: name,
                textAdjust: "font-bold block w-full text-zafiro-600 truncate overflow-hidden ",
                textLink,
                textTooltip: name,
            });
        });
    }

    return msgs;
};

/**
 * Generates a number rounded to the nearest whole number.
 *
 * @param {Integer} number - Current number
 * @param {Integer} decimals - Cant decimals rounded
 *
 * @returns {Integer} - Final number rounded
 */
export const roundDown = (number, decimals = 0) => {
    decimals = decimals || 0;
    return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

/**
 * Searches for a container and hides the corners when a widget is inside a container or inside a menu.
 *
 * @param {String} widgetID - Widget ID prefix to find
 * @param {String} actionStyle - style to be applied to the element found
 * @param {String} containerClass - class containing the items
 *
 */
export const showAndHideResizableHandleWidget = (widgetID, actionStyle, containerClass) => {
    const widgetCurrent = document.getElementById(`widget_${widgetID}`);
    if (widgetCurrent) {
        const itemContainer = widgetCurrent.closest(containerClass);

        if (itemContainer) {
            const buttonsResizable = itemContainer.querySelectorAll(".react-resizable-handle");

            if (buttonsResizable.length) {
                buttonsResizable[buttonsResizable.length - 1].style.visibility = actionStyle;
            }
        }
    }
};

export const useFlattenProjectsDestinations = () => {
    const brands = useBrands();
    const { allProjects } = useAuth();

    useEffect(() => {
        brands.load();
    }, []);

    return (destinations, allowGroups = true) => {
        if (!destinations?.length) {
            return [];
        }

        const includeAllProjects = destinations?.some((item) => item.ref === VISIBILITY.ALL_PROJECTS);
        const newDestinations = (includeAllProjects ? (destinations || []).concat(allProjects) : destinations || [])
            // Remove CORPORATE and ALL_PROJECTS from the list
            .filter((item) => item?.ref !== CORPORATE_REF && item?.ref !== VISIBILITY.ALL_PROJECTS)
            ?.map((p) => {
                if (p?.type === VISIBILITY.ALL_PROJECTS || p?.ref === VISIBILITY.ALL_PROJECTS) {
                    // All projects
                    p.projects = allProjects;
                }
                if (p?.type === VISIBILITY.BRAND) {
                    // Property group
                    p.projects = brands?.data?.length
                        ? brands?.data
                              ?.find((brand) => brand?.ref === p?.ref)
                              ?.projects?.filter((p) => allProjects?.find((ap) => ap?.ref === p?.ref))
                        : [];
                }
                return p;
            });

        if (!newDestinations?.length) {
            return [];
        }

        if (allowGroups) {
            return newDestinations;
        }

        return newDestinations.reduce((acc, d) => {
            if (d?.projects?.length) {
                return acc.concat(d.projects.map((p) => ({ ...p, brand: { ...d, projects: undefined } })));
            }
            return acc.concat(d);
        }, []);
    };
};
