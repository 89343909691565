import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "components/Button";
import Translations from "components/Translations";
import Loading from "components/Loading";
import { useAuth } from "hooks/Session/auth";
import { useAddCategory } from "../../graphql/useCategories";

const ModalAddCategory = (props) => {
    const { t } = useTranslation();

    const [translations, setTranslations] = useState([]);
    const [loading, setLoading] = useState(false);
    const { languages } = useAuth();
    const defaultLanguage = languages?.find((l) => l.isDefault)?.languageRef;
    const { add } = useAddCategory({
        onCompleted: () => {
            setLoading(false);
            props.fetch();
            props.close();
        },
    });

    const handleSaveClick = () => {
        const defaultTranslation = translations.find((tr) => tr.languageCode == defaultLanguage);
        if (defaultTranslation && !defaultTranslation.error) {
            setLoading(true);
            add({
                variables: {
                    id: props.id,
                    name: translations.map((translation) => ({
                        lang: translation.languageCode,
                        text: translation.name,
                    })),
                },
            });
        } else {
            if (!defaultTranslation) {
                setTranslations((prev) => [...prev, { languageCode: defaultLanguage, error: true }]);
            }
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={t("add-category")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={loading} onClick={handleSaveClick}>
                        {t("add")}
                    </Button>
                </>
            }
            className="w-5/12 p-6"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p>{t("write-the-name-and-the-translations")}</p>
                    <Translations
                        id="category-name-translations"
                        languages={languages.map((language) => language.languageRef)}
                        translations={translations}
                        defaultLang={defaultLanguage}
                        onChange={(translations) => {
                            const defaultTranslation = translations.find((tr) => tr.languageCode == defaultLanguage);
                            if (defaultTranslation) {
                                setTranslations(translations);
                            } else {
                                setTranslations([...translations, { languageCode: defaultLanguage, error: true }]);
                            }
                        }}
                        maxHeight="10rem"
                    />
                </div>
            )}
        </Modal>
    );
};

export const useModalAddCategory = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddCategory {...newProps} />);
        },
    };
};
