import React, { useEffect } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { DashboardCards } from "components/Section/DasboardCards/DashboardCards";
import { useListDevices } from "hooks/GraphqlCalls/Hotel/Monitoring/useListDevices";
import { Session } from "hooks/Utils/Session";
import { parseBoolean } from "hooks/Utils/Utils";
import { CastError } from "./CastError";
import { useSelector } from "react-redux";
import NSStatus, { networkServerErrorDiv } from "hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import { NetworkMonitorCards } from "./NetworkMonitorCards";
import { ZabbixError } from "./ZabbixError";
import { useNetworkMonitor } from "hooks/GraphqlCalls/Hotel/Monitoring/useNetworkMonitor";
import { useAuth } from "hooks/Session/auth";
import { MonitorDevices } from "./MonitorDevices";
import { useNetworkMonitorCard } from "hooks/GraphqlCalls/Hotel/Monitoring/useNetworkMonitorCard";

export const Monitoring = () => {
    const { t } = useTranslation();
    const { dashboardCards } = useListDevices();
    const { getZabbixStatus, statusData, statusLoading } = useNetworkMonitor();
    const { summary, loading, getSummary } = useNetworkMonitorCard();
    const { serverError, customServerError } = useSelector((state) => state.table);
    const { permissions } = useAuth();
    const networkWarning = parseBoolean(Session.getSessionProp("networkWarnings"));
    const hasCast = permissions?.product?.cast;
    const hasNetworkMonitor = permissions?.hotel?.networkMonitor;
    NSStatus({ clearTableResults: false, showGenericNotification: false, customServerError: true });

    useEffect(() => {
        if (hasNetworkMonitor) {
            getZabbixStatus();
            getSummary();
        }
    }, [hasNetworkMonitor]);

    return hasNetworkMonitor ? (
        <>
            <UseSectionHeader title={"monitoring"} />
            <main className="bg-white p-6 rounded">
                <section className="flex justify-between">
                    <h1 className="font-bold text-3xl text-gray-900">{t("property-monitor")}</h1>
                    <Button id="btn-property-monitor" design="blue" to={"/hotel/installation/devices/property-monitor"}>
                        {t("monitoring")}
                    </Button>
                </section>
                <p className="my-6 text-gray-900 text-lg">{t("monitor-hotel-and-guest-devices")}</p>
                <DashboardCards data={dashboardCards} monitoring={true} />
                {hasCast && networkWarning && !serverError ? <CastError /> : null}
                {serverError ? networkServerErrorDiv(customServerError, t, true) : null}
            </main>
            <main className="bg-white p-6 rounded mt-4">
                <section className="flex justify-between">
                    <h1 className="font-bold text-3xl text-gray-900">{t("network-monitor")}</h1>
                    <Button id="btn-network-monitor" design="blue" to={"/hotel/installation/devices/network-monitor"}>
                        {t("monitoring")}
                    </Button>
                </section>
                <p className="my-6 text-gray-900 text-lg">{t("newtork-monitor-devices")}</p>
                {summary?.monitoredDevices !== 0 ? (
                    <NetworkMonitorCards monitoring={true} summary={summary} loading={loading} />
                ) : (
                    <p className="text-gray-700">{t("No devices are being monitored yet")}</p> // ESTA TRADUCCIÓN A FUTURO HAY QUE CAMBIARLA
                )}
                {!statusLoading && !statusData?.getNetworkMonitorZabbixStatus?.isAvailable && <ZabbixError />}
            </main>
        </>
    ) : (
        <MonitorDevices />
    );
};
