import { useEffect, useState } from "react";
import { GET_THEME, GET_TICKER_TAPES } from "./queries/tickerTapes";
import {
    DELETE_BANNERS,
    UPDATE_BANNER,
    SET_BANNER_AS_UNAVAILABLE,
    SET_BANNER_AS_AVAILABLE,
    DUPLICATE_BANNER,
    UPDATE_SCHEDULE_BANNER,
    UPDATE_POSITION_SIZE_BANNER,
    UPDATE_DESTINATION_BANNER,
    UPDATE_CONTENT_BANNER,
} from "./mutations/banners";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_BANNERS } from "./queries/banners";
import { ADD_BANNER } from "./mutations/banners";
import { useScreenLinkAlert } from "../modals/modalDestination/Destination/Hooks/useScreenLinkAlert";

export const useListBanners = () => {
    const [banners, setBanners] = useState([]);
    const [idDesignApplied, setIdDesignApplied] = useState(null);
    const { loading: loadingScreenAlert, designApplied, renderBannerNavigationAlert } = useScreenLinkAlert();

    const [listBanners, { loading, error, refetch, data }] = useLazyQuery(GET_BANNERS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!data || loadingScreenAlert) return;

        const banners = (data.banners?.results || []).map((banner) => ({
            ...banner,
            navigationLostAlert: renderBannerNavigationAlert(banner.contentAction || {}),
        }));

        setBanners(banners);
        setIdDesignApplied(designApplied?.id || null);
    }, [data, designApplied, loadingScreenAlert]);

    return {
        getBanners: listBanners,
        loading: loading,
        error: error,
        banners: banners,
        refetchBanners: refetch,
        idDesignApplied: idDesignApplied,
    };
};

export const useAddBanner = (props) => {
    const [executeMutation] = useMutation(ADD_BANNER, {
        onCompleted({ createBanner }) {
            props.onCompleted({ id: createBanner?.id });
        },
    });

    return {
        addBanner: executeMutation,
    };
};

export const useDeleteBanner = (props) => {
    const [mutation] = useMutation(DELETE_BANNERS, {
        onCompleted() {
            props.onCompleted();
        },
        onError: () => {
            props.onError();
        },
    });

    return {
        mutation,
    };
};

export const useUpdateBanner = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_BANNER, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        update: executeMutation,
        loading,
    };
};

export const useUpdateContentBanner = (props) => {
    const [executeMutation] = useMutation(UPDATE_CONTENT_BANNER, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        executeUpdate: executeMutation,
    };
};

export const useSetAvailabilityBanner = (props) => {
    const [setAsUnavailable] = useMutation(SET_BANNER_AS_UNAVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    const [setAsAvailable] = useMutation(SET_BANNER_AS_AVAILABLE, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        setAsAvailable,
        setAsUnavailable,
    };
};

export const useDuplicateBanner = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_BANNER, {
        onCompleted() {
            props.onCompleted();
        },
    });
    return {
        duplicate: executeMutation,
        loading,
    };
};

export const useUpdateScheduleBanner = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_SCHEDULE_BANNER, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        updateScheduleBanner: executeMutation,
        loadingScheduleBanner: loading,
    };
};

export const useUpdateSizePositionBanner = () => {
    const [executeMutation, { loading }] = useMutation(UPDATE_POSITION_SIZE_BANNER, {
        onCompleted() {
            // props.onCompleted();
        },
    });

    return {
        updateSizePositionBanner: executeMutation,
        loadingScheduleBanner: loading,
    };
};

export const useUpdateDestination = (props) => {
    const [executeUpdate, { loading }] = useMutation(UPDATE_DESTINATION_BANNER, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        executeUpdate,
        loadingUpdate: loading,
    };
};

// export const useTheme = () => {
//     const [theme, setTheme] = useState(null);

//     const [getTheme, { loading, error, refetch, data }] = useLazyQuery(GET_THEME, {
//         nextFetchPolicy: "cache-first",
//         onCompleted: (data) => {
//             if (data?.themes?.results?.length > 0) {
//                 const colors = data.themes.results[0].config.colors;
//                 setTheme(colors);
//             }
//         },
//     });

//     return {
//         getTheme,
//         theme,
//     };
// };

// export const useAddTickerTape = (props) => {
//     const [executeMutation] = useMutation(ADD_TICKER_TAPE, {
//         onCompleted({ createTickerTape }) {
//             props.onCompleted({ id: createTickerTape?.id });
//         },
//     });

//     return {
//         addTickerTape: executeMutation,
//     };
// };

// export const useDeleteTickerTapes = (props) => {
//     const [executeMutation] = useMutation(DELETE_TICKER_TAPES, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });

//     return {
//         deleteTickerTapes: executeMutation,
//     };
// };

// export const useUpdateTickerTapes = (props) => {
//     const [executeMutation] = useMutation(UPDATE_TICKER_TAPE, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });

//     return {
//         updateTickerTape: executeMutation,
//     };
// };

// export const useSetAvailabilityTickerTape = (props) => {
//     const [setAsUnavailable] = useMutation(SET_TICKER_TAPE_AS_UNAVAILABLE, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });

//     const [setAsAvailable] = useMutation(SET_TICKER_TAPE_AS_AVAILABLE, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });

//     return {
//         setAsAvailable,
//         setAsUnavailable,
//     };
// };

// export const useDuplicateTickerTape = (props) => {
//     const [executeMutation, { loading }] = useMutation(DUPLICATE_TICKER_TAPE, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });
//     return {
//         duplicateTickerTape: executeMutation,
//         loading,
//     };
// };

// export const useUpdateScheduleTickerTape = (props) => {
//     const [executeMutation, { loading }] = useMutation(UPDATE_SCHEDULE_TICKER_TAPE, {
//         onCompleted() {
//             props.onCompleted();
//         },
//     });

//     return {
//         updateScheduleTickerTape: executeMutation,
//         loadingScheduleTickerTape: loading,
//     };
// };

// export const useUpdateDisplayTickerTape = (props) => {
//     const [executeMutation, { loading }] = useMutation(UPDATE_DISPLAY_TICKER_TAPE, {
//         onCompleted() {
//             if (props?.onCompleted) {
//                 props.onCompleted();
//             }
//         },
//     });

//     return {
//         updateDisplayTickerTape: executeMutation,
//         loadingScheduleTickerTape: loading,
//     };
// };
