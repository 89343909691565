import React, { useState, useEffect } from "react";
//Utils
import { createPopper } from "@popperjs/core";
import { setWidgetToolbarVisible } from "../actions/designActions";
import { useDispatch } from "react-redux";

import Icon from "components/Icon";

/**
 * @param props {{disabled: boolean | undefined}}
 * @returns {{isOpen: boolean, Handler: React.Component, Body: (function(*): *), close: ()=>void, open: ()=>void}}
 */
const useDropdown = (props) => {
    const dispatch = useDispatch();
    const config = {
        disabled: false,
        ...props,
    };

    const [closed, setClosed] = useState(true);

    const refButton = React.useRef();
    const refDropDown = React.useRef();

    const openDropDown = () => {
        setClosed(false);
        //close other widget toolbar
        dispatch(setWidgetToolbarVisible(null, "regular"));
        if (refDropDown && refDropDown.current) {
            createPopper(refButton.current, refDropDown.current, {
                placement: "bottom-end",
                modifiers: [{ name: "offset", options: { offset: () => [0, 3] } }],
            });
        }
    };

    const closeDropDown = () => {
        setClosed(true);
    };

    /**
     * @param e {PointerEvent}
     */
    const handleClick = (e) => {
        const btn = refButton.current;
        const list = refDropDown.current;
        const clickOnHandler = btn && (e.target === btn || btn.contains(e.target));
        const clickOnList = list && (e.target === list || list.contains(e.target));
        if (clickOnList || (list && list.children && list.children.length === 1)) {
            return;
        }
        if (clickOnHandler && closed) {
            openDropDown();
        } else if (!closed) {
            closeDropDown();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => document.removeEventListener("mousedown", handleClick);
    });

    const DropdownHandler = (props) => {
        return (
            <>
                <Icon type="refactor" tooltip="Please, use new Select component" />
                <div
                    ref={refButton}
                    className={config.disabled ? " cursor-default opacity-75" : " cursor-pointer"}
                    {...props}
                >
                    {typeof props.children !== "undefined" ? props.children : "SELECT"}
                </div>
            </>
        );
    };

    const DropdownBody = (props) => {
        return (
            <div ref={refDropDown} hidden={closed || config.disabled} {...props}>
                {props.children}
            </div>
        );
    };

    return {
        Handler: DropdownHandler,
        Body: DropdownBody,
        open: openDropDown,
        close: closeDropDown,
        isOpen: !closed,
    };
};

export default useDropdown;
