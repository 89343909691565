import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal, { ModalSize, useModal } from "components/Modal";
import TextInput from "components/TextInput";
import Button from "components/Button";
import { useUpdateFloor, useUpdateWing } from "hooks/GraphqlCalls/Hotel/useTopologyMap";
import { toast } from "react-toastify";
import { deleteFloor, deleteWing, editName, editNameFloor, editPmsNumber } from "../TopologyItem";

export const EditModal = ({ name, pmsNumber, wingId, floorId, mapRefetch, mode, isWing }) => {
    const [changedName, setChangedName] = useState(name);
    const [changePmsNumber, setChangePmsNumber] = useState(pmsNumber);
    const { updateWing, deleteWing: removeWing } = useUpdateWing();
    const { updateFloor, deleteFloor: removeFloor } = useUpdateFloor();
    const { close } = useModal();
    const { t } = useTranslation();

    const isEditName = mode === editName || mode === editNameFloor;
    const isEditPmsNumber = mode === editPmsNumber;
    const isDelete = mode === deleteWing || mode === deleteFloor;

    const handleSave = () => {
        if (isWing) {
            if (isEditName) {
                if (changedName) {
                    updateWing(wingId, changedName);
                } else {
                    toast.error(t("input error"));
                }
            } else if (isEditPmsNumber) {
                if (changePmsNumber) {
                    updateWing(wingId, null, changePmsNumber);
                } else {
                    toast.error(t("input error"));
                }
            } else if (isDelete) {
                removeWing(wingId);
            }
        } else {
            if (isEditName) {
                if (changedName) {
                    updateFloor(floorId, changedName);
                } else {
                    toast.error(t("input error"));
                }
            } else if (isDelete) {
                removeFloor(floorId);
            }
        }
        setTimeout(() => mapRefetch(), 1000);
        if (changedName || changePmsNumber) {
            close();
        }
    };

    const modalTitle = `${name || pmsNumber} - ${t(
        isEditName ? "edit-name" : isEditPmsNumber ? "edit-pmsnumber" : "delete"
    )}`;
    const modalText = isEditName
        ? isWing
            ? "write-wingname"
            : "write-floorname"
        : isEditPmsNumber
        ? "write-pmsnumber"
        : isWing
        ? "topology-delete-wing"
        : "topology-delete-floor";
    const modalButton = isEditName ? "rename" : isEditPmsNumber ? "rename" : "delete";

    return (
        <Modal
            title={`${modalTitle}`}
            size={ModalSize.SMALL}
            onClose={close}
            className="py-10 px-12 w-1/3"
            footer={
                <div className="mt-4 flex items-center gap-4">
                    <Button className="btn-white" id="modal-button-cancel" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design={isDelete ? "red" : "blue"}
                        id={`modal-button-${isDelete ? "delete" : "save"}`}
                        onClick={handleSave}
                    >
                        {t(modalButton)}
                    </Button>
                </div>
            }
        >
            <p className="my-4">{t(modalText)}</p>
            {isEditName && (
                <TextInput
                    id="name"
                    label={t("name")}
                    value={changedName}
                    className={`${!changedName && "border border-red-100"}`}
                    onChange={(value) => setChangedName(value)}
                />
            )}
            {isEditPmsNumber && (
                <TextInput
                    id="pmsnumber"
                    label={t("pmsNumber")}
                    value={changePmsNumber}
                    className={`${!changePmsNumber && "border border-red-100"}`}
                    onChange={(value) => setChangePmsNumber(value)}
                />
            )}
        </Modal>
    );
};
