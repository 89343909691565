import React from "react";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import { useDeleteRoom } from "hooks/GraphqlCalls/Hotel/Settings/useDeleteRoom";
import Button from "components/Button";
import { toast } from "react-toastify";

export const DeleteRoomModal = ({ name, mapRefetch, recentGuests, id, isCommonZone }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { deleteRoomMutation } = useDeleteRoom();

    return (
        <Modal title={`${name} - ${t("delete")}`} className="w-1/3 py-10 px-12">
            <main className="mb-10">
                {recentGuests && <p className="mb-4">{t("topology-delete-room-had-guests")}</p>}
                <p>
                    {t("topology-delete-room", {
                        room: isCommonZone ? t("common-zone").toLocaleLowerCase() : t("room").toLocaleLowerCase(),
                    })}
                </p>
            </main>
            <footer className="flex justify-end gap-2">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button
                    id="delete"
                    design="red"
                    onClick={() => {
                        if (recentGuests) {
                            toast.error(t("at-least-one-stayguest-checked-in"));
                        } else {
                            deleteRoomMutation(id);
                            setTimeout(() => mapRefetch(), 1000);
                        }
                        close();
                    }}
                >
                    {t("delete")}
                </Button>
            </footer>
        </Modal>
    );
};
