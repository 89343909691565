import React from "react";
import { convertMsToTime } from "hooks/Utils/DesignUtils";
import { formatBytes } from "hooks/Utils/Utils";
import i18n from "i18n";
import moment from "moment";
import DatePicker from "react-datepicker";
import Button from "components/Button";
import classNames from "classnames";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";

export const getDaysNamesByValues = (values) => {
    values = values.filter((value, index, self) => value >= 1 && value <= 7 && self.indexOf(value) === index);

    values.sort((a, b) => a - b);

    const daysOfWeek = ["day-1", "day-2", "day-3", "day-4", "day-5", "day-6", "day-7"];
    const translatedDays = values.map((value) => i18n.t(daysOfWeek[value - 1]));
    return translatedDays.join(", ");
};

export const getTimesRangesFormatted = (timeRanges) => {
    const formattedRanges = timeRanges.map((range) => {
        const startTime = range.startTime.slice(0, 5);
        const endTime = range.endTime.slice(0, 5);

        return `${startTime} - ${endTime}`;
    });

    return formattedRanges.join(" / ");
};

export const isSchedulling = (data) => {
    if (!data?.scheduleDailyType && !data?.scheduleDaysType && !data?.scheduleStart) {
        return false;
    } else {
        return true;
    }
};

export const ScheduledDateColumn = (props) => {
    const startDate = props.scheduleStart
        ? new window.ZDate(props.scheduleStart).toLocaleString(null, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })
        : "-";
    const endDate = props.scheduleEnd
        ? `- ${new window.ZDate(props.scheduleEnd).toLocaleString(null, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })}`
        : "";
    return `${startDate} ${endDate}`;
};

export const formatDateYYYYMMDD = (date) => {
    return moment(date).format("YYYY-MM-DD");
};

export const buildAssetInformation = (props) => {
    const defaultFile =
        props.languages.find((val) => val.language == props.defaultLanguage) ||
        props.languages.find((val) => val.language == props.defaultFileLanguage);
    const name = defaultFile?.name || props?.name;
    const type =
        defaultFile?.contentType.split("/")?.[1].toUpperCase() || props?.contentType.split("/")?.[1].toUpperCase();
    const size = formatBytes(defaultFile?.size || props?.size);
    const dimensions = defaultFile?.dimensions
        ? `${defaultFile?.dimensions?.width} x ${defaultFile?.dimensions?.height}`
        : "";
    const languages =
        props.defaultFileLanguage == ""
            ? i18n.t("multi-language")
            : props.languages
                  .reduce((acc, curr) => {
                      let text = "";
                      if (curr.language) {
                          text = i18n.t("language:" + curr.language);
                          if (curr.language == props.defaultFileLanguage) {
                              text += `(${i18n.t("default-lang")})`;
                          }
                          acc.push(text);
                      }
                      return acc;
                  }, [])
                  .join(",");
    return { name, type, size, dimensions, languages };
};

export const buildVideoInformation = (props) => {
    const defaultFile =
        props.languages.find((val) => val.language == props.defaultLanguage) ||
        props.languages.find((val) => val.language == props.defaultFileLanguage);
    const name = defaultFile?.name || props?.name;
    const type = defaultFile?.contentType || props?.contentType.split("/")?.[1].toUpperCase();
    const size = formatBytes(defaultFile?.size || props?.size);
    const duration = defaultFile.duration ? convertMsToTime(defaultFile?.duration || props?.duration) : "";
    const quality = i18n.t(defaultFile?.resolution).split("-")[0];
    const languages =
        props.defaultFileLanguage == ""
            ? i18n.t("multilanguage")
            : props.languages
                  .reduce((acc, curr) => {
                      let text = "";
                      if (curr.language) {
                          text = i18n.t("language:" + curr.language);
                          if (curr.language == props.defaultFileLanguage) {
                              text += `(${i18n.t("default-lang")})`;
                          }
                          acc.push(text);
                      }
                      return acc;
                  }, [])
                  .join(",");
    return { name, type, size, duration, quality, languages };
};

export const formatSecondsToHMS = (seconds) => {
    return moment.utc(moment.duration(seconds, "seconds").asMilliseconds()).format("HH:mm:ss");
};

export const formatMillisecondsToHMS = (milliseconds) => {
    return moment.utc(milliseconds).format("HH:mm:ss");
};

export const checkHasLinkedSignages = (contentTrees) => {
    const searchDir = (dir) => {
        if (!dir) return false;
        if (Array.isArray(dir.contents)) {
            for (const content of dir.contents) {
                if (Array.isArray(content.linkedSignages) && content.linkedSignages.length > 0) {
                    return true;
                }
            }
        }

        if (Array.isArray(dir.subDirs)) {
            for (const subDir of dir.subDirs) {
                if (searchDir(subDir)) return true;
            }
        }

        return false;
    };

    for (const key in contentTrees) {
        if (contentTrees[key]?.screens?.dir && searchDir(contentTrees[key].screens.dir)) {
            return true;
        }
    }

    return false;
};

export const getCurrentTime = () => {
    const time = Math.abs(new window.ZDate().getTimezoneOffset());
    const hours = parseInt(time / 60);
    const minutes = time % 60;
    let date = new Date();
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);

    return date;
};

export const parseDateHour = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
};

export const validateRanges = (timeRanges) => {
    let validationResults = {};

    for (let i = 0; i < timeRanges.length; i++) {
        const range = timeRanges[i];

        let isStartValid = true;
        let isEndValid = true;

        if (!range.startTime) {
            isStartValid = false;
        }
        if (!range.endTime) {
            isEndValid = false;
        }
        if (range.startTime && range.endTime && range.endTime <= range.startTime) {
            isEndValid = false;
        }

        for (let j = 0; j < timeRanges.length; j++) {
            if (i === j) continue;
            const otherRange = timeRanges[j];
            if (
                (range.startTime && range.startTime >= otherRange.startTime && range.startTime < otherRange.endTime) ||
                (range.endTime && range.endTime > otherRange.startTime && range.endTime <= otherRange.endTime)
            ) {
                isStartValid = false;
                isEndValid = false;
                break;
            }
        }

        validationResults[i] = { start: isStartValid, end: isEndValid };
    }

    return validationResults;
};

export const convertInitialTimeRanges = (timeRanges) => {
    return timeRanges.map((range) => {
        const currentDate = new Date();

        const startTimeParts = range.startTime.split(":");
        const startTime = new Date(currentDate);
        startTime.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]), parseInt(startTimeParts[2]));

        const endTimeParts = range.endTime.split(":");
        const endTime = new Date(currentDate);
        endTime.setHours(parseInt(endTimeParts[0]), parseInt(endTimeParts[1]), parseInt(endTimeParts[2]));

        return {
            ...range,
            startTime: startTime,
            endTime: endTime,
        };
    });
};

export const convertTimeRanges = (timeRanges) => {
    return timeRanges.map((range) => {
        return {
            startTime: parseDateHour(range.startTime),
            endTime: parseDateHour(range.endTime),
        };
    });
};

export const formatDate = (date) => {
    console.log("date", date);

    return new Date(format(parseISO(date), "MM-dd-yyyy"));
};

export const TimePickerComponent = ({ index, startTime, endTime, isValid, updateScheduleItem, deleteScheduleItem }) => {
    const { t } = useTranslation();

    const parseDateHour = (value) => {
        return value;
    };

    return (
        <div className="flex items-center mt-10 h-full w-full mb-2">
            <div className="flex flex-col mr-8">
                <div className=" font-bold mb-2 whitespace-no-wrap text-gray-800">{t("start-time")}*</div>
                <DatePicker
                    selected={startTime}
                    onChange={(value) => {
                        updateScheduleItem(index, "startTime", parseDateHour(value));
                    }}
                    dateFormat="HH:mm"
                    timeCaption="Hour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    name={`startTime_${index}`}
                    isClearable={false}
                    customInput={
                        <input
                            type="time"
                            id={`start-time-input-range-${index}`}
                            className={`time-input t-filter-input text-center inline-block py-2 px-3 w-full ${
                                isValid?.start === false ? "border border-red-100" : " border border-transparent"
                            }`}
                            placeholder="hh:mm"
                        />
                    }
                />
            </div>
            <div className="flex flex-col ml-8">
                <div className=" font-bold mb-2 whitespace-no-wrap text-gray-800">{t("end-time")}*</div>
                <DatePicker
                    selected={endTime}
                    onChange={(value) => {
                        updateScheduleItem(index, "endTime", parseDateHour(value));
                    }}
                    dateFormat="HH:mm"
                    timeCaption="Hour"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={10}
                    name={`endTime_${index}`}
                    isClearable={false}
                    customInput={
                        <input
                            type="time"
                            id={`end-time-input-range-${index}`}
                            className={`time-input t-filter-input text-center inline-block py-2 px-3 w-full ${
                                isValid?.end === false ? "border border-red-100" : " border border-transparent"
                            }`}
                            placeholder="hh:mm"
                        />
                    }
                />
            </div>

            <Button
                onClick={() => deleteScheduleItem(index)}
                className={classNames({
                    "pl-5 h-full mt-6": true,
                    "opacity-0": index === 0,
                    "opacity-100": index !== 0,
                })}
                id={`time-picker-delete${index}`}
                disabled={index === 0}
            >
                <Icon type="delete" size={1.5} className="text-gray-800" />
            </Button>
        </div>
    );
};
