const GetRowActionModalButtons = (actionName) => {
    let buttons = [{ name: "cancel", style: "white", action: "close" }];
    if (
        actionName !== "media-library-delete-permanently" &&
        (actionName.includes("delete") || actionName.includes("erase"))
    ) {
        buttons.push({
            name: "delete",
            style: "red",
            action: "delete",
        });
    } else {
        switch (actionName) {
            case "area-open":
                buttons = [
                    { name: "cancel", style: "blue", action: "close" },
                    {
                        name: actionName,
                        style: "white",
                        action: actionName,
                    },
                ];
                break;
            case "edit-companion":
                buttons.push({
                    name: "save",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "edit-staff-user":
                buttons.push({
                    name: "save",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "area-see-link":
                buttons.push({
                    name: "area-copy-link",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "chromecast-change-pairing":
                buttons.push({
                    name: "change",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "add-filter":
                buttons.push({
                    name: "accept",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "email-booking-notification":
                buttons.push({
                    name: "add",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "media-library-update-media":
                buttons.push({
                    name: "save",
                    style: "blue hidden",
                    action: actionName,
                });
                break;
            case "media-library-move-content":
                buttons.push({
                    name: "move-here",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "media-library-delete-permanently":
                buttons.push({
                    name: "delete-permanently",
                    style: "red",
                    action: actionName,
                });
                break;
            case "edit-design":
            case "edit-template-name":
            case "edit-name-shop":
            case "set-as-available-product":
            case "set-as-unavailable-product":
            case "set-as-available-category":
            case "set-as-unavailable-category":
            case "": //TODO: remove this case
                buttons.push({
                    name: "save",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "handle-mac-exceptions":
                buttons = [
                    {
                        name: "close",
                        style: "blue",
                        action: "close",
                    },
                ];
                break;
            case "edit-hotspot-link":
            case "edit-alarm":
            case "edit-hotspot-name":
            case "edit-input-group":
            case "device-edit-tv-inputs":
                buttons = [
                    {
                        name: "close",
                        style: "white",
                        action: "close",
                    },
                    {
                        name: "save",
                        style: "blue",
                        action: actionName,
                    },
                ];
                break;
            case "tv-uninstall":
                buttons.push({
                    name: "uninstall",
                    style: "red",
                    action: actionName,
                });
                break;
            case "manage-tvs-assigned":
                buttons = [
                    {
                        name: "cancel",
                        style: "white",
                        action: "close",
                    },
                    {
                        name: "save",
                        style: "blue",
                        action: actionName,
                    },
                ];
                break;
            case "set-as-available-shop":
                buttons.push({
                    name: "set-as-available",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "set-as-unavailable-shop":
                buttons.push({
                    name: "set-as-unavailable",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "edit-shop":
                buttons.push({
                    name: "save",
                    style: "blue",
                    action: "edit-shop",
                });
                break;
            case "device-deauthorise":
                buttons.push({
                    action: actionName,
                    name: "deauthorise",
                    style: "blue",
                });
                break;
            case "device-uninstall":
                buttons.push({
                    action: actionName,
                    name: "uninstall",
                    style: "red",
                });
                break;
            case "device-hide-status":
                buttons.push({
                    action: actionName,
                    name: "disable",
                    style: "blue",
                });
                break;
            case "device-reload-interface":
                buttons.push({
                    action: actionName,
                    name: "tv-reload",
                    style: "blue",
                });
                break;
            case "chromecast-launch-settings-screen":
            case "device-launch-system-settings-screen":
            case "chromecast-launch-system-settings-screen":
            case "device-launch-settings-screen":
                buttons.push({
                    action: actionName,
                    name: "launch",
                    style: "blue",
                });
                break;
            case "chromecast-pairing-remote-control":
            case "device-pairing-remote-control":
                buttons.push({
                    action: actionName,
                    name: "start",
                    style: "blue",
                });
                break;
            case "device-detach-from-property":
                buttons.push({
                    action: actionName,
                    name: "detach",
                    style: "red",
                });
                break;
            case "device-restart":
                buttons.push({
                    action: actionName,
                    name: "chromecast-reboot",
                    style: "blue",
                });
                break;
            case "device-move-room":
                buttons.push({
                    action: actionName,
                    name: "move",
                    style: "blue",
                });
                break;
            case "device-assign":
                buttons.push({
                    action: actionName,
                    name: "assign",
                    style: "blue",
                });
                break;
            case "device-edit-name":
            case "device-change-device-function":
                buttons.push({
                    action: actionName,
                    name: "save",
                    style: "blue",
                });
                break;
            case "device-authorise":
                buttons.push({
                    action: actionName,
                    name: "authorise",
                    style: "blue",
                });
                break;
            case "device-unassign":
                buttons.push({
                    action: actionName,
                    name: "unassign",
                    style: "blue",
                });
                break;
            case "device-authorise-and-assign":
                buttons.push({
                    action: actionName,
                    name: "authorise-and-assign",
                    style: "blue",
                });
                break;
            case "device-show-status":
                buttons.push({
                    action: actionName,
                    name: "show-status",
                    style: "blue",
                });
                break;
            case "device-power-off":
                buttons.push({
                    action: actionName,
                    name: "tv-power-off",
                    style: "blue",
                });
                break;
            case "device-power-on":
                buttons.push({
                    action: actionName,
                    name: "tv-power-on",
                    style: "blue",
                });
                break;
            case "debbug-mode-off":
                buttons.push({
                    action: actionName,
                    name: "debbugMode-off",
                    style: "blue",
                });
                break;
            case "debbug-mode-on":
                buttons.push({
                    action: actionName,
                    name: "debbugMode-on",
                    style: "blue",
                });
                break;
            case "device-factory-reset":
                buttons.push({
                    name: "restore",
                    style: "blue",
                    action: actionName,
                });
                break;
            case "resend-pre-check-in":
                buttons.push({ name: "resend", style: "blue", action: "resend-pre-check-in" });
                break;
            case "execute-checkin":
                buttons.push({ name: "check-in", style: "blue", action: "execute-checkin" });
                break;
            default:
                buttons.push({
                    name: actionName,
                    style: "blue",
                    action: actionName,
                });
                break;
        }
    }

    return buttons;
};

export default GetRowActionModalButtons;
