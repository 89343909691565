import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//API
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";

const RoomPreview = (roomtvid, lang, guest, roomName, project) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const GET_HTML_PREVIEW = gql`{
        preview: htmlPreviewPageRoom( roomTvID :"${roomtvid}" lang:"${lang}")
    }`;

    const [executeQuery, { data, error, loading }] = useLazyQuery(GET_HTML_PREVIEW);

    const noGuestHTML = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <style>
                @import url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css);

                * {
                    box-sizing: border-box;
                }
                html {
                    line-height: 1.2;
                }
                body {
                    color: white;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-color: black;
                    font-size: 16px;
                    margin-left: auto;
                    position: relative;
                    overflow: hidden;
                    width: 100vw;
                    height: 100vh;
                    max-width: 100vw;
                    max-height: 100vh;
                }
                .mleft-auto, .mx-auto {
                    margin-left: auto;
                }
                .right-auto, .mx-auto {
                    margin-right: auto;
                }
                .vertical-middle {
                    vertical-align: middle;
                }
                .table {
                    display: table;
                }
                .table-cell {
                    display: table-cell;
                }    
                .p-8 {
                    padding: 1.5rem;
                }
                .h-auto {
                    height: auto;
                }
                .h-full {
                    height: 100%;
                }
                .rounded {
                    border-radius: .25rem;
                }
                .inline-block {
                    display: inline-block;
                }
                .text-gray-100 {
                    color: #f5f6f8;
                }
                .bg-gray-800 {
                    background-color: #576575;
                }
                .relative {
                    position: relative;
                }
                .w-full {
                    width: 100%;
                }
                .text-center {
                    text-align: center;
                }    
                .title {
                    width: 100%;
                    font-weight: 700;
                    font-size: 2vw;
                    padding-bottom: 4vh;
                }
                .room {
                    padding-bottom: 4vh;
                }
            </style>
        </head>
        <body>
            <div id="pop-up-zone" class="table h-full mx-auto">
                <div class="table-cell vertical-middle">
                    <div class="bg-gray-800 text-gray-100 inline-block rounded h-auto p-8">
                        <div class="relative"></div>
                        <div class="w-full text-center">
                            <div id="projectName" class="title">${project.name}</div>
                            <div id="roomNumber" class="room">${roomName}</div>
                            <div id="noGuestMessage" class="status">No hay huésped en la habitación</div>
                        </div>
                    </div>
                </div>
            </div>
            <script>
                window.onload = function() {                
                    document.getElementById('projectName').innerHTML = '${project.name}';
                    document.getElementById('roomNumber').innerHTML = '${roomName}';
                    if (navigator.language.indexOf("es") > -1) {
                        document.getElementById('noGuestMessage').innerHTML = 'No hay huésped en la habitación';
                    } else {
                        document.getElementById('noGuestMessage').innerHTML = 'No guest checked in';
                    }
                };
            </script>
        </body>
        </html>
    `;

    useEffect(() => {
        const setFrameContent = () => {
            const previewFrame = document.getElementById("roomPreviewFrame");
            if (!previewFrame) return;

            if (!guest) {
                previewFrame.srcdoc = noGuestHTML;
                return;
            }
            dispatch(showGlobalLoading(true));
            executeQuery();
        };

        let previewFrame = document.getElementById("roomPreviewFrame");
        if (!previewFrame) {
            const interval = setInterval(() => {
                previewFrame = document.getElementById("roomPreviewFrame");
                if (previewFrame) {
                    clearInterval(interval);
                    setFrameContent();
                }
            }, 100);
            return () => clearInterval(interval);
        } else {
            setFrameContent();
        }
    }, [guest, roomtvid, lang, executeQuery, dispatch, t, roomName]);

    useEffect(() => {
        const previewFrame = document.getElementById("roomPreviewFrame");
        if (!previewFrame) return;

        if (!loading && !error && data && data.preview) {
            previewFrame.srcdoc = data.preview;
            dispatch(showGlobalLoading(false));
        }
        //eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        const previewFrame = document.getElementById("roomPreviewFrame");
        if (!previewFrame) return;

        if (error) {
            previewFrame.srcdoc = `<div style="width:100%; min-height:100vh; display: flex; align-items: center; justify-content: center">${t(
                "technical-error"
            )}</div>`;
            dispatch(showGlobalLoading(false));
        }
        //eslint-disable-next-line
    }, [error]);
};

export default RoomPreview;
