import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useComponentVisible from "../useComponentVisible";
import UseSelectOption from "./useSelectOption";
import { changeActionValues, setActionInput } from "../../actions/globalActions";
import { useTranslation } from "react-i18next";
import { multipleTranslate } from "../../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";

import Icon from "components/Icon";

function useSelect({ selectData, enabled = true }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { validations } = useSelector((state) => state.action);
    const {
        id,
        name,
        data,
        containerOptionsAdjust,
        containerOptionsStyles,
        inputContainerAdjust,
        textInputContainerAdjust,
        label,
        labelStyle,
        placeHolder,
        noTranslatePH,
        noTranslateAV,
        useActionInput,
        inputIconContainerAdjust,
        handleChange,
        description,
        descriptionStyle,
    } = selectData;
    const [activeValue, setActiveValue] = useState(selectData.activeValue);

    const ICA = inputContainerAdjust;
    const TICA = textInputContainerAdjust;
    const IICA = inputIconContainerAdjust;

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selectActive, setSelectActive] = useState({});
    const [textToRender, setTextToRender] = useState(null);
    const [passValidation, setPassValidation] = useState(true);

    //Actions
    useEffect(() => {
        setActiveValue(selectData.activeValue);
    }, [selectData.activeValue]);

    useEffect(() => {
        const dataToAction = { [id]: activeValue };
        if (useActionInput) {
            dispatch(setActionInput(dataToAction));
        } else {
            dispatch(changeActionValues(dataToAction));
        }
        if (handleChange && typeof handleChange === "function") {
            handleChange(activeValue);
        }
        setTextToRender(data.find((item) => item.value === activeValue)?.text);
        // eslint-disable-next-line
    }, [activeValue]);

    const handleChangeFilters = (e) => {
        const dataId = e.currentTarget.getAttribute("data-id");
        const textToRender = e.currentTarget.getAttribute("value");
        setTextToRender(textToRender);
        setActiveValue(dataId);
        setIsComponentVisible(false);
    };

    useEffect(() => {
        if (typeof validations[id] !== "undefined") {
            setPassValidation(validations[id]);
        } else {
            setPassValidation(true);
        }
        // eslint-disable-next-line
    }, [validations]);

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    return (
        <>
            <Icon type="refactor" tooltip="Please, use new Select component" />
            {label ? (
                <span className={labelStyle ? labelStyle : "block pb-2 first-capital"}>
                    {multipleTranslate(label, t)}
                </span>
            ) : null}
            {description ? (
                <span className={descriptionStyle ? descriptionStyle : "block pb-2 first-capital"}>
                    {Parser(multipleTranslate(description, t))}
                </span>
            ) : null}
            <div
                ref={ref}
                className={`w-full ${ICA ? ICA : "mt-1 h-10"} bg-gray-200 rounded relative ${selectActive.container} ${
                    !passValidation ? "border border-red-100" : ""
                }`}
            >
                <div
                    className={`t-filter-ph ${selectActive.text} ${!enabled ? "disabled" : "text-gray-900"}`}
                    onClick={enabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
                    id={id ? id : null}
                >
                    <div
                        className={`first-capital ${TICA ? TICA : ""} text-left ${!activeValue ? "text-gray-600" : ""}`}
                    >
                        {renderLabel({ name, textToRender, placeHolder, noTranslatePH, noTranslateAV, data, t })}
                    </div>
                </div>
                <span
                    className={`field-icon ${IICA ? IICA : ""} icon-chevron animated ${selectActive.icon} ${
                        !enabled ? "disabled" : "text-gray-900"
                    }`}
                    onClick={enabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
                    id={id ? `${id}-icon` : null}
                ></span>
                {isComponentVisible ? (
                    <div
                        className={`t-options-container p-2 ${containerOptionsAdjust}`}
                        style={containerOptionsStyles ? containerOptionsStyles : {}}
                    >
                        {data.map((option, index) =>
                            option ? (
                                <div
                                    className="h-10 hover:bg-gray-100"
                                    onClick={handleChangeFilters}
                                    id={`use-select-option-${index}`}
                                    data-id={option.value}
                                    key={option.text}
                                    value={option.text}
                                >
                                    <UseSelectOption
                                        selectName={name}
                                        value={option.text}
                                        translatable={false}
                                        selected={activeValue}
                                    />
                                </div>
                            ) : null
                        )}
                    </div>
                ) : null}
            </div>
        </>
    );
}

const renderLabel = (props) => {
    const { name, textToRender, placeHolder, noTranslatePH, noTranslateAV, data, t } = props;
    let activeString = null;
    let response = textToRender;
    if (textToRender?.includes("+")) {
        activeString = textToRender.split("+")[0];
    }

    if (!textToRender) {
        if (placeHolder) {
            response = noTranslatePH ? placeHolder : t(placeHolder);
        } else {
            response = t(name);
        }
    } else {
        data.forEach((element) => {
            if (element?.value === activeString) {
                response = noTranslateAV ? element.text : t(element.text);
            }
        });
    }
    return response;
};

export default useSelect;
