import { useEffect } from "react";
import { useMutation, useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useModal } from "components/Modal";

export const useNewPassCode = ({ refetch, setValidation }) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const CHANGE_PASSCODE = gql`
        mutation {
            createAccessCode {
                error
                ok
                code
            }
        }
    `;
    const GET_ACCESS_PROFILE = gql`
        query {
            wifiAccessProfiles {
                results {
                    id
                    name
                    downloadbwmax
                    downloadbwmin
                    uploadbwmin
                    uploadbwmax
                }
            }
        }
    `;
    const CREATE_PASSCODE = gql`
        mutation createWifiAccessCode(
            $code: String!
            $accessprofileId: Int64!
            $maxusers: Int
            $durationHours: Int64
            $durationDays: Int64
            $renovationDays: Int64
            $renovationHours: Int64
        ) {
            createWifiAccessCode(
                code: $code
                accessprofileId: $accessprofileId
                maxusers: $maxusers
                durationHours: $durationHours
                durationDays: $durationDays
                renovationDays: $renovationDays
                renovationHours: $renovationHours
            ) {
                error
                ok
                __typename
            }
        }
    `;

    const [executeMutation, { data: dataPasscode }] = useMutation(CHANGE_PASSCODE, {
        onError(err) {
            console.log(err);
        },
    });

    const [executeQuery, { data: dataAccessProfiles, loading }] = useLazyQuery(GET_ACCESS_PROFILE, {
        onError(err) {
            console.log(err);
        },
        fetchPolicy: "network-only",
    });

    const [executeCreatePasscode] = useMutation(CREATE_PASSCODE, {
        onError(err) {
            console.log(err);
        },
    });

    useEffect(() => {
        executeMutation();
        executeQuery();
    }, []);

    const refreshPasscode = () => {
        executeMutation();
    };

    const createPasscode = (values) => {
        executeCreatePasscode({
            variables: {
                code: values?.code,
                accessprofileId: Number(values?.accessProfileId),
                maxusers: values?.limitOfDevicesValue && Number(values?.limitOfDevicesValue),
                durationHours: values?.durationHours && Number(values?.durationHours),
                durationDays: values?.durationDays && Number(values?.durationDays),
                renovationDays: values?.renovationDays && Number(values["days-duration"]),
                renovationHours: values?.renovationHours && Number(values?.renovationHours),
            },
        }).then((response) => {
            if (response?.data?.createWifiAccessCode?.ok) {
                refetch();
                toast.success(t("operation-successful"));
                close();
            } else if (response?.data?.createWifiAccessCode.error) {
                toast.error(t(response?.data?.createWifiAccessCode.error));
                setValidation((prev) => ({ ...prev, code: true }));
            }
        });
    };

    return {
        dataPasscode: dataPasscode?.createAccessCode?.code,
        refreshPasscode,
        loading,
        dataAccessProfiles: dataAccessProfiles?.wifiAccessProfiles?.results,
        createPasscode,
    };
};
