import React, { useState, useEffect } from "react";

import { Component, ListComponents, Header } from "sections/playground";

import Modal, { useModal, ModalSize } from "components/Modal";
import Button from "components/Button";
import SelectorItem from "components/SelectorItem";
import IconsPicker from "components/IconsPicker";
import Icon from "components/Icon";

const Icons = () => {
    const { open } = useModal();

    const [selectorValue, setSelectorValue] = useState(3);
    const [selectedIcon, setSelectedIcon] = useState({ type: "fas", icon: "atom" });
    const [managerIcons, setManagerIcons] = useState([]);

    useEffect(() => {
        const svgFile = require("assets/fonts/icomoon/icomoon.svg");
        if (svgFile) {
            fetch(svgFile)
                .then((response) => response.text())
                .then((data) => {
                    const icons = [
                        ...(parseGlyphNames(data) || []),
                        // customTypes
                        ...[
                            "error",
                            "sort",
                            "asc",
                            "desc",
                            "true",
                            "false",
                            "close",
                            "chain",
                            "alert-orange",
                            "alert-red",
                            "critical",
                            "blue-warning",
                        ],
                    ];
                    icons.sort((a, b) => a.localeCompare(b));
                    setManagerIcons([...new Set(icons)]);
                })
                .catch((error) => console.error("Error fetching SVG:", error));
        }
    }, []);

    return (
        <>
            <Header title="Icons" />
            <ListComponents>
                <IconsPicker
                    id="icon-picker"
                    selected={selectedIcon}
                    onSuccess={(type, icon) => {
                        console.log("Selected icon", type, icon);
                        setSelectedIcon({ type, icon });
                    }}
                />
                <Component title="SelectorItem">
                    <div>
                        <div className="font-bold mb-2 text-lg">Editable example</div>
                        <SelectorItem
                            type="satisfaction"
                            id="pending-item-test-1"
                            value={selectorValue}
                            onChange={setSelectorValue}
                        />
                        <p className="pt-2">Selected value: {selectorValue}</p>
                        <Button design="classic-link" id="reset-test" onClick={() => setSelectorValue(3)}>
                            Reset value
                        </Button>
                    </div>
                    <div>
                        <div className="font-bold mb-2 text-lg">Read only example</div>
                        <SelectorItem type="satisfaction" id="pending-item-test-2" value={3} readOnly={true} />
                    </div>
                    <div>
                        <div className="font-bold mb-2 text-lg">Disabled example</div>
                        <SelectorItem type="satisfaction" id="pending-item-test-3" value={2} disabled={true} />
                    </div>
                </Component>
                {managerIcons?.length ? (
                    <Component title="Manager icons list">
                        <IconsPicker
                            id="manager-icons"
                            icons={managerIcons?.map((icon) => ({ prefix: "icon", name: icon }))}
                            size="4xl"
                            scroll={false}
                            onSuccess={(type, icon) => {
                                open(
                                    <Modal size={ModalSize.SMALL} title={icon} className="text-center">
                                        <Icon size="10xl" type={icon} />
                                    </Modal>
                                );
                            }}
                        />
                    </Component>
                ) : null}
            </ListComponents>
        </>
    );
};

const parseGlyphNames = (svg) => {
    const regex = /glyph-name="([^"]*)"/g;
    let matches;
    const names = [];
    while ((matches = regex.exec(svg)) !== null) {
        names.push(matches[1]);
    }
    return names;
};

export default Icons;
