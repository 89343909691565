import React, { useEffect, useState, isValidElement } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import Icon from "components/Icon";

/**
 * This component is used to show an error message to the user.
 * @component
 * @param {string} children - Error message
 * @param {boolean} useDefault - Show default message to the user instead of the details of the error (default true)
 * @param {function} retry - Function to retry the action
 * @param {string} className - Additional classes
 * @returns {JSX.Element} - Error component
 * @example
 * <ErrorInfo useDefault={false}>Error when test this example</ErrorInfo>
 */
const ErrorInfo = ({ children, error, retry, className, useDefault, customMessage }) => {
    const { t } = useTranslation();
    const id = `err-${uuid()}`;
    const defaultErrorMessage = customMessage || t("error-mutation");

    const [clicksCount, setClicksCount] = useState(0);

    const message = ((data) => {
        if (typeof data === "string") {
            return data;
        }
        if (data instanceof Error) {
            return data?.message;
        }
        if (isValidElement(data)) {
            return data;
        }
        if (typeof data === "object") {
            return <pre className="whitespace-pre-wrap p-2 text-left font-normal">{JSON.stringify(data, null, 2)}</pre>;
        }
        console.warn("Error children is not valid", { data });
        return null;
    })(children || error);

    useEffect(() => {
        if (!isValidElement(children) && typeof children !== "string") {
            if (children instanceof Error) {
                console.warn("ErrorInfo", children?.message);
            } else {
                console.warn("Error children is not valid", { children });
            }
        }
    }, [children]);

    return (
        <div
            className={classNames({
                "bg-orange-200 rounded text-red-600 font-bold text-center p-5": true,
                [className]: className,
            })}
            id={id}
            onClick={() => {
                setClicksCount(clicksCount + 1);
            }}
        >
            {useDefault === false && message ? (
                message
            ) : (
                <>
                    <div className="flex items-center">
                        <Icon type="error" size="xl" className="mr-2" /> {defaultErrorMessage}
                    </div>
                    {clicksCount > 4 ? (
                        <div className="text-left p-2 border break-all border-red-200 bg-white font-normal text-red-400 my-5">
                            {message || "empty error"}
                        </div>
                    ) : null}
                </>
            )}

            {retry ? (
                <button id="pms-config-retry" className="btn-red cursor-pointer mx-auto my-5" onClick={retry}>
                    {t("Retry again")}
                </button>
            ) : null}
        </div>
    );
};
ErrorInfo.displayName = "ErrorInfo";

export default ErrorInfo;
