import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import UseTitle from "./useTitle";
import UseLoading from "../components/Table/useLoading";

import Loading from "components/Loading";
import Warning from "components/Warning";

//Actions
import { closeModal, setModalContent } from "../actions/uiActions";
import { cleanAction, cleanActionValues } from "../actions/globalActions";
import { setTableResults, setReload } from "../actions/tableActions";
//Utils
import ModalContent from "./Modal/ModalContent";
import ModalContentSchedule from "./Schedule/Modals/ModalContentSchedule";
import { setExecuteQuery } from "../actions/sectionActions";

const UseModal = () => {
    //Store data
    const { sectionName } = useSelector((state) => state.sectionContent);
    const modalOpen = useSelector((state) => state.ui.modal);
    const { loadingContent } = useSelector((state) => state.ui);
    const {
        title,
        titleSufix,
        noCapitalizeTitle,
        titlePrefix,
        bodyAdjust,
        dontClose,
        buttons,
        noTranslateTitle,
        onBack,
    } = useSelector((state) => state.ui.modalContent);

    //States
    const [modalVisible, setModalVisible] = useState("");
    const [fullTitle, setFullTitle] = useState("");

    //Actions
    const dispatch = useDispatch();

    const hideModal = () => dispatch(closeModal());

    const getFullTitle = () => {
        let res = [];
        if (titlePrefix) {
            res.push(titlePrefix);
        }

        if (Array.isArray(title)) {
            title.forEach((item) => res.push(item));
        } else {
            res.push(title);
        }

        if (titleSufix) {
            res.push(titleSufix);
        }
        return res;
    };

    //Clear Action if closed
    const handleClick = (e) => {
        e.stopPropagation();
        if (!dontClose && modalOpen) {
            if (buttons?.some((btn) => btn.action === "close&reload")) {
                dispatch(setModalContent(false));
                dispatch(cleanAction());
                dispatch(setTableResults([]));
                dispatch(setReload(true));
                hideModal();
            } else if (buttons?.some((btn) => btn.action === "closeResetExecute")) {
                hideModal();
                dispatch(cleanAction());
                dispatch(setModalContent(false));
                dispatch(setExecuteQuery(null));
            } else if (buttons?.some((btn) => btn.action === "close&KeepActions")) {
                hideModal();
                dispatch(cleanActionValues());
                dispatch(setModalContent(false));
            } else {
                hideModal();
                dispatch(setModalContent(false));
                dispatch(cleanAction());
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (modalOpen) {
            setModalVisible("block");
            setFullTitle(getFullTitle());

            setTimeout(function () {
                const item = document.querySelector(`#modal_focus`);
                if (item) {
                    item.focus();
                }
            }, 150);
        } else {
            setModalVisible("hidden");
        }
        // eslint-disable-next-line
    }, [modalOpen, title]);

    return (
        <div
            className={`fixed w-full h-full top-0 left-0 items-center flex flex-wrap  justify-center overflow-y-scroll ${modalVisible}`}
            style={{ zIndex: 991 }}
        >
            <div id="modal-background" className="modal-background" onClick={handleClick}></div>
            <div className={`modal-body ${bodyAdjust?.includes("m-0") ? "" : "mt-4 mb-4"} ${bodyAdjust} relative`}>
                <Warning id="modal-warn-dev" title="Refactor needs" design="refactor" className="mx-2">
                    Please, use new Modal component
                </Warning>
                {loadingContent ? <Loading adjust="full" /> : null}
                <div className="py-10 px-12">
                    {onBack ? (
                        <button
                            id="modal-back"
                            onClick={onBack}
                            className="icon text-xl icon-chevron-left absolute -ml-8 py-2 text-gray-800"
                        />
                    ) : null}
                    {fullTitle ? (
                        <UseTitle
                            text={fullTitle}
                            forModal={true}
                            adjust="pb-3"
                            noCapitalize={noCapitalizeTitle}
                            noTranslate={noTranslateTitle}
                        />
                    ) : null}
                    <div className={loadingContent ? "invisible" : null}>
                        {sectionName === "area-schedules" ? <ModalContentSchedule /> : <ModalContent />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseModal;
