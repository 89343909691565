import { newRoute, newRedirect } from "routes/utils";

import { MonitorDevices } from "components/Section/Hotel/Monitor/MonitorDevices";
import InstallationMap from "components/Section/Hotel/InstallationMap";
import Rooms from "components/Section/Hotel/Rooms/Rooms";
import RoomDetail from "components/Section/Hotel/Rooms/RoomDetail";
import RoomPreview from "components/Section/Hotel/Rooms/RoomPreview";
import TVPreview from "components/Section/Hotel/TVPreview";
import RoomGroups from "components/Section/Hotel/Rooms/RoomsGroups";
import ServicesConfig from "components/Section/Hotel/ServicesConfig";
import Topology from "components/Section/Hotel/Settings/Topology";
import { EditRoom } from "components/Section/Hotel/Settings/EditRoom.jsx";
import { AddRoom } from "components/Section/Hotel/Settings/AddRooms.jsx";
import { AddFloor } from "components/Section/Hotel/Settings/AddFloor.jsx";
import { AddWing } from "components/Section/Hotel/Settings/AddWing.jsx";
import { TVSettings } from "components/Section/Hotel/TVSettings.jsx";
import CastSettings from "components/Section/Hotel/CastSettings";
import WifiSettings from "components/Section/Hotel/WifiSettings";
import PortalManagerWifi from "components/Section/Hotel/PortalManagerWifi";
import MobileSettings from "components/Section/Hotel/MobileSettings";
import AccessProfiles from "components/Section/Hotel/AccessProfles";
import AccessTypes from "components/Section/Hotel/AccessTypes/AccessTypes";
import InputGroups from "components/Section/Hotel/Settings/InputGroups";
import { NetworkWarning } from "components/Section/Hotel/NetworkWarning";
import { AddCommonZone } from "components/Section/Hotel/Settings/AddCommonZone";
import { EditCommonZone } from "components/Section/Hotel/Settings/EditCommonZone";
import CommonZoneDetail from "components/Section/Hotel/CommonZones/CommonZoneDetail";
import { CommonZonesList } from "components/Section/Hotel/CommonZones/CommonZonesList";
import ZoneGroups from "sections/hotel/zones/groups";
import { Monitoring } from "components/Section/Hotel/Monitor/Monitoring";
import { NetworkMonitor } from "components/Section/Hotel/Monitor/NetworkMonitor";

const sectionID = "hotel";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkHotel = ({ permissions, isCorporate }) => !isCorporate && permissions?.hotel;
const checkMonitoring = ({ permissions }) => permissions?.hotel?.monitoring;
const checkNetworkMonitor = ({ permissions }) => permissions?.hotel?.networkMonitor;
const checkMap = ({ permissions }) => permissions?.hotel?.map;
const checkRooms = ({ permissions }) => permissions?.hotel?.roomList;
const checkCommonZones = ({ permissions }) => permissions?.hotel?.roomList;
const checkRoomGroups = ({ permissions }) => permissions?.hotel?.roomGroups;
const checkCommonZonesGroups = ({ permissions }) => permissions?.hotel?.roomGroups;
const checkServices = ({ permissions }) => permissions?.hotel?.dd;
const checkTVSettings = ({ permissions }) => permissions?.hotel?.TVSettings || permissions?.hotel?.tvInputs;
const checkNetworkWarnings = ({ permissions, isDhcp }) => isDhcp?.isSection && permissions?.hotel?.networkAlerts;
const checkWifi = ({ permissions, isCorporate }) =>
    !isCorporate && (permissions?.hotel?.wifiSettings || permissions?.hotel?.wifiConfig);
const checkTopology = ({ permissions }) => permissions?.hotel?.topology;
const checkMobileSettings = ({ permissions }) => permissions?.hotel?.mobileConfig;
const checkCast = ({ permissions }) => permissions?.hotel?.castConfig;
const checkTVInputs = ({ permissions }) => permissions?.hotel?.tvInputs;

const routes = [
    route("/", {
        initial: true,
        redirect: redirect("/installation"),
        check: checkHotel,
    }),

    // Installation
    route("/installation", { redirect: redirect("/installation/map") }),
    // Map
    route("/installation/map", { section: InstallationMap, check: checkMap }),
    // Monitoring
    route("/installation/monitor", { redirect: redirect("/installation/devices") }),
    route("/installation/devices", { section: Monitoring, check: checkMonitoring }),
    route("/installation/devices/property-monitor", { section: MonitorDevices, check: checkNetworkMonitor }),
    route("/installation/devices/network-monitor", { section: NetworkMonitor, check: checkNetworkMonitor }),

    // Network alerts
    route("/installation/network-warning", { section: NetworkWarning, check: checkNetworkWarnings }),

    // Rooms
    route("/rooms/", { redirect: redirect("/rooms/list") }),
    route("/rooms/list", { section: Rooms, check: checkRooms }),
    route("/rooms/room/detail/:id", { section: RoomDetail, check: checkRooms }),
    route("/rooms/room/preview/tv/:roomtvid/:ref", { section: TVPreview, check: checkRooms, fullscreen: true }),
    route("/rooms/room/preview/:roomtvid", { section: RoomPreview, check: checkRooms, fullscreen: true }),
    route("/rooms/room/preview/:roomtvid/:lang/:guest", { section: RoomPreview, check: checkRooms, fullscreen: true }),
    route("/rooms/roomgroups", { section: RoomGroups, check: checkRoomGroups }),

    // Zones
    route("/zones/", { redirect: redirect("/zones/list") }),
    route("/zones/list", { section: CommonZonesList, check: checkRooms }),
    route("/zones/zone/detail/:id", { section: CommonZoneDetail, check: checkCommonZones }),
    route("/zones/zone/preview/tv/:roomtvid/:ref", { section: TVPreview, check: checkCommonZones, fullscreen: true }),
    route("/zones/groups", { section: ZoneGroups, check: checkCommonZonesGroups }),

    // Service configuration
    route("/services", { section: ServicesConfig, check: checkServices }),

    // Property settings
    route("/property-settings", { redirect: redirect("/property-settings/wifi") }),
    // Cast
    route("/property-settings/cast", { section: CastSettings, check: checkCast }),
    // WiFi
    route("/property-settings/wifi", { section: WifiSettings, check: checkWifi }),
    route("/property-settings/wifi/access-type", { section: AccessTypes, check: checkWifi }),
    route("/property-settings/wifi/manager", { section: PortalManagerWifi, check: checkWifi }),
    route("/property-settings/wifi/access-profile", { section: AccessProfiles, check: checkWifi }),
    // TV
    route("/property-settings/tv", { redirect: redirect("/property-settings/tv-settings") }),
    route("/property-settings/tv-settings", { section: TVSettings, check: checkTVSettings }),
    route("/property-settings/tv/inputs", { section: InputGroups, check: checkTVInputs }),
    // Mobile
    route("/property-settings/mobile-settings", { section: MobileSettings, check: checkMobileSettings }),
    // Topology
    route("/property-settings/topology", { section: Topology, check: checkTopology }),
    route("/property-settings/topology/edit-room/:id", { section: EditRoom, check: checkTopology }),
    route("/property-settings/topology/add-room/:id", { section: AddRoom, check: checkTopology }),
    route("/property-settings/topology/add-common-zone/:id", { section: AddCommonZone, check: checkTopology }),
    route("/property-settings/topology/edit-common-zone/:id", { section: EditCommonZone, check: checkTopology }),
    route("/property-settings/topology/add-floor/:id", { section: AddFloor, check: checkTopology }),
    route("/property-settings/topology/add-wing", { section: AddWing, check: checkTopology }),
];

export default routes;
