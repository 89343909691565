import React from "react";
import classNames from "classnames";

import { Context as EnvironmentContext } from "contexts/Global/env";

const UseComponentLoading = ({ adjust }) => {
    return (
        <>
            <div className={`z-500 t-0 l-0 w-full h-full flex m-auto ${adjust ? adjust : ""}`}>
                <div
                    className={classNames({
                        "lds-roller m-auto": true,
                        "refactor-loading": EnvironmentContext.development,
                    })}
                >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    );
};

export default UseComponentLoading;
