import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { withDeprecationWarning } from "dev";
import { Context as EnvironmentContext } from "contexts/Global/env";

const UseLoading = withDeprecationWarning({ name: "UseLoading", alt: "Loading" }, ({ adjust }) => {
    //Store data
    const { error } = useSelector((state) => state.table);

    return (
        <>
            {!error || error === "" ? (
                <div className={`t-loading ${adjust}`}>
                    <div
                        className={classNames({
                            "lds-roller": true,
                            "refactor-loading": EnvironmentContext.development,
                        })}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : null}
        </>
    );
});

export default UseLoading;
