import React, { useEffect, useState } from "react";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { COLUMNS_MAPS, HEADERS, MAP_TYPE } from "./constants";
import { useLoading } from "components/Loading";
import { useDeleteMap, useListMaps, useUpdateMap } from "./graphql/useMaps";
import { useModalAddMap } from "./modals/useModalAddMap";
import { useModalEditName } from "./modals/useModalEditName";
import { useModalDelete } from "./modals/useModalDelete";
import { useModalLinkedMaps } from "./modals/useModalLinkedMaps";
import { useAuth } from "hooks/Session/auth";
import { getParseMapName } from "./utils";
import { useGetDesignLocalAssigned } from "../Advertising/modals/modalDestination/Destination/Hooks/useScreenLinkAlert";

const Maps = () => {
    const { t } = useTranslation();
    const [customSearch, setCustomSearch] = useState("");
    const [customSort, setCustomSort] = useState({ field: "id", criteria: "desc" });
    const { setLoading } = useLoading();
    const { getMaps: fetchMaps, maps } = useListMaps({
        onCompleted: () => {
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
            setCustomSort({ field: "id", criteria: "desc" });
        },
    });

    const { languages } = useAuth();
    const defaultLanguage = languages?.find((l) => l.isDefault)?.languageRef;
    const { open: openModalAddMap } = useModalAddMap();
    const { open: openModalEditNameMap } = useModalEditName();
    const { open: openModalDeleteMap } = useModalDelete();
    const { open: openModalLinkedMaps } = useModalLinkedMaps();
    const navigate = useNavigate();

    const getMaps = () => {
        setLoading(true);
        fetchMaps({ variables: { search: customSearch, orderBy: customSort } });
    };

    useEffect(() => {
        getMaps();
    }, []);

    useEffect(() => {
        getMaps();
    }, [customSort, customSearch]);

    return (
        <>
            <Table
                id={"maps-table"}
                cols={COLUMNS_MAPS}
                search={true}
                paginate={true}
                showCount={true}
                header={HEADERS({ t })}
                perPage={8}
                className={"main-container"}
                topRightCorner={
                    <Button
                        design={"blue"}
                        id={"add-map-button"}
                        onClick={() => {
                            openModalAddMap({ open: openModalAddMap });
                        }}
                    >
                        {t("add-map")}
                    </Button>
                }
                rows={maps.map(
                    ({ id, name, updatedAt, createdAt, linkedMapsNum, linkedMapsInfo, navigationAlert }, index) => {
                        const updatedDate = new window.ZDate(updatedAt || createdAt).toLocaleString(null, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        });
                        const hourAndMinute = new window.ZDate(updatedAt || createdAt).toLocaleString(null, {
                            hour: "2-digit",
                            minute: "2-digit",
                        });

                        const mapName = getParseMapName(name, defaultLanguage);

                        return {
                            id,
                            name: (
                                <Button
                                    design={"link"}
                                    id={`button-maps-name-${index}`}
                                    onClick={() => {
                                        navigate(`/design/maps/${id}`);
                                    }}
                                >
                                    {mapName}
                                    {navigationAlert}
                                </Button>
                            ),
                            updated_at: `${updatedDate} ${hourAndMinute}`,
                            linked_maps_num:
                                linkedMapsNum > 0 ? (
                                    <Button
                                        design={"link"}
                                        id={`button-maps-linked-${index}`}
                                        onClick={() => {
                                            openModalLinkedMaps({ id, name: mapName, linkedMaps: linkedMapsInfo });
                                        }}
                                    >
                                        {t("mapcount", { count: linkedMapsNum })}
                                    </Button>
                                ) : (
                                    t("mapcount", { count: linkedMapsNum })
                                ),
                            rowConfig: {
                                actions: [
                                    {
                                        id: "edit-name",
                                        label: t("edit-name"),
                                        onClick: () => {
                                            openModalEditNameMap({
                                                name: mapName,
                                                id,
                                                fetch: getMaps,
                                                names: name,
                                                useUpdate: useUpdateMap,
                                            });
                                        },
                                    },
                                    {
                                        id: "edit-map",
                                        label: t("edit-map"),
                                        onClick: () => {
                                            navigate(`/design/maps/${id}`);
                                        },
                                    },
                                    {
                                        id: "delete-map",
                                        label: t("delete"),
                                        disabled: linkedMapsNum > 0,
                                        tooltip: linkedMapsNum > 0 ? t("linked-map") : null,
                                        onClick: () => {
                                            openModalDeleteMap({
                                                name: mapName,
                                                id,
                                                fetch: getMaps,
                                                useDelete: useDeleteMap,
                                                type: MAP_TYPE,
                                            });
                                        },
                                    },
                                ],
                            },
                        };
                    }
                )}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
                customSort={{
                    value: customSort,
                    onChange: (sort) => {
                        if (!sort) {
                            setCustomSort({ field: "id", criteria: "desc" });
                        } else {
                            setCustomSort(sort);
                        }
                    },
                }}
            ></Table>
        </>
    );
};

export default Maps;
