import { gql } from "apollo-boost";

export const GET_BANNERS = gql`
    query listBanners(
        $scheduleFrom: String
        $scheduleTo: String
        $search: String
        $orderBy: OrderBy
        $id: Int64
        $available: [Boolean]
        $onlyOnGoing: Boolean
        $onlyExpired: Boolean
        $currentTime: DateTime
    ) {
        banners(
            filter: {
                search: $search
                scheduleFrom: $scheduleFrom
                scheduleTo: $scheduleTo
                id: $id
                available: $available
                onlyOngoing: $onlyOnGoing
                onlyExpired: $onlyExpired
            }
            orderBy: $orderBy
            currentDateTime: $currentTime
        ) {
            results {
                id
                available
                name
                contentAction
                contentImage
                destinationID
                destinationInfo {
                    all
                    roomsTpGuestRoom {
                        id
                        name
                    }
                    roomsTpCommonZone {
                        id
                        name
                    }
                    roomGroupsTpCommonZone {
                        id
                        name
                    }
                    roomTvsTpCommonZone {
                        id
                        name
                    }
                    countries {
                        name
                        ref
                    }
                    floors {
                        id
                        name
                    }
                    languages {
                        name
                        ref
                    }
                    roomGroupsTpGuestRoom {
                        id
                        name
                    }
                    roomTvsTpGuestRoom {
                        id
                        name
                    }
                    wings {
                        id
                        name
                    }
                    stayGuests {
                        id
                        name
                    }
                    stayGroups {
                        id
                        name
                    }
                }
                displayPosition
                displaySize
                scheduleDaysOfWeek
                scheduleDaysType
                scheduleDuration
                scheduleStart
                scheduleEnd
                scheduleFrequencyDisplay
                scheduleFrequencyLimited
                scheduleObtrusiveMode
                ongoing
                filtersNum
                timeRanges {
                    endTime
                    startTime
                }
            }
        }
    }
`;
