import React from "react";
export const SIGNAGE_CONTENT_TYPES = ["ASSET", "VIDEO", "SCREEN"];

export const GUESTROOM = "GUESTROOM";
export const COMMONZONE = "COMMONZONE";

export const GROUP_CZ_TYPE = "roomGroupsTpCommonZone";
export const CZ_TYPE = "roomsTpCommonZone";
export const TV_CZ_LOCATION_TYPE = "roomTvsTpCommonZone";

export const ROOM_TYPE = "roomsTpGuestRoom";
export const ROOM_GROUP_TYPE = "roomGroupsTpGuestRoom";
export const TV_ROOM_TYPE = "roomTvsTpGuestRoom";

export const WING_TYPE = "wings";
export const FLOOR_TYPE = "floors";

export const STAY_GUEST_TYPE = "stayGuests";
export const STAY_GUEST_GROUP_TYPE = "stayGroups";

export const LANGUAGES_TYPE = "languages";
export const COUNTRIES_TYPE = "countries";

export const SIGNAGE_TYPE = "sequence";
export const TICKER_TAPE_TYPE = "ticker-tape";
export const BANNER_TYPE = "banner";

export const FILTER_VALUES = {
    AVAILABLE: "available",
    UNAVAILABLE: "unavailable",
    ONLY_EXPIRED: "onlyExpired",
    ONLY_ON_GOING: "onlyOnGoing",
};
export const INITIAL_BATCH_ACTIONS = ({ t }) => {
    return {
        duplicate: { label: t("duplicate"), value: "duplicate", disabled: false, id: "duplicate-batch-id" },
        available: {
            label: t("set-as-available"),
            value: "set-as-available",
            disabled: false,
            id: "set-as-available-batch-id",
        },
        unavailable: {
            label: t("set-as-unavailable"),
            value: "set-as-unavailable",
            disabled: false,
            id: "set-as-unavailable-batch-id",
        },
        delete: { label: t("delete"), value: "delete", disabled: false, id: "delete-batch-id" },
    };
};
export const HEADERS = ({ t }) => {
    return {
        name: { title: t("name"), sortable: true },
        schedule_end: { title: t("scheduled-date"), sortable: true },
        filters_num: { title: t("filters"), sortable: true },
        available: { title: t("status"), sortable: true },
    };
};
export const COLUMNS_SIGNAGE = ["name", "schedule_end", "filters_num", "available", "default"];
export const COLUMNS_TICKER_TAPES = ["name", "schedule_end", "filters_num", "available"];
export const stateFilterOptions = ({ t }) => {
    return [
        {
            id: "color-filter-available-ongoing-option-id",
            value: FILTER_VALUES.ONLY_ON_GOING,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("on-going")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-available-option-id",
            value: FILTER_VALUES.AVAILABLE,
            label: t("available"),
        },
        {
            id: "color-filter-available-expired-option-id",
            value: FILTER_VALUES.ONLY_EXPIRED,
            label: (
                <span>
                    {t("available")}
                    <span className="ml-1 text-gray-500">({t("expired")})</span>
                </span>
            ),
        },
        {
            id: "color-filter-unavailable-option-id",
            value: FILTER_VALUES.UNAVAILABLE,
            label: t("unavailable"),
        },
    ];
};

export const SPEEDS = {
    SLOW: { label: "slow", duration: "17s" },
    MEDIUM: { label: "medium", duration: "13s" },
    FAST: { label: "fast", duration: "10s" },
};

export const SIZES = {
    SMALL: { label: "small", className: "text-base" },
    MEDIUM: { label: "medium", className: "text-xl" },
    LARGE: { label: "large", className: "text-3xl" },
};

export const FILTERS = (t) => [
    {
        label: t("by-room"),
        options: [
            { value: "roomTvsTpGuestRoom", label: t("tv-locations-room"), disabled: false },
            { value: "roomsTpGuestRoom", label: t("room-number"), disabled: false },
            { value: "roomGroupsTpGuestRoom", label: t("room-group"), disabled: false },
            { value: "floors", label: t("floor"), disabled: false },
            { value: "wings", label: t("wing"), disabled: false },
        ],
    },
    {
        label: t("by-guest"),
        options: [
            { value: "stayGuests", label: t("guest"), disabled: false },
            { value: "stayGroups", label: t("guest-group"), disabled: false },
            { value: "languages", label: t("language"), disabled: false },
            { value: "countries", label: t("country"), disabled: false },
        ],
    },
    {
        label: t("by-common-zone"),
        options: [
            { value: "roomsTpCommonZone", label: t("common-zones"), disabled: false },
            { value: "roomGroupsTpCommonZone", label: t("group-common-zones"), disabled: false },
            { value: "roomTvsTpCommonZone", label: `${t("tv-locations-cz")}`, disabled: false },
        ],
    },
];

export const COMMON_ZONE_CHIPS_CLASS = "bg-purple-700 text-white";
export const DEFAULT_CHIPS_CLASS = "bg-blue-200 text-white";

export const keyValuesInFilters = {
    countryRefs: "countries",
    floorIDs: "floors",
    languageRefs: "languages",
    roomGroupTpGuestRoomIDs: "roomGroupsTpGuestRoom",
    roomTpGuestRoomIDs: "roomsTpGuestRoom",
    stayGroupIDs: "stayGroups",
    stayGuestIDs: "stayGuests",
    wingIDs: "wings",
};

export const POSITIONS = {
    TOP_LEFT: { top: "20px", left: "20px" },
    TOP_CENTER: { top: "20px", right: 0, left: 0, margin: "0 auto" },
    TOP_RIGHT: { top: "20px", right: "20px" },
    MIDDLE_LEFT: { left: "20px", top: 0, bottom: 0, margin: "auto 0" },
    MIDDLE_CENTER: { bottom: 0, top: 0, right: 0, left: 0, margin: "auto" },
    MIDDLE_RIGHT: { right: "20px", top: 0, bottom: 0, margin: "auto 0" },
    BOTTOM_LEFT: { bottom: "20px", left: "20px" },
    BOTTOM_CENTER: { bottom: "20px", left: 0, right: 0, margin: "0 auto" },
    BOTTOM_RIGHT: { bottom: "20px", right: "20px" },
};

export const BANNER_SIZES = {
    SMALL: { maxWidth: 0.2792, height: 0.262 },
    MEDIUM: { maxWidth: 0.4745, height: 0.4454 },
    LARGE: { maxWidth: 0.6708, height: 0.6296 },
};

export const ALL_PROPERTY_FILTER = (t) => ({ label: t("all property"), value: "allProperty" });
