import { DEVICE_FUNCTIONS, DEVICE_STATUS, DEVICE_TYPES } from "../../Hotel/Monitor/ListDevicesUtils";
import { DeviceKind } from "constants/devices";

const showStatus = {
    name: "show-status",
    modal_title: "show-status",
    action: "device-show-status",
};

const moveRoom = {
    name: "move-room",
    modal_title: "move-room",
    action: "device-move-room",
};

const editName = {
    name: "edit-name",
    modal_title: "edit-name",
    action: "device-edit-name",
};

const uninstall = {
    name: "uninstall",
    modal_title: "uninstall",
    action: "device-uninstall",
};

const reboot = {
    name: "reboot",
    modal_title: "reboot",
    action: "device-restart",
};

const disable = {
    name: "disable",
    modal_title: "disable",
    action: "device-hide-status",
};

const deauthorise = {
    super_user: true,
    modal_title: "deauthorise",
    name: "deauthorise",
    action: "device-deauthorise",
};

const chromecastUnassign = {
    name: "chromecast-unassign",
    modal_title: "chromecast-unassign",
    action: "device-unassign",
};

const changeDevice = {
    name: "change-device-function",
    modal_title: "change-device-function",
    action: "device-change-device-function",
};

const tvReload = {
    name: "tv-reload",
    modal_title: "tv-reload",
    action: "device-reload-interface",
};

const editTv = {
    name: "edit-tv-inputs",
    modal_title: "edit-tv-inputs",
    action: "device-edit-tv-inputs",
};

const chromecastPairingRemoteControl = {
    name: "chromecast-pairing-remote-control",
    modal_title: "chromecast-pairing-remote-control",
    action: "device-pairing-remote-control",
};

const launchSystemSettingsScreen = {
    super_user: true,
    modal_title: "launch-system-settings-screen",
    name: "launch-system-settings-screen-not-recommended",
    action: "device-launch-system-settings-screen",
};

const authorise = {
    super_user: true,
    name: "authorise",
    modal_title: "authorise",
    action: "device-authorise",
};

const deleteDevice = {
    name: "delete-device",
    modal_title: "delete-device",
    action: "device-delete-device",
};

const tvPowerOff = {
    name: "tv-power-off",
    modal_title: "tv-power-off",
    action: "device-power-off",
};

const tvPowerOn = {
    name: "tv-power-on",
    modal_title: "tv-power-on",
    action: "device-power-on",
};

const debbugModeOff = {
    super_user: true,
    name: "debbugMode-off",
    modal_title: "debbugMode-off",
    action: "debbug-mode-off",
};

const debbugModeOn = {
    super_user: true,
    name: "debbugMode-on",
    modal_title: "debbugMode-on",
    action: "debbug-mode-on",
};

const authoriseAndAssign = {
    super_user: true,
    name: "authorise-and-assign",
    modal_title: "authorise-and-assign",
    action: "device-authorise-and-assign",
};

const assign = {
    name: "assign",
    modal_title: "assign",
    action: "device-assign",
};

export const deviceAction = (res, resultsItem, superUser) => {
    const commonValues = [
        [
            { ...chromecastUnassign },
            { ...moveRoom },
            { ...editName },
            { ...tvReload },
            { ...reboot },
            { ...changeDevice },
            { ...disable },
            { ...chromecastPairingRemoteControl },
            superUser && { ...launchSystemSettingsScreen },
            superUser && { ...deauthorise },
        ],
        [
            { ...chromecastUnassign },
            { ...moveRoom },
            { ...editName },
            { ...changeDevice },
            { ...disable },
            superUser && { ...deauthorise },
        ],
        [
            { ...chromecastUnassign },
            { ...moveRoom },
            { ...editName },
            { ...changeDevice },
            { ...showStatus },
            superUser && { ...deauthorise },
        ],
        [
            { ...chromecastUnassign },
            { ...moveRoom },
            { ...editName },
            { ...changeDevice },
            superUser && { ...deauthorise },
        ],
    ];

    const getDebugActions = () => {
        if (typeof resultsItem.debugMode !== "undefined") {
            return resultsItem.debugMode === true
                ? [{ ...debbugModeOff }]
                : resultsItem.debugMode === false
                ? [{ ...debbugModeOn }]
                : [];
        }
        return [];
    };

    switch (resultsItem.kind) {
        case DeviceKind.STB:
            break;
        case DeviceKind.TV:
            switch (resultsItem.status) {
                case DEVICE_STATUS.online.value:
                case DEVICE_STATUS.lowSignal.value:
                    res.push(
                        { ...tvPowerOff },
                        { ...moveRoom },
                        { ...tvReload },
                        { ...editTv },
                        { ...disable },
                        { ...uninstall },
                        ...getDebugActions()
                    );
                    break;
                case DEVICE_STATUS.standby.value:
                    res.push(
                        { ...tvPowerOn },
                        { ...moveRoom },
                        { ...tvReload },
                        { ...editTv },
                        { ...disable },
                        { ...uninstall },
                        ...getDebugActions()
                    );
                    break;
                case DEVICE_STATUS.offline.value:
                    res.push({ ...moveRoom }, { ...editTv }, { ...disable }, { ...uninstall }, ...getDebugActions());
                    break;
                case DEVICE_STATUS.hiddenStatus.value:
                    res.push({ ...moveRoom }, { ...editTv }, { ...showStatus }, { ...uninstall }, ...getDebugActions());
                    break;
            }
            break;
        case DeviceKind.CHROMECAST:
            const isPhilipsBuiltIn =
                resultsItem.type === DEVICE_TYPES.philips.value &&
                resultsItem.function === DEVICE_FUNCTIONS.tvAndcast.value;
            if ((resultsItem.type === DEVICE_TYPES.googleTV.value || isPhilipsBuiltIn) && resultsItem.castEnabled) {
                switch (resultsItem.function) {
                    case DEVICE_FUNCTIONS.cast.value:
                        switch (resultsItem.status) {
                            case DEVICE_STATUS.online.value:
                            case DEVICE_STATUS.lowSignal.value:
                                res.push(...commonValues[0]);
                                break;
                            case DEVICE_STATUS.offline.value:
                                res.push(...commonValues[1]);
                                break;
                            case DEVICE_STATUS.hiddenStatus.value:
                                res.push(...commonValues[2]);
                                break;
                            case DEVICE_STATUS.unavailable.value:
                                res.push(...commonValues[3], { ...tvReload });
                                break;
                        }
                        break;
                    case DEVICE_FUNCTIONS.tv.value:
                        switch (resultsItem.status) {
                            case DEVICE_STATUS.online.value:
                            case DEVICE_STATUS.lowSignal.value:
                            case DEVICE_STATUS.standby.value:
                                res.push(...commonValues[0], ...getDebugActions());
                                break;
                            case DEVICE_STATUS.offline.value:
                                res.push(...commonValues[1], ...getDebugActions());
                                break;
                            case DEVICE_STATUS.hiddenStatus.value:
                                res.push(...commonValues[2], ...getDebugActions());
                                break;
                            case DEVICE_STATUS.unavailable.value:
                                res.push(...commonValues[3], ...getDebugActions());
                                break;
                        }
                        break;
                    case DEVICE_FUNCTIONS.tvAndcast.value:
                        switch (resultsItem.status) {
                            case DEVICE_STATUS.online.value:
                            case DEVICE_STATUS.lowSignal.value:
                            case DEVICE_STATUS.standby.value:
                                res.push(
                                    isPhilipsBuiltIn &&
                                        resultsItem.status === DEVICE_STATUS.online.value && {
                                            ...tvPowerOff,
                                        },
                                    isPhilipsBuiltIn &&
                                        resultsItem.status === DEVICE_STATUS.standby.value && {
                                            ...tvPowerOn,
                                        },
                                    { ...chromecastUnassign },
                                    { ...moveRoom },
                                    { ...editName },
                                    { ...tvReload },
                                    { ...reboot },
                                    isPhilipsBuiltIn && { ...editTv },
                                    !isPhilipsBuiltIn && { ...changeDevice },
                                    { ...disable },
                                    !isPhilipsBuiltIn && { ...chromecastPairingRemoteControl },
                                    superUser && !isPhilipsBuiltIn && { ...launchSystemSettingsScreen },
                                    superUser && { ...deauthorise },
                                    ...getDebugActions()
                                );
                                break;
                            case DEVICE_STATUS.offline.value:
                                res.push(
                                    { ...chromecastUnassign },
                                    { ...moveRoom },
                                    { ...editName },
                                    isPhilipsBuiltIn && { ...editTv },
                                    !isPhilipsBuiltIn && { ...changeDevice },
                                    { ...disable },
                                    superUser && { ...deauthorise },
                                    ...getDebugActions()
                                );
                                break;
                            case DEVICE_STATUS.hiddenStatus.value:
                                res.push(
                                    { ...chromecastUnassign },
                                    { ...moveRoom },
                                    { ...editName },
                                    isPhilipsBuiltIn && { ...editTv },
                                    !isPhilipsBuiltIn && { ...changeDevice },
                                    { ...showStatus },
                                    superUser && { ...deauthorise },
                                    ...getDebugActions()
                                );
                                break;
                            case DEVICE_STATUS.unavailable.value:
                                res.push(
                                    { ...chromecastUnassign },
                                    { ...moveRoom },
                                    { ...editName },
                                    isPhilipsBuiltIn && { ...editTv },
                                    !isPhilipsBuiltIn && { ...changeDevice },
                                    superUser && { ...deauthorise },
                                    ...getDebugActions()
                                );
                                break;
                        }
                        break;
                    case DEVICE_FUNCTIONS.unspecified.value:
                    case "":
                        switch (resultsItem.status) {
                            case DEVICE_STATUS.unassigned.value:
                                res.push(
                                    { ...assign },
                                    { ...editName },
                                    {
                                        modal_title: "detach-from-property",
                                        name: "detach-from-property",
                                        action: "device-detach-from-property",
                                    },
                                    superUser && { ...deauthorise }
                                );
                                break;
                            case DEVICE_STATUS.unauthorized.value:
                                res.push(superUser && { ...authorise }, superUser && { ...authoriseAndAssign }, {
                                    ...deleteDevice,
                                });
                                break;
                            case DEVICE_STATUS.unavailable.value:
                                res.push(
                                    { ...chromecastUnassign },
                                    { ...moveRoom },
                                    { ...editName },
                                    { ...changeDevice },
                                    superUser && { ...deauthorise }
                                );
                                break;
                        }
                        break;
                }
            } else if (resultsItem.type === DEVICE_TYPES.googleTV.value && !resultsItem.castEnabled) {
                switch (resultsItem.status) {
                    case DEVICE_STATUS.online.value:
                    case DEVICE_STATUS.lowSignal.value:
                    case DEVICE_STATUS.standby.value:
                        res.push(
                            { ...uninstall },
                            { ...moveRoom },
                            { ...editName },
                            { ...tvReload },
                            { ...reboot },
                            { ...disable },
                            { ...chromecastPairingRemoteControl },
                            superUser && { ...launchSystemSettingsScreen },
                            superUser && { ...deauthorise },
                            ...getDebugActions()
                        );
                        break;
                    case DEVICE_STATUS.offline.value:
                        res.push(
                            { ...uninstall },
                            { ...moveRoom },
                            { ...editName },
                            { ...disable },
                            superUser && { ...deauthorise },
                            ...getDebugActions()
                        );
                        break;
                    case DEVICE_STATUS.hiddenStatus.value:
                        res.push(
                            { ...uninstall },
                            { ...moveRoom },
                            { ...editName },
                            { ...showStatus },
                            superUser && { ...deauthorise },
                            ...getDebugActions()
                        );
                        break;
                }
            } else {
                switch (resultsItem.status) {
                    case DEVICE_STATUS.online.value:
                    case DEVICE_STATUS.lowSignal.value:
                    case DEVICE_STATUS.standby.value:
                        res.push(
                            { ...chromecastUnassign },
                            { ...moveRoom },
                            { ...editName },
                            { ...reboot },
                            { ...disable },
                            superUser && {
                                super_user: true,
                                name: "chromecast-factory-reset",
                                modal_title: "chromecast-factory-reset",
                                action: "device-factory-reset",
                            },
                            superUser && { ...deauthorise },
                            ...getDebugActions()
                        );
                        break;
                    case DEVICE_STATUS.offline.value:
                        res.push(
                            { ...chromecastUnassign },
                            { ...moveRoom },
                            { ...editName },
                            { ...disable },
                            superUser && { ...deauthorise }
                        );
                        break;
                    case DEVICE_STATUS.hiddenStatus.value:
                        res.push(
                            { ...chromecastUnassign },
                            { ...moveRoom },
                            { ...editName },
                            {
                                name: "show-status",
                                modal_title: "show-status",
                                action: "device-show-status",
                            },
                            superUser && { ...deauthorise }
                        );
                        break;
                    case DEVICE_STATUS.unavailable.value:
                        res.push(
                            { ...chromecastUnassign },
                            { ...moveRoom },
                            { ...editName },
                            superUser && { ...deauthorise }
                        );
                        break;
                    case DEVICE_STATUS.unassigned.value:
                        res.push(
                            { ...assign },
                            { ...editName },
                            {
                                modal_title: "detach-from-property",
                                name: "detach-from-property",
                                action: "device-detach-from-property",
                            },
                            superUser && { ...deauthorise }
                        );
                        break;
                    case DEVICE_STATUS.unauthorized.value:
                        res.push({ ...authorise }, { ...authoriseAndAssign }, { ...deleteDevice });
                        break;
                }
            }
            break;
    }

    return res;
};
