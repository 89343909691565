import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SortableList from "components/SortableList";
import DraggableListItem from "components/DraggableListItem";
import { useMap } from "../../MapContext";
import { useUpdatePinsInterestPoint, useSetInterestPointOrderInMap } from "../../../graphql/useMaps";
import { useAuth } from "hooks/Session/auth";
import { Session } from "hooks/Utils/Session";
import { useModalDeletePointOfInterest } from "../../../modals/pointsOfInterest/useModalDeletePointOfInterest";
import { useNavigate } from "react-router-dom";
import { DestinationType } from "components/Section/Design/Advertising/modals/modalDestination/Destination/utils/utils";
import Icon from "components/Icon";
import { useScreenLinkAlert } from "components/Section/Design/Advertising/modals/modalDestination/Destination/Hooks/useScreenLinkAlert";

const SideBarInteresPoints = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { defaultLanguage } = useAuth();
    const { dataInterestPoints, setSectionLoading, manageInterestPoints, refetch, refetchCategories } = useMap();
    const { loading: loadingScreenLinkAlert, renderNavigationInterestPointAlert } = useScreenLinkAlert();

    const { handleCalculateInterestPointPosition, interestPoints, originalInterestPoints, setSelectedItemId } =
        manageInterestPoints;
    const { open: openModalDeletePointOfInterest } = useModalDeletePointOfInterest();
    const navigate = useNavigate();

    const [items, setItems] = useState();

    const { setInterestPointOrder } = useSetInterestPointOrderInMap({
        onCompleted: () => {
            setSectionLoading(false);
            refetch();
        },
        onError: () => {
            setSectionLoading(false);
            refetch();
        },
    });

    const { updatePinsInterestPoint } = useUpdatePinsInterestPoint({
        onCompleted: () => {
            setSectionLoading(false);
            refetch();
        },
        onError: () => {
            setSectionLoading(false);
            refetch();
        },
    });

    useEffect(() => {
        if (dataInterestPoints) {
            setItems(normalizeInterestPoints(dataInterestPoints));
        }
    }, [dataInterestPoints, interestPoints, originalInterestPoints, loadingScreenLinkAlert]);

    const normalizeInterestPoints = (data) =>
        data
            .slice()
            .sort((a, b) => a.pos - b.pos)
            .map(({ id: interestPointId, name, pos, pinIcon, navigationAction }) => {
                const normalizedLabel = name.replace(/^"(.*)"$/, "$1");
                const imageSrc = pinIcon?.libraryRef || null;
                const iconData = pinIcon?.icon && pinIcon?.lib ? pinIcon : null;
                const currentPins = originalInterestPoints.find((point) => point.id === interestPointId)?.pins || [];
                const customAction = renderNavigationInterestPointAlert(navigationAction);
                return {
                    id: interestPointId,
                    label: normalizedLabel,
                    addonClassName: "addon-circle",
                    pos,
                    pinIcon: pinIcon || null,
                    imageSrc: imageSrc ? Session.getDasUrl(`${imageSrc}?lang=${defaultLanguage}`) : null,
                    iconData,
                    customAction,
                    actions: [
                        {
                            id: "edit-point-of-interest",
                            label: t("edit-point-of-interest"),
                            onClick: () => handleEditPointOfInterest(interestPointId),
                        },
                        {
                            id: "add-pin",
                            label: t("add-pin"),
                            onClick: () => handleAddPin(interestPointId, currentPins),
                        },
                        {
                            id: "delete-pin",
                            label: t("delete"),
                            onClick: () => handleDeletePin(interestPointId, normalizedLabel),
                        },
                    ],
                };
            });

    const handleSortEnd = ({ updatedItems, movedItem, oldIndex, newIndex }) => {
        setSectionLoading(true);
        setInterestPointOrder({
            variables: { mapID: id, id: movedItem.id, pos: newIndex + 1 },
        });
    };

    const handleEditPointOfInterest = (interestPointId) => {
        navigate(`/design/maps/${id}/${interestPointId}`);
    };

    const handleAddPin = (interestPointId, pins) => {
        const newPin = handleCalculateInterestPointPosition(interestPoints);
        const updatedPins = [...pins, newPin];
        updatePinsInterestPoint({ variables: { mapID: id, id: interestPointId, pins: updatedPins } });
        setSectionLoading(true);
        setSelectedItemId(newPin.id);
        refetch();
    };

    const handleDeletePin = (interestPointId, name) => {
        openModalDeletePointOfInterest({
            refetch: () => {
                refetch();
                refetchCategories();
                setSelectedItemId(null);
            },
            variables: { mapID: id, id: interestPointId },
            name,
        });
    };

    return (
        <div className="h-full w-full">
            {items && items.length === 0 && (
                <div className="flex pt-4 items-start justify-center h-full">
                    <div>{t("there-are-not-points-of-interest")}</div>
                </div>
            )}
            <SortableList
                items={items}
                lock="y"
                lockToContainerEdges
                SortableItemComponent={DraggableListItem}
                onSortEnd={handleSortEnd}
            />
        </div>
    );
};

export default SideBarInteresPoints;
