import React from "react";
import { formatDate } from "./services/formatDate";
import { NetworkHeader } from "./NetworkHeader";
import { NoTrafficData } from "./Traffic";
import { Warning } from "./Warning";
import { useTranslation } from "react-i18next";

export const ShareContent = ({ networkInfo, called, loading }) => {
    const { t } = useTranslation();
    const { lastUpdate, updateTimeMin, areUsers, shareContent } = networkInfo || {};
    const { hasCastingProblems, hasDiscoveryProblems, hasDiscoveryProblemsForIos } = shareContent || {};
    const lastUpdateDate = formatDate(lastUpdate);
    const noUserDataText = areUsers === false && "no-user-data";

    if (loading || !called) return null;

    return (
        <div className="border-l border-gray-300">
            <section className={`p-4`}>
                <NetworkHeader
                    title="share-content-issues"
                    lastUpdateDate={lastUpdateDate}
                    updateTimeMin={updateTimeMin}
                    adjustLastUpdate={"mr-4"}
                />
                <section className="mb-10">
                    {areUsers ? (
                        <>
                            {hasDiscoveryProblemsForIos && (
                                <>
                                    <Warning text={t("ios-problem")} color={"orange"} ml={4} textColor="black" />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">
                                        {t("mdns-packets-being-filtered")}
                                    </span>
                                </>
                            )}
                            {hasCastingProblems && hasDiscoveryProblems ? (
                                <>
                                    <Warning
                                        text={t("pairing-process-not-possible")}
                                        color={"red"}
                                        ml={4}
                                        textColor="black"
                                    />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">
                                        {t("users-connected-dont-have-access-to-server")}
                                    </span>
                                </>
                            ) : hasCastingProblems && !hasDiscoveryProblems ? (
                                <>
                                    <Warning
                                        text={t("sharing-content-not-possible")}
                                        color={"orange"}
                                        ml={4}
                                        textColor="black"
                                    />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">
                                        {t("communication-filtered")}
                                    </span>
                                </>
                            ) : !hasCastingProblems && hasDiscoveryProblems ? (
                                <>
                                    <Warning
                                        text={t("cast-icon-not-be-shown")}
                                        color={"orange"}
                                        ml={4}
                                        textColor="black"
                                    />
                                    <span className="ml-10 mt-2 text-gray-800 w-2/3 block">
                                        {t("gateway-isnt-reaching-user")}
                                    </span>
                                </>
                            ) : !hasCastingProblems && !hasDiscoveryProblems && !hasDiscoveryProblemsForIos ? (
                                <p className="mx-4 text-gray-700">{t("no-issues-detected")}</p>
                            ) : null}
                        </>
                    ) : (
                        <NoTrafficData text={noUserDataText} />
                    )}
                </section>
            </section>
        </div>
    );
};
