import React, { useContext, useEffect, useState } from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

//Utils
import Parser from "hooks/Utils/Parser";
import { useAccessProfile } from "hooks/GraphqlCalls/Hotel/Monitoring/useAccessProfile";
import Table from "components/ZafiroTable";
import Loading from "components/Loading";
import Button from "components/Button";
import { TopologyTextInputs } from "./Settings/TopologyComponents/TopologyTextInputs";
import { TopologyNumberInputs } from "./Settings/TopologyComponents/TopologyNumberInputs";
import Icon from "components/Icon";
import { toast } from "react-toastify";
import { SaveAccessProfile } from "./SaveAccessProfile";
import { AccessTypesContext, AccessTypesProvider } from "contexts/AccessTypes";

const AccessProfilesWithoutContext = () => {
    const [accessProfile, setAccessProfile] = useState([]);
    const {
        creating,
        setCreating,
        editing,
        setEditing,
        setNameValidation,
        setUploadMinBandwidthValidation,
        setDownloadMinBandwidthValidation,
        setUploadMaxBandwidth,
        setDownloadMaxBandwidth,
    } = useContext(AccessTypesContext);
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasGuest = permissions?.product?.guest;
    const { tableResults, loading, notConfigutarionAccessTypeWarning, ready, search, setSearch } = useAccessProfile();

    const renderAccessTypes = (accessTypes) => {
        let response = [];
        if (accessTypes?.length > 0) {
            accessTypes.map((accessType) => {
                if (accessType === "Room + surname" && !hasGuest) {
                    return;
                }
                response.push(`<strong>"${accessType}"</strong>`);
            });
        }
        response = response.join(", ");
        let lastIndex = response.lastIndexOf(", ");
        if (lastIndex > -1) response = response.slice(0, lastIndex) + ` ${t("and")} ` + response.slice(lastIndex + 1);

        return response;
    };

    const cancelEdit = () => {
        setAccessProfile(tableResults);
        setUploadMinBandwidthValidation(false);
        setNameValidation(false);
        setDownloadMinBandwidthValidation(false);
        setUploadMaxBandwidth(false);
        setEditing((prev) => ({ ...prev, editing: false }));
        setCreating(false);
    };

    useEffect(() => {
        if (tableResults && !creating && !editing?.editing) {
            setAccessProfile(tableResults);
        }
    }, [tableResults, creating, editing]);

    useEffect(() => {
        if (editing?.editing) {
            setAccessProfile((prev) => {
                const updatedData = [...prev];
                const index = updatedData.findIndex((item) => item.id === editing.id);
                if (index !== -1) {
                    const updatedAccessType = {
                        name: (
                            <div alt={updatedData[index].nameForEdit}>
                                <TopologyTextInputs
                                    id="edit-access-profile-name"
                                    type="name"
                                    value={updatedData[index].nameForEdit}
                                    section="access-types"
                                />
                            </div>
                        ),
                        bwMin: (
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-1">
                                    <Icon type="maximum" size="2xl" />
                                    <TopologyNumberInputs
                                        id="edit-access-profile-uploadbwmin"
                                        type="min-upload-bandwidth"
                                        value={updatedData[index].uploadbwminForEdit}
                                    />
                                </div>
                                <div className="flex items-center gap-1">
                                    <Icon type="minimum" size="2xl" />
                                    <TopologyNumberInputs
                                        id="edit-access-profile-downloadbwmin"
                                        type="min-download-bandwidth"
                                        value={updatedData[index].downloadbwminForEdit}
                                    />
                                </div>
                            </div>
                        ),
                        bwMax: (
                            <div className="flex flex-col gap-1">
                                <div className="flex items-center gap-1">
                                    <Icon type="maximum" size="2xl" />
                                    <TopologyNumberInputs
                                        id="edit-access-profile-uploadbwmax"
                                        type="max-upload-bandwidth"
                                        value={updatedData[index].uploadbwmaxForEdit}
                                    />
                                </div>
                                <div className="flex items-center gap-1">
                                    <Icon type="minimum" size="2xl" />
                                    <TopologyNumberInputs
                                        id="edit-access-profile-downloadbwmax"
                                        type="max-download-bandwidth"
                                        value={updatedData[index].downloadbwmaxForEdit}
                                    />
                                </div>
                            </div>
                        ),
                        edit: (
                            <Button id="cancel" className="btn-blue-outline font-bold" onClick={cancelEdit}>
                                {t("cancel")}
                            </Button>
                        ),
                        delete: <SaveAccessProfile id={editing?.id} />,
                    };
                    updatedData[index] = updatedAccessType;
                }
                return updatedData;
            });
        }
    }, [editing?.editing]);

    const addProfile = () => {
        if (editing?.editing) {
            return toast.error(t("save-before-continue"));
        }

        let accessType = {
            name: <TopologyTextInputs id="edit-access-profile-name" type="name" section="access-types" />,
            bwMin: (
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                        <Icon type="maximum" size="2xl" />
                        <TopologyNumberInputs id="edit-access-profile-uploadbwmin" type="min-upload-bandwidth" />
                    </div>
                    <div className="flex items-center gap-1">
                        <Icon type="minimum" size="2xl" />
                        <TopologyNumberInputs id="edit-access-profile-downloadbwmin" type="min-download-bandwidth" />
                    </div>
                </div>
            ),
            bwMax: (
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-1">
                        <Icon type="maximum" size="2xl" />
                        <TopologyNumberInputs id="edit-access-profile-uploadbwmax" type="max-upload-bandwidth" />
                    </div>
                    <div className="flex items-center gap-1">
                        <Icon type="minimum" size="2xl" />
                        <TopologyNumberInputs id="edit-access-profile-downloadbwmax" type="max-download-bandwidth" />
                    </div>
                </div>
            ),
            edit: (
                <Button
                    id="cancel"
                    className="btn-blue-outline font-bold"
                    onClick={() => {
                        setCreating(false);
                        setAccessProfile(tableResults);
                        setNameValidation("");
                        setUploadMinBandwidthValidation("");
                        setDownloadMinBandwidthValidation("");
                        setUploadMaxBandwidth("");
                        setDownloadMaxBandwidth("");
                    }}
                >
                    {t("cancel")}
                </Button>
            ),
            delete: <SaveAccessProfile type="addAccessType" />,
        };
        setAccessProfile([accessType, ...tableResults]);
    };

    const Warning = () => (
        <div className="w-full">
            <Icon type="warning" className="mr-1" size="xl" />
            <span>
                {Parser(
                    t("access-profile-no-associated-access-type-warning", {
                        upload: "1,5",
                        download: "5",
                        accessTypes: renderAccessTypes(notConfigutarionAccessTypeWarning?.accessTypes),
                    })
                )}
            </span>
        </div>
    );

    return (
        <>
            <UseSectionHeader title={["manage-access-profiles"]} navToSection="/hotel/property-settings/wifi/manager" />
            <div className="main-container">
                <Table
                    id="access-profiles"
                    warnings={notConfigutarionAccessTypeWarning?.show ? <Warning /> : null}
                    search={true}
                    loading={!ready}
                    showCount={true}
                    header={{
                        name: { title: t("name"), width: "22%" },
                        bwMin: { title: t("min-bandwidth"), width: "22%" },
                        bwMax: { title: t("max-bandwidth"), width: "22%" },
                        accessTypes: { title: t("access-types"), width: "22%" },
                        edit: { width: "6%", align: "center" },
                        delete: { width: "6%", align: "center" },
                    }}
                    topRightCorner={
                        <Button
                            id="add-access-profile"
                            className="btn btn-blue"
                            onClick={() => {
                                setCreating(true);
                                addProfile();
                            }}
                        >
                            {t("add-profile")}
                        </Button>
                    }
                    cols={["name", "bwMin", "bwMax", "accessTypes", "edit", "delete"]}
                    rows={accessProfile}
                />
            </div>
        </>
    );
};

const AccessProfiles = () => {
    return (
        <AccessTypesProvider>
            <AccessProfilesWithoutContext />
        </AccessTypesProvider>
    );
};

export default AccessProfiles;
