import React, { useRef } from "react";
import Modal, { ModalSize, useModal } from "components/Modal";
import FileHandler from "components/FileHandler";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getFileTooltip } from "hooks/Utils/FileUtils";
import ReactTooltip from "components/ReactTooltip";
import useTranscodeVideoModal from "./useTranscodeVideoModal";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { useUploadFileContext } from "contexts/UploadFile/uploadFileContext";

const ModalContent = (props) => {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const { open: openTranscodeVideoModal } = useTranscodeVideoModal();
    const { generateFilesUploading, uploadFilesRequest, handleFilesUpload } = useUploadFileContext();
    const { modalData = {}, closeAllModals, close } = props;
    const { contentTypesFiles = [], multipleFiles = false, onCloseAction } = modalData;
    const { token } = useAuth();
    const folder = modalData?.folder || null;

    const handleOnSuccess = async (filesInfo) => {
        const files = filesInfo.map((fileInfo) => {
            return { file: fileInfo.file };
        });

        closeAllModals();

        try {
            await handleFilesUpload({
                files,
                t,
                generateFilesUploading,
                uploadFilesRequest,
                token,
                folder,
                onComplete: () => {
                    onCloseAction();
                },
            });
        } catch (err) {
            console.error("Error uploading files:", err);
        }
    };

    return (
        <Modal
            // className=" w-3/12 p-10"
            size={ModalSize.SMALL}
            footer={
                <>
                    <Button
                        design="blue-outline"
                        id="modal-add-media-library-cancel"
                        onClick={() => {
                            if (typeof close === "function") {
                                close();
                            }
                            if (typeof onCloseAction === "function") {
                                onCloseAction();
                            }
                        }}
                    >
                        {t("cancel")}
                    </Button>
                </>
            }
        >
            <div className=" pb-32">
                <FileHandler
                    ref={inputRef}
                    id="select-file-aa"
                    options={{
                        contentTypes: contentTypesFiles.map((contentTypeFile) => contentTypeFile.type),
                        multiple: true,
                        dragAndDrop: true,
                    }}
                    onSuccess={(files) => {
                        if (files.some((file) => file.type === "video")) {
                            props.close();
                            const filesInfo = files.filter((file) => file.type === "video").map((file) => file.file);
                            openTranscodeVideoModal({
                                modalData: { filesInfo, onCloseAction: props?.modalData?.onCloseAction },
                            });
                        } else {
                            handleOnSuccess(files);
                        }
                    }}
                    onError={(error) => {
                        toast.error(error);
                    }}
                >
                    <div className="border border-dashed border-gray-500">
                        <div className="text-center p-10 ">
                            <h4 className="font-bold mb-2">
                                {t(multipleFiles ? "drop-files-to-upload" : "drop-file-to-upload")}
                            </h4>
                            <p className="mb-2">{t("or")}</p>
                            <p
                                className="btn-blue w-6/12 m-auto mb-2"
                                id="select-file-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (inputRef.current) {
                                        inputRef.current.click();
                                    }
                                }}
                            >
                                {t(multipleFiles ? "select-files" : "select-file")}
                            </p>
                            <div className="text-xs">
                                {t("maximum-upload-file-size")}
                                <span
                                    className={`icon-info text-lg align-middle m-2 relative z-100`}
                                    data-tip={`${contentTypesFiles.reduce((acc, current) => {
                                        acc += getFileTooltip(current, t);
                                        return acc;
                                    }, "")}`}
                                    data-html={true}
                                ></span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    multiline={true}
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        </div>
                    </div>
                </FileHandler>
            </div>
        </Modal>
    );
};

const useAddMediaLibrary = () => {
    const { open, close, closeAllModals } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close, closeAllModals };
            open(<ModalContent {...newProps} />);
        },
    };
};

export default useAddMediaLibrary;
