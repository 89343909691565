import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useAuth } from "hooks/Session/auth";
import { getOrigin } from "hooks/Utils/Customers/Customers";
import { Session } from "hooks/Utils/Session";
import { TAG_CHECK_IN } from "components/Tag";
import { loginType } from "hooks/Data/useCustomer";

export const ButtonExportCustomers = ({ children, data, mobileApps, projects }) => {
    const { t } = useTranslation();
    const { isCorporate } = useAuth();

    return (
        <Button
            id="exportLink"
            action="csv"
            actionData={{
                filename: `${Session.getProject()?.name || "hotel"}-customers.csv`,
                headers: [
                    { label: t("email"), key: "email" },
                    { label: t("name"), key: "name" },
                    { label: t("surname"), key: "surname" },
                    { label: t("birthdate"), key: "birthdate" },
                    { label: t("country"), key: "country" },
                    { label: t("mobile-phone"), key: "mobile-phone" },
                    { label: t("accesses"), key: "accesses" },
                    { label: t("commercial-communications"), key: "marketingAccepted" },
                    { label: t("email-verified"), key: "emailVerified" },
                ],
                data:
                    data?.map((c) => {
                        const o = getOrigin(c.project, c.signupApp, c.signupWifi, t, mobileApps, projects);
                        return {
                            email: c.email,
                            name: c.name,
                            surname: c.surname,
                            birthDate: c.birthDate,
                            country: c.country,
                            "mobile-phone": c.phone,
                            accesses: c.logins
                                ?.map((login) => {
                                    const appRef = login.type === loginType.APP ? login.value : null;
                                    const portalRef = login.type === loginType.WIFI ? login.value : null;
                                    const origin = getOrigin(null, appRef, portalRef, t, mobileApps, projects);
                                    if (!isCorporate) {
                                        return origin?.type;
                                    } else {
                                        const project = projects?.find(
                                            (project) => project.ref === portalRef || project.ref === appRef
                                        );
                                        const name = project?.name || portalRef || appRef;
                                        return `${origin?.type} ${name}`;
                                    }
                                })
                                .concat(o?.type === TAG_CHECK_IN ? `${o?.type} ${o?.name}` : "")
                                .join(", "),
                            marketingAccepted: c.marketingAccepted ? t("accepted") : t("not-accepted"),
                            emailVerified: c.emailVerified ? t("verified") : t("unverified"),
                        };
                    }) || [],
            }}
            design="classic-link"
            className="font-bold"
        >
            {children}
        </Button>
    );
};
