import React, { Fragment } from "react";
import UseButton from "../../../../components/useButton";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import UseItemActions from "../../../useItemActions";
import NewAlarm from "../../../../hooks/GraphqlCalls/Customers/modals/NewAlarm";
import { setModalContent, openModal } from "../../../../actions/uiActions";
import { changeActionValues, setActionName, setActionItemAffected } from "../../../../actions/globalActions";
import UpdateAlarm from "../../../../hooks/GraphqlCalls/Customers/UpdateAlarm";
import { printAlarm, roomHasAlarm, getTVChannelName } from "../../../../hooks/Utils/Customers/AlarmUtils";
import Alarm from "../../../../hooks/GraphqlCalls/Customers/models/Alarm";
import GetTVChannels from "../../../../hooks/GraphqlCalls/Customers/GetTVChannels";

const GuestAlarms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    GetTVChannels();

    const { data, channels } = useSelector((state) => state.sectionContent);
    const { rooms, alarms } = data || {};
    const guestName = `${data.guestName} ${data.guestSurname}`;

    const printRooms = () => {
        return rooms.map((room) => (
            <Fragment key={`room_alarm_${room.RoomID}`}>
                <div className="flex">
                    <div className="font-bold">{room.name}</div>
                    <div className="ml-auto">
                        {roomHasAlarm(alarms, room.name) ? (
                            <UseItemActions
                                dataType="alarm"
                                id={room.stayGuestRoomID}
                                executeSectionQuery={true}
                                actions={[
                                    {
                                        alarms: getRoomAlarm(alarms, room.RoomID),
                                        filterRoom: room.name,
                                        modal_value: room.name,
                                        name: "edit",
                                        action: "edit-room-alarm",
                                        modal_btn_name: "save",
                                    },
                                    {
                                        alarms: alarms.map((alarm) => Alarm(alarm, t)),
                                        modal_value: room.name,
                                        name: "delete",
                                        action: "delete-room-alarm",
                                    },
                                ]}
                                scrollParentId="guestAlarms"
                            />
                        ) : (
                            <div
                                id={"set-alarm-button"}
                                className="icon-edit text-xl text-gray-600 cursor-pointer"
                                onClick={() => setRoomAlarm(room.stayGuestRoomID)}
                            ></div>
                        )}
                    </div>
                </div>
                <div className="mt-2 p-4 rounded border border-gray-200 mb-4 break-words text-base text-gray-800">
                    {roomHasAlarm(alarms, room.name)
                        ? alarms.map((alarm) =>
                              printAlarm(alarm, room.name, getTVChannelName(channels, alarm.tvChannelId))
                          )
                        : t("{{capitalize, capitalize}}", { capitalize: t("no-alarmas-set") })}
                </div>
            </Fragment>
        ));
    };

    const getRoomAlarm = (alarms, roomId) => {
        const roomAlarm = [];
        alarms.forEach((alarm) => {
            if (alarm.roomIds.includes(roomId)) {
                roomAlarm.push(Alarm(alarm, t));
            }
        });
        return roomAlarm;
    };

    const deleteAllGuestAlarms = () => {
        dispatch(setActionItemAffected(alarms.map((alarm) => alarm.groupingID)));
        dispatch(setActionName("delete-alarm"));
        const modal = {
            id: data.guestID,
            title: "delete-all-guest-alarms",
            value: guestName,
            inputs: [
                {
                    text: "delete-all-guest-alarms-text",
                    type: "void",
                    action: "tVariables",
                    value: {
                        guestName,
                    },
                },
            ],

            useGlobalLoading: true,
            executeSectionQuery: true,
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "delete-alarm",
                },
            ],
        };
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const editAllGuestAlarms = () => {
        dispatch(setActionItemAffected(alarms.map((alarm) => alarm.groupingID)));
        dispatch(changeActionValues({ showAllGuestRooms: true }));
        dispatch(setActionName("edit-alarm"));
        const modal = {
            id: data.guestID,
            title: "edit-all-alarms",
            value: guestName,
            inputs: [
                {
                    text: "edit-alarm",
                    type: "editAlarm",
                },
                {
                    type: "data",
                    value: alarms,
                },
            ],

            useGlobalLoading: true,
            executeSectionQuery: true,
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "edit-alarm",
                },
            ],
        };
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const editDeleteButtons = () => {
        return (
            <>
                <UseButton
                    buttonName={t("{{capitalize, capitalize}}", { capitalize: t("edit-all-alarms") })}
                    buttonColor="btn-blue"
                    icon="edit"
                    adjust="w-1/2"
                    id="alarms-button-edit-all-guest-alarms"
                    action={editAllGuestAlarms}
                />
                <UseButton
                    buttonName={t("{{capitalize, capitalize}}", { capitalize: t("delete-all", { context: "female" }) })}
                    buttonColor="btn-red"
                    icon="delete"
                    adjust="ml-5 w-1/2 "
                    id="alarms-button-delete-all-guest-alarms"
                    action={deleteAllGuestAlarms}
                />
            </>
        );
    };

    const setRoomAlarm = (stayGuestRoomID) => {
        const newAlarmModalSetAll = {
            ...NewAlarm(),
            title: "set-room-alarms",
            value: guestName,
            text: "",
            inputs: [
                {
                    text: `${t("set-room-alarms", { guest: guestName })} (<span class="text-blue-300 font-bold">${t(
                        "num-room",
                        { count: 1 }
                    )}</span>):`,
                    type: "htmltext",
                },
                {
                    text: "new-alarm",
                    type: "newAlarm",
                },
            ],
        };

        dispatch(setModalContent(newAlarmModalSetAll));
        dispatch(changeActionValues({ isFromRoomDetail: true }));
        dispatch(changeActionValues({ stayGuestRoom: [stayGuestRoomID] }));
        dispatch(setActionName("new-alarm"));
        dispatch(openModal());
    };

    const setAllAlarms = () => {
        const guestName = `${data.guestName} ${data.guestSurname}`;
        const newAlarmModalSetAll = {
            ...NewAlarm(),
            title: "set-all-alarms",
            value: guestName,
            text: "",
            inputs: [
                {
                    text: `${t("set-room-alarms", { guest: guestName })} (<span class="text-blue-300 font-bold">${t(
                        "num-room",
                        { count: rooms.length }
                    )}</span>):`,
                    type: "htmltext",
                },
                {
                    text: "new-alarm",
                    type: "newAlarm",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "set",
                    style: "blue",
                    action: "new-alarm",
                },
            ],
        };

        dispatch(setModalContent(newAlarmModalSetAll));
        dispatch(changeActionValues({ isFromGuestDetail: true }));
        dispatch(setActionName("new-alarm"));
        dispatch(openModal());
    };

    return (
        <>
            <UpdateAlarm />
            <div id="alarmButtons" className="flex mb-8 ">
                {alarms && alarms.length > 0 ? (
                    editDeleteButtons()
                ) : (
                    <div id="alarms-button-set-all-alarms" className="w-full" onClick={() => setAllAlarms()}>
                        <UseButton
                            buttonName={t("{{capitalize, capitalize}}", { capitalize: t("set-all-alarms") })}
                            buttonColor="btn-blue"
                            icon="edit"
                            adjust="w-full"
                        />
                    </div>
                )}
            </div>
            <div id="guestAlarms" className="commentsMaxHeight overflow-scroll">
                {printRooms()}
            </div>
        </>
    );
};

export default GuestAlarms;
