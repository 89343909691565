import React, { useEffect, useRef, useState, useCallback } from "react";
import Translations from "components/Translations";
import Icon from "components/Icon";
import { useAuth } from "hooks/Session/auth";
import { predefinedTags } from "hooks/Utils/Utils";
import { useUpdateTickerTapes, useListCustomTags } from "../graphql/useTickerTapes";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const TickerTapesContent = ({ getData, translations: trans, loading }) => {
    const { t } = useTranslation();
    const { languages: projectLangs, project, permissions } = useAuth();

    const { update: updateTickerTape } = useUpdateTickerTapes({
        onCompleted: () => {
            getData();
        },
    });
    const { getCustomTags, customTags } = useListCustomTags();
    const { id } = useParams();
    const [translations, setTranslations] = useState([]);
    const hasChange = useRef(false);
    useEffect(() => {
        setTranslations(
            Object.entries(JSON.parse(trans || "{}")).map(([key, value]) => {
                return { languageCode: key, name: value };
            })
        );
        if (customTags.length == 0 && project.hasChainModule) {
            getCustomTags();
        }
    }, [trans]);

    useEffect(() => {
        if (hasChange.current) {
            updateTickerTape({
                variables: {
                    id,
                    content: translations.map((trans) => ({ lang: trans.languageCode, text: trans.name })),
                },
            });
            hasChange.current = false;
        }
    }, [translations]);

    return (
        <div className="w-full bg-white rounded p-6">
            <div className=" border-b border-gray-300 mb-2">
                <h2 className=" font-bold text-2xl">{t("content")}</h2>
                <p>{t("add-and-translate-the-content")}</p>
            </div>
            <div className=" flex gap-6 my-4 justify-center ">
                <Icon size={"2xl"} type={"warning"}></Icon>

                <ul className=" list-disc">
                    <li>{t("in-rooms-the-message-will-be-displayed-in-the-language-of-the-guest")}</li>
                    <li>{t("in-common-areas-the-message-will-be-displayed-in-all-languages")}</li>
                </ul>
            </div>

            <Translations
                id="content-translations"
                maxHeight="10rem"
                languages={projectLangs?.map((language) => language.languageRef)}
                translations={translations}
                defaultLang={projectLangs?.find((language) => language.isDefault)?.languageRef}
                tags={[
                    {
                        label: t("predefined-tags"),
                        options: predefinedTags(t, permissions?.tags || [], "TV", "GENERAL").map((tag) => ({
                            ...tag,
                            label: (
                                <p className=" flex ">
                                    <span>{tag.value.replace(/\{|\}|\./g, "")}</span>
                                    {tag.info && (
                                        <Icon className={"ml-2"} type={"info"} size={1.5} tooltip={t(tag.info)}></Icon>
                                    )}
                                </p>
                            ),
                            value: tag.value.replace(/\{|\}|\./g, ""),
                        })),
                    },
                    ...(customTags.length > 0
                        ? [
                              {
                                  label: t("custom-tags"),
                                  options: customTags,
                              },
                          ]
                        : []),
                ]}
                multiline={true}
                resize={false}
                avoidLineBreaks={true}
                placeholder="Enter translation"
                onChange={(trans) => {
                    if (JSON.stringify(trans) != JSON.stringify(translations)) {
                        hasChange.current = true;
                        setTranslations(trans);
                    }
                }}
            />
        </div>
    );
};
