import React, { useContext } from "react";
import classNames from "classnames";
import TableContext from "./Context";

import ErrorInfo from "components/ErrorInfo";
import Icon from "components/Icon";

export const Header = ({ count }) => {
    const { cols, header: headerData, sort, rows, setSort, headerStyle } = useContext(TableContext);
    const header =
        typeof headerData === "object"
            ? Object.keys(headerData)?.map((id) => ({
                  id: id,
                  ...headerData[id],
              }))
            : [];

    const rowActionsHeader = cols.includes("row-actions") && header.find((h) => h.id === "row-actions");
    if (rowActionsHeader) {
        rowActionsHeader.title = <Counter total={count} />;
    } else {
        header.push({
            id: "count",
            title: <Counter total={count} />,
            width: "2%",
        });
    }

    const error = (() => {
        if (!cols || cols.length === 0) {
            return "No columns provided";
        } else if (!header) {
            return "No header provided";
        }
        return null;
    })();

    const onClickHeader = (col) => {
        if (col?.sortable) {
            if (sort?.field === col?.id && sort?.criteria === "desc") {
                setSort(null);
            } else {
                setSort({
                    field: col?.id,
                    criteria: sort?.field === col?.id && sort?.criteria === "asc" ? "desc" : "asc",
                });
            }
        }
    };

    if (!header?.length || !cols?.length) {
        return null;
    }

    const hasHeaderData =
        error ||
        (cols?.length &&
            cols
                ?.filter((c) => c != "row-expand" && c != "row-actions")
                .some((col) => header?.find((h) => h?.id === col)));

    if (!hasHeaderData) {
        return null;
    }

    return (
        <>
            <colgroup>
                {cols.map((col) => {
                    const th = header?.find((h) => h?.id === col);
                    const style = {
                        width: th?.width || "auto",
                        minWidth: th?.minWidth || "auto",
                        maxWidth: th?.maxWidth || "auto",
                    };
                    return <col key={col} style={style} align={th?.align} />;
                })}
            </colgroup>

            <thead
                className="sticky z-10 top-0 bg-white"
                style={headerStyle ? headerStyle : { boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)" }}
            >
                {error ? (
                    <tr className="text-center">
                        <td colSpan={cols?.length || 1} className=" px-4 py-10">
                            <ErrorInfo useDefault={false}>{error}</ErrorInfo>
                        </td>
                    </tr>
                ) : (
                    <tr className="text-left">
                        {cols.map((colID) => {
                            const th = header?.find((h) => h?.id === colID);
                            const id = `col-${colID}`;
                            const icon = sort?.field === th?.id ? sort?.criteria : "sort";
                            const thClass = classNames({
                                "px-4 py-2 whitespace-nowrap": true,
                                [`text-${th?.align}`]: th?.align,
                                "cursor-pointer": th?.sortable && rows?.length,
                                "text-gray-800": !rows?.length,
                            });
                            const thOnClick = th?.sortable && rows?.length ? () => onClickHeader(th) : null;

                            const cellClass = classNames({
                                "flex items-center space-x-2": true,
                                "justify-center": th?.align === "center",
                                "justify-end": th?.align === "right",
                                "justify-start": th?.align === "left",
                            });

                            return (
                                <th key={id} id={id} className={thClass} onClick={thOnClick}>
                                    <div className={cellClass}>
                                        <span>{th?.title}</span>
                                        {th?.sortable ? <Icon type={icon} size="xl" /> : null}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                )}
            </thead>
        </>
    );
};

export default Header;

const Counter = ({ total }) => {
    const { texts } = useContext(TableContext);
    return (
        <div className="text-gray-800 text-sm whitespace-nowrap font-thin">
            {total ? texts?.countResults(total) : null}
        </div>
    );
};
