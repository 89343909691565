import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Button from "./Button";

const positionsObj = {
    TOP_LEFT: "TOP_LEFT",
    TOP_CENTER: "TOP_CENTER",
    TOP_RIGHT: "TOP_RIGHT",
    MIDDLE_LEFT: "MIDDLE_LEFT",
    MIDDLE_CENTER: "MIDDLE_CENTER",
    MIDDLE_RIGHT: "MIDDLE_RIGHT",
    BOTTOM_LEFT: "BOTTOM_LEFT",
    BOTTOM_CENTER: "BOTTOM_CENTER",
    BOTTOM_RIGHT: "BOTTOM_RIGHT",
};

const PositionSelector = ({ value, onChange }) => {
    const [selectedPosition, setSelectedPosition] = useState(null);

    useEffect(() => {
        if (value) {
            setSelectedPosition(value);
        }
    }, [value]);

    const currentPosition = selectedPosition;
    const handleChange = (position) => {
        if (onChange) {
            onChange(position);
        }
        setSelectedPosition(position);
    };

    const positions = Object.values(positionsObj);

    return (
        <div className="grid grid-cols-3 grid-rows-3 border-2 rounded border-gray-600 w-full h-52">
            {positions.map((position) => {
                const isSelected = position === currentPosition;

                return (
                    <Button
                        key={position}
                        onClick={() => handleChange(position)}
                        id={`position-button-selector-${position}`}
                        className="flex items-center justify-center h-full w-full"
                    >
                        <div className="transition-all duration-300 ease-in-out hover:bg-zafiro-400 hover:bg-opacity-40 w-10 h-10 rounded flex items-center justify-center">
                            <div
                                className={classNames("rounded transition-all duration-300 ease-in-out", {
                                    "bg-zafiro-400 w-10 h-10": isSelected,
                                    "bg-gray-400 w-1 h-1": !isSelected,
                                })}
                            ></div>
                        </div>
                    </Button>
                );
            })}
        </div>
    );
};

export default PositionSelector;
