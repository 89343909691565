import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ReactTooltip from "components/ReactTooltip";

//Components
import UseIcon from "../useIcon";

import { Context as EnvironmentContext } from "contexts/Global/env";

function UseToast({ title, msgs, minimize, type }) {
    const [showContent, setShowContent] = useState(true);
    const [errorNumber, setNumberError] = useState(0);
    const [onLoad, setOnLoad] = useState(true);
    const handleShowContent = () => {
        setShowContent(!showContent);
    };

    const showError = errorNumber === msgs.length;

    useEffect(() => {
        let error = 0;
        let load = 0;

        if (msgs) {
            msgs.forEach((msg) => {
                if (msg && msg.error) {
                    error++;
                }
                if (msg && msg.spinner) {
                    load++;
                }
            });
        }

        if (load === 0) {
            setOnLoad(false);
        }
        setNumberError(error);
    }, [msgs]);

    return (
        <div className=" flex flex-col" style={{ width: "100%" }}>
            <div
                className={` flex items-center w-full h-8 absolute top-0 px-4 left-0 ${
                    !showError && errorNumber === 0 && !onLoad
                        ? "bg-green-100"
                        : onLoad && !showError
                        ? "bg-orange-100"
                        : !onLoad && !showError
                        ? "bg-orange-100"
                        : "bg-red-100"
                } `}
                style={{ width: "100%" }}
            >
                <div className={` w-full flex items-center justify-between `} style={{ width: "100%" }}>
                    <div className=" first-capital w-11/12">{title}</div>
                    {minimize ? (
                        <div className=" flex justify-end " style={{ width: "1.25rem" }}>
                            <UseIcon
                                icon={{ name: `chevron${!showContent ? "-up" : ""}` }}
                                adjust="inline-block opacity-75"
                                onclickCallback={handleShowContent}
                            />
                        </div>
                    ) : null}
                </div>{" "}
            </div>
            {showContent ? (
                <div
                    className=" mt-8 "
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    {msgs
                        ? msgs.map((msg, index) => {
                              return (
                                  <div className={`${msg.adjust}`} style={{ width: "100%" }}>
                                      {msg.text ? (
                                          <div className={`${msg.textAdjust}`}>
                                              {msg.textLink ? (
                                                  <Link
                                                      data-tip={msg.textTooltip}
                                                      to={msg.textLink}
                                                      className={`t-link`}
                                                  >
                                                      {msg.text}
                                                  </Link>
                                              ) : (
                                                  <span className=" text-gray-900" data-tip={msg.textTooltip}>
                                                      {msg.text}
                                                  </span>
                                              )}
                                              {msg.textTooltip ? (
                                                  <ReactTooltip
                                                      place="bottom"
                                                      type="light"
                                                      offset={{ top: -8, left: -8 }}
                                                      html={true}
                                                      border={true}
                                                      multiline={true}
                                                      borderColor="#D3DAE1"
                                                  />
                                              ) : null}
                                          </div>
                                      ) : null}
                                      {msg.description ? (
                                          <p
                                              className={`${msg.descriptionAdjust}`}
                                              style={msg.descriptionStyle ? msg.descriptionStyle : {}}
                                          >
                                              {msg.description}
                                          </p>
                                      ) : null}
                                      {msg.icon ? <UseIcon icon={msg.icon} adjust={msg.iconAdjust} /> : null}
                                      {msg.spinner ? (
                                          <div
                                              className={`${msg.spinnerAdjust}`}
                                              style={{ width: "1.25rem", marginRight: "1.5rem" }}
                                          >
                                              <div
                                                  className={classNames({
                                                      "lds-roller": true,
                                                      "refactor-loading": EnvironmentContext.development,
                                                  })}
                                                  style={msg.spinnerStyle}
                                              >
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                                  <div></div>
                                              </div>
                                          </div>
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}
                </div>
            ) : null}
        </div>
    );
}

export default UseToast;
