import React, { createContext, useState, useContext, useEffect } from "react";
import { Session } from "hooks/Utils/Session";
import { toast } from "react-toastify";
import UseToast from "components/Notifications/useToast";
import { useTranslation } from "react-i18next";
import { arrangeToastMessagesUploadingFiles } from "hooks/Utils/DesignUtils";

const UploadFileContext = createContext();

const UploadFileContextProvider = ({ children }) => {
    const { t } = useTranslation();

    const [toastID, setToastID] = useState(null);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [executeChange, setExecuteChange] = useState(false);

    const generateFilesUploading = ({ files = [], lang }) => {
        const filesUploading = files.map((file) => ({
            name: file.file.name,
            status: 1,
            errorMessage: "",
            size: file?.file?.size ?? 0,
            ref: "",
            typeFile: file.file.type,
            lang: lang,
        }));

        const [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, files.length);

        const id = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: false,
            closeButton: false,
            closeOnClick: false,
            draggable: false,
            hideProgressBar: true,
            icon: false,
            className: "use-toast",
        });

        setToastID(id);
        setFilesToUpload(filesUploading);
    };

    const handleFilesUpload = async ({
        files,
        t,
        generateFilesUploading,
        uploadFilesRequest,
        token,
        options = {},
        onComplete,
        folder,
    }) => {
        const {
            url = "upload",
            fileRef = false,
            arrangeToastMessagesUploadingFiles: arrangeMessages = arrangeToastMessagesUploadingFiles,
        } = options;

        generateFilesUploading({ files, t, arrangeToastMessagesUploadingFiles: arrangeMessages });

        try {
            await uploadFilesRequest({
                files,
                url,
                folder,
                fileRef,
                t,
                arrangeToastMessagesUploadingFiles: arrangeMessages,
                token,
                onComplete,
            });
        } catch (err) {
            console.error("Error uploading files:", err);
            return { fileRef: null };
        }
    };

    const allFilesProcessed = () => {
        return filesToUpload.every((file) => file.status === 2 || file.status === 4);
    };

    useEffect(() => {
        if (executeChange) {
            const [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesToUpload, filesToUpload.length);

            toast.update(toastID, {
                render: <UseToast title={title} msgs={msgs} minimize={true} />,
                className: "use-toast",
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            if (allFilesProcessed()) {
                setTimeout(() => {
                    toast.dismiss(toastID);
                }, 2000);
            }

            setExecuteChange(false);
        }
    }, [executeChange, t, filesToUpload, toastID]);

    const uploadFilesRequest = async ({ files, url, fileRef, lang, parseName, folder, token, onComplete, subPath }) => {
        for (let i = 0; i < files.length; i++) {
            const { file } = files[i];
            const { quality, language, name } = file;

            const uploadUrl =
                `${Session.getUploadUrl()}/${subPath ? `${subPath}/` : ""}?type=${
                    file.type.includes("video/") ? "video" : file.type.includes("image/") ? "asset" : file.type
                }&token=${token.replace("Bearer ", "")}` +
                `${fileRef ? `&ref=${fileRef}` : ""}` +
                `${folder ? `&folder=${folder}` : ""}`;

            console.log(folder);

            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", parseName ? parseName(name) : name);
            formData.append("folderRef", folder || "root");

            if (quality) {
                formData.append("resolution", quality);
            }
            if (language) {
                formData.append("fileLanguage", language);
            }

            try {
                const uploadResponse = await fetch(uploadUrl, {
                    method: "POST",
                    body: formData,
                }).then((res) => res.json());

                if (uploadResponse?.ref) {
                    updateFileStatus(i, uploadResponse.ref, file, uploadResponse.streamStatus);
                } else {
                    updateFileError(i, uploadResponse?.error?.message);
                }
            } catch (err) {
                updateFileError(i, err.message);
            }

            setExecuteChange(true);
        }

        if (typeof onComplete === "function") {
            onComplete();
        }
    };

    const updateFileStatus = (index, ref, file, streamStatus) => {
        setFilesToUpload((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].status = file.type === "video" && streamStatus !== "COMPLETE" ? 1 : 2;
            updatedFiles[index].ref = ref;
            return updatedFiles;
        });
    };

    const updateFileError = (index, errorMessage) => {
        setFilesToUpload((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].status = 4;
            updatedFiles[index].errorMessage = errorMessage || t("Unknown error");
            return updatedFiles;
        });
    };

    return (
        <UploadFileContext.Provider
            value={{
                toastID,
                setToastID,
                filesToUpload,
                generateFilesUploading,
                uploadFilesRequest,
                handleFilesUpload,
                executeChange,
            }}
        >
            {children}
        </UploadFileContext.Provider>
    );
};

const useUploadFileContext = () => useContext(UploadFileContext);

export { UploadFileContextProvider, useUploadFileContext };
