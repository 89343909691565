import React, { useContext } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";
import { Context as EnvironmentContext } from "contexts/Global/env";

/**
 * This component is a icon with a tooltip
 * @component
 * @param {string} type The icon identifier
 * @param {string} tooltip The tooltip text to show
 * @param {string} tooltipType The id of the tooltip type
 * @param {string} size The size of the icon (default: 1)
 * @param {string} className Additional classes
 * @param {string} style Additional styles
 * @param {string} text Optional text to show below the icon
 * @returns {JSX.Element} The UI of the component
 * @example <Icon type="info" tooltip="This is a info message" />
 */
const Icon = ({ id, type, tooltip, tooltipType, className, style, text, textPosition = "right", size = 1 }) => {
    const { highlightComponent } = useContext(GlobalContext);

    if (type === "refactor" && !EnvironmentContext.development) {
        // Refactor warnings are only shown in development mode
        return null;
    }

    const customTypes = {
        error: "warning",
        sort: "table-sort",
        asc: "sort-desc",
        desc: "sort-asc",
        true: "tick-right",
        false: "remove",
        close: "remove",
        chain: "corporate-access",
        "alert-orange": "alert",
        "alert-red": "alert",
        critical: "warning",
        "blue-warning": "warning",
        refactor: "warning",
    };

    const fontSize = (size) => {
        const text2rem = {
            xxs: 0.5,
            xs: 0.75,
            sm: 0.875,
            base: 1,
            xl: 1.25,
            "2xl": 1.5,
            "3xl": 1.667,
            "4xl": 2.25,
            "5xl": 3,
            "6xl": 4,
            "7xl": 4.5,
            "8xl": 5,
            "9xl": 5.5,
            "10xl": 6,
        };
        const rem = text2rem[size] || size;
        return rem === 1 ? undefined : `${rem}rem`;
    };

    const isFontAwesome = type?.match(/^fa.\sfa-+/);

    const iconClass = classNames({
        [`icon icon-${customTypes[type] || type}`]: type && !isFontAwesome,
        [type]: isFontAwesome,
        "inline-block leading-6": true,
        "text-red-500": type === "error",
        "text-red-100": type === "critical",
        "text-orange-100 hover:text-orange-400": type === "warning" && !String(className)?.includes("text-"),
        "text-orange-100 absolute": type === "alert-orange",
        "text-red-100 absolute": type === "alert-red",
        "refactor-warning p-2 rounded-full float-left mr-1": type === "refactor",
        "highlight-component": highlightComponent,
        [className]: className,
    });

    const props = {
        id,
        className: iconClass,
        style: {
            fontSize: fontSize(size),
            ...style,
        },
        ...(tooltip &&
            !text && {
                "data-tip": tooltip,
                "data-for": tooltipType || (type === "error" ? "warning-tooltip" : "dangerous-html-tooltip"),
            }),
    };

    const icon = (() => {
        if (type === "alert-orange" || type === "alert-red") {
            return (
                <div
                    className={`relative text-white text-center align-middle font-bold rounded-full ${
                        type === "alert-red" ? "bg-red-100" : "bg-orange-100"
                    }`}
                    style={{
                        fontSize: fontSize(size),
                        width: "1.4em",
                        height: "1.4em",
                        lineHeight: "1em",
                        padding: "0.2em",
                        zoom: 0.65,
                    }}
                >
                    !
                </div>
            );
        }
        return <i {...props} />;
    })();

    if (text) {
        return (
            <div
                className="flex items-center space-x-2"
                {...(tooltip
                    ? {
                          "data-tip": tooltip,
                          "data-for": tooltipType || (type === "error" ? "warning-tooltip" : "dangerous-html-tooltip"),
                      }
                    : {})}
            >
                {textPosition === "left" ? <div className="leading-6">{text}</div> : null}
                {icon}
                {textPosition !== "left" ? <div className="leading-6">{text}</div> : null}
            </div>
        );
    }

    return icon;
};
Icon.displayName = "Icon";

export default Icon;
