import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setActiveFilters, getFilterQuery, setPage } from "../../actions/tableActions";

import { capitalizeFirst } from "../../hooks/Utils/Utils";
import Icon from "components/Icon";

const UseSearch = ({ id }) => {
    const dispatch = useDispatch();
    let inputTimeout = useRef();
    const { t } = useTranslation();

    //Store data
    const { searchable, customConfig, disabled, type } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //States
    const [val, setVal] = useState("");

    //Listeners
    useEffect(() => {
        if (activeFilters && activeFilters.search) {
            setVal(activeFilters.search);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (activeFilters && !activeFilters.search) {
            setVal("");
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    //Clear the last setTimeout, otherwise the page will crash if the user navigates away before it executes
    useEffect(() => () => clearTimeout(inputTimeout.current), [inputTimeout]);

    const handleChangeFilters = (e) => {
        if (disabled) {
            return;
        }
        if (inputTimeout.current) {
            clearTimeout(inputTimeout.current);
        }
        const value = e.target.value.replace('"', "");
        setVal(value);
        inputTimeout.current = setTimeout(() => {
            if (value.length >= 2 || value.length === 0) {
                dispatchSearch(type === "list-fonts-library" ? encodeURI(value.trim()) : value.trim());
            }
        }, 500);
    };
    const handleBlurFilters = (e) => {
        if (disabled) {
            return;
        }
        dispatchSearch(type === "list-fonts-library" ? encodeURI(e.target.value.trim()) : e.target.value.trim());
    };
    const dispatchSearch = (value) => {
        dispatch(setActiveFilters("search", value));
        dispatch(setPage(Number(1)));
        dispatch(
            getFilterQuery({
                ...activeFilters,
                search: value,
            })
        );
    };
    const _handleKeyDown = (e) => {
        if (disabled) {
            return;
        }
        if (e.key === "Enter") {
            handleChangeFilters(e);
        }
    };
    return (
        <>
            {searchable ? (
                <>
                    <Icon type="refactor" tooltip="Please, use new TextInput component" />
                    <div className="w-full mr-8 relative">
                        <input
                            id={`${id ? id : ""}`}
                            type="text"
                            className={`w-full pr-10 ${
                                disabled
                                    ? "bg-gray-100 text-gray-200"
                                    : "hover:text-zafiro-400  text-gray-900 bg-gray-200"
                            } rounded p-2 pl-4 phNoTransform`}
                            placeholder={`${capitalizeFirst(
                                t(customConfig && customConfig.searchText ? customConfig.searchText : "search")
                            )} ${customConfig && customConfig.searchSuffix ? t(customConfig.searchSuffix) : ""} ...`}
                            onChange={handleChangeFilters}
                            onBlur={handleBlurFilters}
                            value={val}
                            disabled={disabled}
                            onKeyDown={_handleKeyDown}
                        ></input>
                        <span className="field-icon icon-search"></span>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default UseSearch;
