import React, { useRef, useState } from "react";
import { sortableElement } from "react-sortable-hoc";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import Button from "./Button";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * DraggableListItem Component
 *
 * @param {object} props - The component props.
 * @param {string|number} props.id - Unique identifier for the item.
 * @param {string} props.label - The text to be displayed in the item.
 * @param {object} [props.style] - Inline styles for the item.
 * @param {string} [props.addonClassName="addon-circle"] - CSS class for the additional container.
 * @param {Array} [props.actions=[]] - Array of actions available for the item.
 * @param {string} [props.imageSrc] - URL of the image (if available).
 * @param {object} [props.iconData] - FontAwesome icon object containing library and icon name.
 * @param {JSX.Element} [props.customAction]
 *
 * @returns {JSX.Element} The draggable item component.
 */
const DraggableListItem = sortableElement(
    ({
        id,
        label,
        style,
        addonClassName = "addon-circle",
        actions = [],
        actionsStyles = { width: "12rem", position: "absolute", left: "-11rem" },
        imageSrc,
        iconData,
        customAction = null,
    }) => {
        const actionsRef = useRef(null);
        const textRef = useRef(null);

        const [showTooltip, setShowTooltip] = useState(false);
        const [showActionIcon, setShowActionIcon] = useState(false);
        const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
        const handleActionClick = (action, event) => {
            event.stopPropagation();

            if (actionsRef.current?.close) {
                actionsRef.current.close();
            }

            if (typeof action?.onClick === "function") {
                action.onClick(event);
            }
        };
        const handleTextOverflow = () => {
            const el = textRef.current;
            if (el) {
                setShowTooltip(el.scrollWidth > el.clientWidth);
            }
        };

        const renderAddon = () => {
            if (imageSrc) {
                return <img className="object-cover w-full h-full" src={imageSrc} alt={label} />;
            }
            if (iconData) {
                return (
                    <FontAwesomeIcon icon={[iconData?.lib, iconData?.icon]} style={{ width: "60%", height: "60%" }} />
                );
            }
            return null;
        };

        const listItemClass = "relative px-6 py-4 bg-white select-none flex items-center";
        const dragIconClass = "mr-4 text-gray-800";
        const textClass = "whitespace-nowrap overflow-hidden overflow-ellipsis block ml-3 w-full select-none";
        const actionIconClass = classNames("text-gray-800", {
            "opacity-0": !showActionIcon,
            "opacity-100": showActionIcon || dropdownIsOpen,
        });

        return (
            <li
                className={listItemClass}
                style={style}
                onMouseEnter={() => setShowActionIcon(true)}
                onMouseLeave={() => setShowActionIcon(false)}
            >
                <Icon type="drag-and-drop" size={1.5} className={dragIconClass} />

                <div id={`${id}-addon-container`} className={addonClassName}>
                    {renderAddon()}
                </div>

                <div
                    ref={textRef}
                    onMouseOver={handleTextOverflow}
                    onMouseLeave={() => setShowTooltip(false)}
                    data-tip={showTooltip ? label : ""}
                    data-for="default-tooltip"
                    className={textClass}
                >
                    {label}
                </div>

                {customAction}

                <Dropdown
                    id={`${id}-content-actions-dropdown`}
                    ref={actionsRef}
                    float
                    showArrow={false}
                    width="1rem"
                    overflowX="unset"
                    onOpenChange={setDropdownIsOpen}
                    handler={<Icon type="row-actions" size={1.3} className={actionIconClass} />}
                >
                    <div
                        style={actionsStyles}
                        className="select-none mt-1 rounded w-40 bg-white shadow-lg text-left border border-gray-100 text-gray-900 flex flex-col"
                    >
                        {actions.map((action, index) => {
                            const actionId = `${id}-draggable-list-actions-${action.id || index + 1}`;
                            const actionClass = classNames("px-4 py-2 w-full text-left", {
                                "text-gray-500 hover:text-gray-500 cursor-not-allowed": action.disabled,
                                "text-gray-900 hover:bg-gray-100": !action.disabled,
                            });

                            return (
                                <Button
                                    key={actionId}
                                    id={actionId}
                                    disabled={action.disabled}
                                    onClick={(event) => {
                                        handleActionClick(action, event);
                                        setShowActionIcon(false);
                                    }}
                                    tooltip={action.tooltip}
                                    className={actionClass}
                                >
                                    {action.label}
                                </Button>
                            );
                        })}
                    </div>
                </Dropdown>
            </li>
        );
    }
);

export default DraggableListItem;
