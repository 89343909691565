import React, { useRef } from "react";
import UseCard from "../../../../useCard";
import { useSelector } from "react-redux";

//Components
import UseSectionHeader from "../../../../useSectionHeader";
import UpdateChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateChannels";
import ChannelsGeneralSettingsEPG from "../ChannelsGeneralSettingsEPG";
import ChannelsGeneralSettingsHighAvailability from "./ChannelsGeneralSettingsHighAvailability";
import ChannelsGeneralSettingsAlwaysDisplayTv from "./ChannelsGeneralSettingsAlwaysDisplayTv";
import ChannelsGeneralSettingsRememberLastCard from "./ChannelsGeneralSettingsRememberLastCard";
import { Session } from "hooks/Utils/Session";

const ChannelsGeneralSettings = () => {
    //Consts&states
    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: `/services`,
        },
        {
            name: "tv-channels",
            translate: true,
            route: `/services/tv-channels`,
        },
    ];
    const { permissions } = useSelector((state) => state.ui);
    const checkoutInterfacePerm = permissions?.services?.checkoutInterface;
    const highAvailability = permissions?.services?.highAvailability;
    const isSuperUser = Session.isSuperUser();

    //renders
    return (
        <>
            <UseSectionHeader title={["general-settings"]} customBreadCrumbs={breadcrumbs} />
            <UpdateChannels />
            <div className="grid grid-cols-2 gap-6">
                <div className="bg-white rounded shadow p-5">
                    <ChannelsGeneralSettingsRememberLastCard />
                </div>
                {checkoutInterfacePerm && (
                    <div className="bg-white rounded shadow p-5">
                        <ChannelsGeneralSettingsAlwaysDisplayTv />
                    </div>
                )}
                {highAvailability && checkoutInterfacePerm && (
                    <div className="bg-white rounded shadow p-5">
                        <ChannelsGeneralSettingsHighAvailability />
                    </div>
                )}
                {isSuperUser && (
                    <div className="bg-white rounded shadow p-5">
                        <ChannelsGeneralSettingsEPG />
                    </div>
                )}
            </div>
        </>
    );
};

export default ChannelsGeneralSettings;
