import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { Session } from "hooks/Utils/Session";

import Settings from "./Settings";
import ChooseAsset from "./ChooseAsset";
import UseButton from "../../../../../useButton";
import UseIcon from "../../../../../useIcon";
import UseSelectWithSearch from "../../../../../useSelectWithSearch";
import { SortContents } from "../../../../../Sidebar/ScreensSidebar";
import { WidgetContext } from "../../../../../../contexts/editor";
import { changeActionValues } from "../../../../../../actions/globalActions";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { getProjectLangs, parseTranslation } from "hooks/Utils/SalesUtils";
import { DEVICE, SCREEN } from "constants/editor";
import { getScreens as GET_SCREENS } from "hooks/Utils/Design/EditorUtils";
import UseLoading from "../../../../../Table/useLoading";

import { useAuth } from "hooks/Session/auth";
import { parseMapsOptions } from "components/Section/Design/Maps/utils";

const Actions = ({
    actions,
    toolbarID,
    title,
    value,
    onChange,
    widgetTypeID,
    modalActions,
    index,
    type = null,
    modalContentId = null,
}) => {
    const { id: widgetID } = useContext(WidgetContext);
    const { isCorporate } = useAuth();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const allowDisabled = actions && actions.disabled === false ? false : true;
    const sectionName = `toolbar-${type}`;

    const { cScreenData, gridItems, cDevice, itemSelected } = useSelector((state) => state.design);
    const { permissions } = useSelector((state) => state.ui);
    const { languages: projectLangs, defaultLanguage } = useAuth();
    const modalValues = useSelector((state) => {
        return state.ui?.modalContent?.inputs ? state.ui.modalContent.inputs[index] : null;
    });
    const designID = cScreenData?.data?.designID ? cScreenData.data.designID : null;
    const screenType = cScreenData?.screenType;
    const deviceType = cDevice?.type ? cDevice.type : null;

    const currentWidget = gridItems
        ? gridItems.filter((w) => w && w.i === (modalActions === true ? itemSelected : widgetID))[0]
        : null;
    const currentActions = value
        ? value
        : currentWidget && !modalActions
        ? currentWidget.actions
        : modalActions === true && modalValues && modalValues.cValue
        ? modalValues.cValue
        : null;

    // Current Screen
    const isNormalScreen =
        screenType === SCREEN.TYPE.TV || screenType === SCREEN.TYPE.GENERAL || screenType === SCREEN.TYPE.MOBILE;
    const isChromecastScreen = screenType === SCREEN.TYPE.CCINSTRUCTION;

    // Current Device
    const isDeviceTV = deviceType === DEVICE.TYPE.TV;
    const isDeviceMobile = deviceType === DEVICE.TYPE.MOBILE;

    // Permissions
    const hasChannels = permissions?.product?.channels;
    const hasMobile = permissions?.product?.mobile;
    const hasStay = permissions?.product?.guest;
    const hasCast = permissions?.product?.cast;
    const hasSales = permissions?.product?.sales;
    const hasCommonZones = permissions?.product?.house;
    const hasPOS = permissions?.integrations?.pos;
    const hasMyOrders = permissions?.services?.myOrders;
    const hasLegacyTV = permissions?.legacy?.tv;
    const hasLegacySales = permissions?.legacy?.sales;
    const hasApps = permissions?.design?.apps;
    const hasSignage = permissions?.product?.signage;
    const hasMaps = permissions?.design?.maps;

    // Permissions for actions
    const hasActionInputs = permissions?.actions?.tvInputs;
    const hasActionBill = permissions?.actions?.bill && hasStay;
    const hasActionCheckout = permissions?.actions?.checkout && hasStay;
    const hasActionAreas = permissions?.actions?.areas && hasCommonZones;
    const hasActionBookings = permissions?.actions?.bookings && hasCommonZones;
    const hasActionPOS = permissions?.actions?.pos && hasPOS;
    const hasActionChannelList = permissions?.actions?.listChannels && (hasChannels || hasLegacyTV) && isDeviceTV;
    const hasActionChannel =
        permissions?.actions?.channel && (hasChannels || hasLegacyTV) && !isCorporate && isDeviceTV;
    const hasActionSales = permissions?.actions?.sales && hasSales && (isDeviceTV || isDeviceMobile);
    const hasActionVendureSales = hasActionSales && !hasLegacySales;
    const hasActionLegacySales = hasActionSales && hasLegacySales && isDeviceMobile;
    const hasParentalCode = permissions?.actions?.actParental;
    const hasActionFeedback = permissions?.actions?.actFeedback && hasStay;

    // States
    const [destinationType, setDestinationType] = useState(allowDisabled ? "disabled" : "");
    const [selectedAction, setSelectedAction] = useState(null);
    const [inputTextValue, setInputTextValue] = useState({});
    const [lastTypeValue, setLastTypeValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);
    const [buttonApplyOnClick, setButtonApplyOnClick] = useState(false);
    const [langDefault, setLangDefault] = useState("");

    // Get Screens
    const [getScreens, { data: screensData, loading: loadingScreens, error: errorScreens }] = useMSQuery(
        GET_SCREENS(designID)
    );

    // Get custom URL tags
    const GET_CUSTOM_URL_TAG = gql`
        {
            designTags(filter: { target: [URL] }) {
                results {
                    id
                    name
                    tag
                    target
                }
            }
        }
    `;
    const [getCustomURLTags, { data: customURLTags, loading: loadingURLTags, error: errorURLTags }] =
        useMSQuery(GET_CUSTOM_URL_TAG);

    // Get old TV channels
    const GET_TV_CHANNELS = !hasChannels
        ? gql`
              {
                  oldTVChannels {
                      channels
                  }
              }
          `
        : gql`
              {
                  tunes {
                      results {
                          id
                          name
                      }
                  }
              }
          `;
    const [getTvChannels, { data: tvChannelsData, loading: loadingTvChannels, error: errorTvChannels }] =
        useMSQuery(GET_TV_CHANNELS);

    // Get old shops
    const GET_ROOMSHOPS = gql`
        {
            roomShops {
                results {
                    id
                    name
                    available
                }
            }
        }
    `;
    const [getRoomShops, { data: roomShopsData, loading: loadingRoomShops, error: errorRoomShops }] =
        useMSQuery(GET_ROOMSHOPS);

    // Get vendure shops
    const GET_VENDURE_SHOPS = gql`
        {
            shops {
                results {
                    id
                    name: nameTranslations {
                        lang
                        text
                    }
                    token
                    users
                    customFields {
                        isActive
                    }
                }
            }
        }
    `;
    const [getVendureShops, { data: vendureShopsData, loading: loadingVendureShops, error: errorVendureShops }] =
        useMSQuery(GET_VENDURE_SHOPS);

    const GET_COMMON_ZONES = gql`
        {
            rooms(filter: { type: COMMONZONE }) {
                results {
                    name
                    roomTVs {
                        id
                        name
                    }
                }
            }
        }
    `;

    const [getCommonZones, { data: commonZonesData, loading: loadingCommonZones, error: errorCommonZones }] =
        useMSQuery(GET_COMMON_ZONES);

    const GET_MAPS = gql`
        query listMaps {
            maps(lang: "all") {
                results {
                    id
                    name
                }
            }
        }
    `;

    const [getMaps, { data: mapsData, loading: loadingMapsData, error: errorMapsData }] = useMSQuery(GET_MAPS);

    const saveAction = (type, value) => {
        setDestinationType(type);
        if (type === "library") {
            return;
        }

        let v = { ...lastTypeValue };
        v[type] = value;
        setLastTypeValue(v);

        let action;
        if (type === "landing") {
            action = { type: "section", value: type };
        } else if (type !== "disabled") {
            action = { type: type, value: value };
        }

        if (modalActions) {
            dispatch(changeActionValues({ "action-destination": action?.value ? action : null }));
        } else {
            const ensureValueTypes = ["content", "section", "tvchannel"];
            if (action?.value || !ensureValueTypes.includes(action?.type)) {
                onChange
                    ? onChange(action)
                    : dispatch(
                          setExecuteQuery({
                              action: "updateWidgetActions",
                              params: {
                                  widgetID: widgetID,
                                  actions: {
                                      actions: action ? [action] : [],
                                  },
                              },
                          })
                      );
            }
        }
    };

    const checkURL = (type) => {
        let str = inputTextValue[type];
        str = "" + str;
        if (!str) {
            return true;
        }
        if (actions.http) {
            return str.toLowerCase().substring(0, 7) === "http://" || str.toLowerCase().substring(0, 8) === "https://";
        } else {
            return str.toLowerCase().substring(0, 8) === "https://";
        }
    };

    const getVal = (type) => {
        if (type.id === "landing") {
            return type.id;
        }
        if (type.id === "url") {
            if (selectedAction && selectedAction.type === "url") {
                return selectedAction.value;
            }
        }
        return null;
    };

    let destTypes = [
        {
            id: "qr",
            name: t("predefined"),
            input: "select",
        },
        {
            id: "landing",
            name: t("landing"),
            input: "radio",
        },
        {
            id: "content",
            name: t("screen"),
            placeholder: t("select screen"),
            input: "select",
        },
        {
            id: "section",
            name: t("predefined section"),
            placeholder: t("select predefined section"),
            input: "select",
        },
        {
            id: "url",
            name: t("link") + (actions.http ? "" : " (URL - " + t("https only") + ")"),
            input: "text",
        },
        {
            id: "library",
            name: t("media file"),
            input: "asset",
        },
    ];

    if (hasActionChannel) {
        destTypes.push({
            id: "tvchannel",
            name: t("TV channel"),
            placeholder: t("select-channel"),
            input: "select",
        });
    }

    if (isChromecastScreen && !widgetTypeID) {
        destTypes = [
            {
                id: "section",
                name: t("predefined section"),
                placeholder: t("select predefined section"),
                input: "select",
            },
        ];
    }

    const destinations = destTypes;

    const screensOptions = {};
    if (screensData?.contentTree?.screens) {
        const screensResult = screensData.contentTree.screens;
        const screens = SortContents(screensResult.dir.contents);
        screens.forEach((c) => {
            if (c.isEnabled) {
                screensOptions[c.id] = c.name;
            }
        });
        if (screensResult?.dir?.subDirs?.length > 0) {
            screensResult.dir.subDirs.forEach((sub) => {
                if (sub.contents) {
                    const subscreens = SortContents(sub.contents);
                    subscreens.forEach((c) => {
                        if (c.isEnabled) {
                            screensOptions[c.id] = c.name;
                        }
                    });
                }
            });
        }
    }

    const customTagsOptions = {};
    if (customURLTags?.designTags?.results) {
        customURLTags.designTags.results.forEach((element) => {
            customTagsOptions[element.tag] = element.name;
        });
    }

    const channelsOptions = {};
    if (tvChannelsData?.oldTVChannels?.channels) {
        const chData = JSON.parse(tvChannelsData.oldTVChannels.channels);
        if (chData?.categories?.length > 0) {
            const channels = chData.categories[0]?.items;
            channels.forEach((channel) => {
                channelsOptions[channel.id] = channel.name;
            });
        }
    } else if (tvChannelsData?.tunes?.results) {
        const channels = tvChannelsData?.tunes?.results;
        channels.forEach((channel) => {
            channelsOptions[channel.id] = channel.name;
        });
    }

    const shopOptions = {};
    if (vendureShopsData?.shops?.results) {
        vendureShopsData.shops.results.forEach((s) => {
            if (s?.customFields?.isActive) {
                shopOptions["roomshop:" + s.token] = parseTranslation(s.name, langDefault) || s.token;
            }
        });
    }

    const commonZonesOptions = {};

    if (commonZonesData?.rooms?.results) {
        commonZonesData.rooms.results.forEach((room) => {
            if (room.roomTVs && room.roomTVs.length > 0) {
                room.roomTVs.forEach((tv) => {
                    const key = `commonZone:${tv.id}`;
                    commonZonesOptions[key] = {
                        name: `<p>${room.name} <span class="text-gray-700" >${tv.name}</span> </p>`,
                        subname: tv.name,
                        enabled: true,
                    };
                });
            }
        });
    }
    const oldShopOptions = {};
    if (roomShopsData?.roomShops?.results) {
        roomShopsData.roomShops.results.forEach((s) => {
            if (s.available) {
                oldShopOptions["roomshop:" + s.id] = t("roomshop {{name}}", { name: s.name });
            }
        });
    }

    const mapOptions = {};

    if (mapsData?.maps?.results) {
        parseMapsOptions(mapsData, defaultLanguage).forEach((map) => {
            mapOptions["maps:" + map.value] = map.label;
        });
    }

    const getOptions = (type) => {
        const groups = {
            qr: [
                {
                    name: t("custom-tags"),
                    enabled: true,
                    options: customTagsOptions,
                },
                {
                    name: t("predefined-tags"),
                    enabled: true,
                    options: {
                        pairingmobile: {
                            name: t("Pairing from mobile"),
                            enabled: hasMobile,
                        },
                        pairingurl: {
                            name: t("Pairing url"),
                            enabled: hasCast,
                        },
                        wifidata: {
                            name: t("Wifi data"),
                            enabled: true,
                        },
                    },
                },
            ],
            section: [
                {
                    name: t("apps"),
                    enabled: (isChromecastScreen || (isNormalScreen && isDeviceTV)) && hasApps,
                    options: {
                        netflix: "Netflix",
                        appletv: "AppleTV",
                        youtube: "YouTube",
                        ...(hasStay && { resetCredentials: t("reset-credentials") }),
                    },
                },
                {
                    name: t("tv"),
                    enabled: isNormalScreen && isDeviceTV,
                    options: {
                        tv: {
                            name: t("channels"),
                            enabled: hasActionChannelList,
                        },
                        "ccinstruction-screen": {
                            name: t("ccinstruction-screen"),
                            enabled: hasCast,
                        },
                        languageSelection: {
                            name: t("welcomeScreen-lang"),
                            enabled: true,
                        },
                        inputs: {
                            name: t("inputs"),
                            enabled: hasActionInputs,
                        },
                    },
                },
                {
                    name: t("movies"),
                    enabled:
                        isDeviceTV &&
                        !isChromecastScreen &&
                        (permissions?.movies?.hollywood || permissions?.movies?.adult),
                    options: {
                        movies: { name: t("all-movies"), enabled: true },
                        ["hollywood-movies"]: { name: t("hollywood-movies"), enabled: permissions?.movies?.hollywood },
                        ["adult-movies"]: { name: t("adult-movies"), enabled: permissions?.movies?.adult },
                    },
                },
                {
                    name: t("sales"),
                    warning: errorVendureShops
                        ? `${t("There are technical problems with Zafiro Sales")}\n${t(
                              "It is not possible to link to a specific shop"
                          )}`
                        : null,
                    enabled: isNormalScreen && hasActionVendureSales,
                    options: {
                        roomshops: capitalizeFirst(t("shops")),
                        ...shopOptions,
                        myorders: { name: t("my-orders-history"), enabled: hasMyOrders ? true : false },
                    },
                },
                {
                    name: t("shops"),
                    enabled: isNormalScreen && hasActionLegacySales,
                    options: {
                        "external-pos": {
                            name: t("external-pos"),
                            enabled: hasActionPOS,
                        },
                        ...oldShopOptions,
                    },
                },
                {
                    name: t("guest-stay"),
                    enabled: isNormalScreen && hasStay,
                    options: {
                        bill: {
                            name: t("bill"),
                            enabled: hasActionBill,
                        },
                        checkout: {
                            name: t("express-checkout"),
                            enabled: hasActionCheckout,
                        },
                        parentalcode: {
                            name: t("parental code"),
                            enabled: hasParentalCode && isDeviceTV,
                        },
                        feedback: {
                            name: t("feedback"),
                            enabled: hasActionFeedback && isDeviceMobile,
                        },
                    },
                },
                {
                    name: t("maps"),
                    //TODO remove !isDeviceTV when it can be work on TV
                    enabled: hasMaps && Session.develMode() && !isDeviceTV,
                    options: {
                        ...mapOptions,
                    },
                },
                // {
                //     name: "Signage",
                //     info: t("The destination will display the scheduled sequences of the selected common zone"),
                //     enabled: Session.develMode() && hasSignage && isDeviceTV,
                //     options: {
                //         ...commonZonesOptions,
                //     },
                // },
                // {
                //     name: t("common-zones"),
                //     enabled: isDeviceMobile && hasCommonZones,
                //     options: {
                //         zones: {
                //             name: t("areas-activities"),
                //             enabled: hasActionAreas,
                //         },
                //         booking: {
                //             name: t("booking-requests"),
                //             enabled: hasActionBookings,
                //         },
                //     },
                // },
            ],
            content: [
                {
                    enabled: isNormalScreen,
                    options: {
                        ...screensOptions,
                    },
                },
            ],
            tvchannel: [
                {
                    enabled: isNormalScreen && isDeviceTV && hasActionChannel,
                    options: {
                        ...channelsOptions,
                    },
                },
            ],
        };

        const ensureOrder = ["roomshops", "myOrders"];

        const parseGroup = (group) => {
            let options = [];
            if (group?.enabled) {
                Object.keys(group.options)
                    .sort((a, b) => {
                        const aIndex = ensureOrder.indexOf(a);
                        const bIndex = ensureOrder.indexOf(b);
                        if (aIndex === bIndex) {
                            return 0;
                        } else if (aIndex === -1) {
                            return 1;
                        } else if (bIndex === -1) {
                            return -1;
                        } else {
                            return aIndex - bIndex;
                        }
                    })
                    .forEach((key) => {
                        const value = group.options[key];
                        const enabled = typeof value === "string" || value.enabled;
                        if (enabled) {
                            options.push({
                                id: key,
                                name: typeof value === "string" ? value : value.name,
                                category: group.name,
                                categoryWarning: group.warning,
                                categoryInfo: group.info,
                            });
                        }
                    });
            }
            return options;
        };

        const options = [];
        if (groups[type]) {
            groups[type].forEach((group) => {
                parseGroup(group).forEach((option) => {
                    options.push(option);
                });
            });
        } else {
            console.warn("Undefined action of type " + type);
        }

        return options;
    };

    useEffect(() => {
        if (currentActions && currentActions.length > 0 && currentActions[0] !== null) {
            let initialTextValue = {};
            // eslint-disable-next-line
            currentActions.map((a) => {
                if (typeof a === "undefined") {
                    return null;
                }

                setSelectedAction(a);
                setDestinationType(a && a.type === "section" && a.value === "landing" ? "landing" : a.type);
                if (a.type === "url") {
                    initialTextValue[a.type] = a.value;
                }
            });

            if (modalActions) {
                dispatch(changeActionValues({ "action-destination": currentActions[0] }));
            }

            setInputTextValue(initialTextValue);
        }
        if (actions?.content) {
            getScreens();
        }
        if (hasActionChannel) {
            getTvChannels();
        }

        if (hasActionVendureSales) {
            getVendureShops();
        }
        if (hasActionLegacySales) {
            getRoomShops();
        }

        if (hasMaps && Session.develMode()) {
            getMaps();
        }

        getCustomURLTags();
        setButtonApplyOnClick(true);
        setReady(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ready) {
            setLoading(
                loadingScreens ||
                    loadingRoomShops ||
                    loadingTvChannels ||
                    loadingURLTags ||
                    loadingVendureShops ||
                    loadingCommonZones ||
                    loadingMapsData ||
                    (actions?.content && !screensData && !errorScreens) ||
                    (hasActionChannel && !tvChannelsData && !errorTvChannels) ||
                    (hasActionLegacySales && !roomShopsData && !errorRoomShops) ||
                    (hasActionVendureSales && !vendureShopsData && !errorVendureShops) ||
                    (hasMaps && !mapsData && !errorMapsData) ||
                    !customURLTags
            );
        }

        [(errorScreens, errorRoomShops, errorURLTags, errorTvChannels, errorVendureShops, errorMapsData)].forEach(
            (e) => {
                if (e) {
                    console.error(e);
                }
            }
        );
        // eslint-disable-next-line
    }, [
        loadingScreens,
        loadingRoomShops,
        loadingTvChannels,
        loadingURLTags,
        loadingVendureShops,
        loadingCommonZones,
        errorCommonZones,
        errorScreens,
        errorRoomShops,
        errorURLTags,
        errorTvChannels,
        errorVendureShops,
        roomShopsData,
        tvChannelsData,
        screensData,
        customURLTags,
        errorMapsData,
        loadingMapsData,
    ]);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    return (
        <Settings toolbarID={toolbarID} widgetTypeID={widgetTypeID} type={type}>
            <Settings.Group
                title={modalActions ? "" : title ? title : t("destination")}
                adjust={`${modalActions ? "p-0" : ""}`}
            >
                {loading ? (
                    <div className="w-20 mx-auto" style={{ zoom: 0.5 }}>
                        <UseLoading adjust="contents" />
                    </div>
                ) : (
                    <>
                        {allowDisabled ? (
                            <Settings.Radio
                                radioID={`disabled_radio_${widgetID}`}
                                id={`${sectionName}-disabled`}
                                title={t("disabled")}
                                value={"disabled"}
                                selected={destinationType}
                                action={(value) => saveAction(value, null)}
                            />
                        ) : null}
                        {destinations?.map((type) => {
                            return actions?.[type.id] ? (
                                type.input === "select" ? (
                                    <Settings.Radio
                                        radioID={`${type.id}_radio_${widgetID}`}
                                        id={`${sectionName}-${type.id}`}
                                        title={type.name}
                                        value={type.id}
                                        selected={destinationType}
                                        action={(value) =>
                                            destinationType !== value
                                                ? saveAction(value, lastTypeValue ? lastTypeValue[type.id] : null)
                                                : null
                                        }
                                    >
                                        {loading ? null : (
                                            <UseSelectWithSearch
                                                data={{
                                                    categoryCheck: false,
                                                    oneSelected: true,
                                                    noSelect: true,
                                                    disabled: destinationType !== type.id,
                                                    name: `${type.id}_${widgetID}`,
                                                    optionData: getOptions(type.id),
                                                    sort: type.id === "section" ? false : true,
                                                    selectedIds:
                                                        selectedAction && selectedAction.type === type.id
                                                            ? [selectedAction.value]
                                                            : null,
                                                    onChange: (value) => saveAction(type.id, value),
                                                    hideSearch:
                                                        type.id !== "tvchannel" &&
                                                        type.id !== "section" &&
                                                        type.id !== "content",
                                                    selectPlaceHolder: type.placeholder,
                                                    optionsAdjust: "mt-11",
                                                    fixed: false,
                                                    parentName: `widgetOptionContainer_${widgetID}`,
                                                    id: `${sectionName}-${type.id}-select`,
                                                }}
                                                overlapOptions={
                                                    modalActions === true
                                                        ? false
                                                        : type.id === "section" ||
                                                          type.id === "content" ||
                                                          type.id === "qr" ||
                                                          type.id === "tvchannel"
                                                        ? true
                                                        : false
                                                }
                                                widgetID={widgetID}
                                                radioName={type.id}
                                            />
                                        )}
                                    </Settings.Radio>
                                ) : type.input === "text" ? (
                                    <Settings.Radio
                                        radioID={`${type.id}_radio_${widgetID}`}
                                        id={`${sectionName}-${type.id}`}
                                        title={type.name}
                                        value={type.id}
                                        selected={destinationType}
                                        action={(value) => (destinationType !== value ? saveAction(value, null) : null)}
                                    >
                                        <div className="flex flex-col items-end w-full">
                                            <div className="w-full mb-2">
                                                <input
                                                    type="text"
                                                    disabled={destinationType !== type.id}
                                                    id={`${type.id}_input_${widgetID}`}
                                                    placeholder={t("paste here") + "..."}
                                                    className={
                                                        (buttonApplyOnClick === true && checkURL(type.id)
                                                            ? "w-11/12"
                                                            : " w-full") +
                                                        " bg-gray-200 rounded px-4 py-2 " +
                                                        (destinationType !== type.id ||
                                                        !inputTextValue[type.id] ||
                                                        checkURL(type.id)
                                                            ? ""
                                                            : "border border-red-100")
                                                    }
                                                    value={inputTextValue ? inputTextValue[type.id] || "" : ""}
                                                    onChange={(e) => {
                                                        if (e?.currentTarget) {
                                                            let v = { ...inputTextValue };
                                                            v[type.id] = e.currentTarget.value;
                                                            setInputTextValue(v);
                                                        }
                                                        setButtonApplyOnClick(false);
                                                    }}
                                                />
                                                {buttonApplyOnClick === true && checkURL(type.id) ? (
                                                    <big>
                                                        <UseIcon
                                                            icon={{ name: "external-link" }}
                                                            adjust={`inline-block text-2xl cursor-pointer text-zafiro-600 z-100 align-text-bottom pl-1`}
                                                            onclickCallback={() => window.open(inputTextValue[type.id])}
                                                        />
                                                    </big>
                                                ) : null}
                                            </div>
                                            {destinationType === type?.id ? (
                                                <div>
                                                    <UseButton
                                                        buttonName={getVal(type) ? "update" : "apply"}
                                                        buttonColor={"btn-blue"}
                                                        disabled={
                                                            getVal(type) === inputTextValue[type.id] ||
                                                            !checkURL(type.id) ||
                                                            !inputTextValue[type.id]
                                                        }
                                                        adjust="inline-flex float-right"
                                                        action={() => {
                                                            setButtonApplyOnClick(true);
                                                            saveAction(type.id, inputTextValue[type.id]);
                                                        }}
                                                        id={`${getVal(type) ? "update" : "apply"}-button`}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                    </Settings.Radio>
                                ) : type.input === "radio" ? (
                                    <Settings.Radio
                                        radioID={`${type.id}_radio_${widgetID}`}
                                        id={`${sectionName}-${type.id}`}
                                        title={type.name}
                                        value={type.id}
                                        selected={destinationType}
                                        action={(value) => (destinationType !== value ? saveAction(value, null) : null)}
                                    />
                                ) : type.input === "asset" ? (
                                    <Settings.Radio
                                        radioID={`${type.id}_radio_${widgetID}`}
                                        id={`${sectionName}-${type.id}`}
                                        title={t("media file")}
                                        value={"library"}
                                        selected={destinationType}
                                        action={(value) => saveAction(value, null)}
                                    >
                                        <ChooseAsset
                                            modalContentId={modalContentId}
                                            isForModalActions={modalActions}
                                            modalValues={modalValues}
                                            destTypes={isDeviceTV ? ["asset", "video"] : null}
                                            buttonId={`${sectionName}-${type.id}-choose`}
                                        />
                                    </Settings.Radio>
                                ) : null
                            ) : null;
                        })}
                    </>
                )}
            </Settings.Group>
        </Settings>
    );
};

export default Actions;
