import { Widget } from "zrender/widgets.definitions";

export const MIN_WIDTH = 2; // Minimum width for a widget
export const MIN_HEIGHT = 2; // Minimum height for a widget

// Device is the type of device that displays the screen
export const DEVICE = {
    TYPE: {
        TV: "TV",
        MOBILE: "Mobile",
        DESKTOP: "Desktop",
    },
};

// Welcome sequences are the different types of content that can be displayed in the welcome screen
export const WELCOME_SEQUENCE = {
    WELCOME_LANG: "LANGSELECTION",
    WELCOME_VIDEO: "VIDEO",
    WELCOME_WIDGETS: "WIDGETS",
};

// Screen is the type of screen that is presented to the user
// Note: some screens can be configured to multiple devices, but not all screens are available for all devices
export const SCREEN = {
    TYPE: {
        TV: "TV",
        WELCOME: "WELCOME",
        SALES: "SALES",
        SALESMOBILE: "SALESMOBILE",
        EXPRESSCHECKOUT: "EXPRESSCHECKOUT",
        MOBILE: "MOBILE",
        CCINSTRUCTION: "CCINSTRUCTION",
        CCPAIRINGSUCCESSFUL: "CCPAIRINGSUCCESSFUL",
        GENERAL: "GENERAL",
        CAST: "CAST",
        PORTAL: "PORTAL",
        WIFI: "WIFI",
        MAPS: "MAPS",
        MAPSMOBILE: "MAPSMOBILE",
        ...WELCOME_SEQUENCE,
    },
    // TODO - Review this LTYPE data (evaluatin __typename from graphql response)
    LTYPE: {
        LANDING: "ltLanding",
        WELCOME: "ltWelcome",
        SIMPLECONTENT: "SimpleContent",
        SUBDIR: "stSubDir",
        WELCOME_CONTENT: "WelcomeScreenContentGET",
    },
};

export const WELCOME_SEQUENCES = Object.values(WELCOME_SEQUENCE).map((type) => ({
    type,
    icon: (() => {
        switch (type) {
            case WELCOME_SEQUENCE.WELCOME_LANG:
                return "icon-information";
            case WELCOME_SEQUENCE.WELCOME_VIDEO:
                return "icon-play2";
            case WELCOME_SEQUENCE.WELCOME_WIDGETS:
                return "icon-areas-activities";
            default:
                return "icon-warning";
        }
    })(),
    translate: (() => {
        switch (type) {
            case WELCOME_SEQUENCE.WELCOME_LANG:
                return "welcomeScreen-lang";
            case WELCOME_SEQUENCE.WELCOME_VIDEO:
                return "welcomeScreen-video";
            case WELCOME_SEQUENCE.WELCOME_WIDGETS:
                return "welcomeScreen-widgets";
            default:
                return type;
        }
    })(),
}));

export const WIDGET = {
    TYPE: {
        // Implemented in zrender
        TOPBAR: Widget.TopBar,
        WIFILOGIN: Widget.WiFiLogin,
        IMAGE: Widget.Image,
        LOGO: Widget.Logo,
        SHOPITEM: Widget.ShopItem,
        SHOPORDERITEM: Widget.ShopOrderItem,
        SLIDER: Widget.Slider,
        // Not implemented in zrender yet
        ROOMS: Widget.Rooms,
        TVMENU: Widget.TVMenu,
        TVMENUNAV: Widget.TVMenuNav,
        VIDEO: Widget.Video,
        WEATHER: Widget.Weather,
        ALARM: Widget.Alarm,
        BUTTON: Widget.Button,
        CHATBUTTON: Widget.ChatButton,
        CALENDAR: Widget.Calendar,
        CAROUSEL: Widget.Carousel,
        CLOCK: Widget.Clock,
        CONTAINER: Widget.Container,
        HTML: Widget.HTML,
        ICON: Widget.Icon,
        IFRAME: Widget.iFrame,
        LANGSELECTION: Widget.LangSelection,
        MESSAGES: Widget.Messages,
        QR: Widget.QR,
        TEXT: Widget.Text,
    },
};

export const WIDGET_TOOLBAR = {
    DEBUG: "debug",
    NAVIGATION: "navigation",
    SETTINGS: "settings",
    STYLE: "style",
    LAYOUT: "layout",
    ACTIONS: "actions",
    INFO: "info",
    CLONE: "clone",
    DELETE: "delete",
};

export const getWidgetToolbarIcon = (type) => {
    switch (type) {
        case WIDGET_TOOLBAR.NAVIGATION:
            return "widget-navigation";
        case WIDGET_TOOLBAR.SETTINGS:
            return "settings";
        case WIDGET_TOOLBAR.STYLE:
            return "color";
        case WIDGET_TOOLBAR.LAYOUT:
            return "layout";
        case WIDGET_TOOLBAR.ACTIONS:
            return "action";
        case WIDGET_TOOLBAR.INFO:
            return "info";
        case WIDGET_TOOLBAR.CLONE:
            return "clone";
        case WIDGET_TOOLBAR.DELETE:
            return "delete-editor";
        case WIDGET_TOOLBAR.DEBUG:
            return "superuser";
        default:
            return "warning";
    }
};

export const CONTENT_SCREEN_TOOLBAR = [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE];

export const SCREEN_CONFIGURATIONS = {
    [WIDGET_TOOLBAR.SETTINGS]: [
        [
            ["data", "images"],
            ["data", "music"],
        ],
    ],
    [WIDGET_TOOLBAR.STYLE]: [
        [
            ["style", "bgColor"],
            ["style", "opacity"],
        ],
    ],
};

export const WIDGETS = {
    ALARM: {
        name: "alarm",
        icon: "alarm",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.TV],
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
        toolbar: [WIDGET_TOOLBAR.LAYOUT, WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.LAYOUT]: [
                ["text", "icon", "alignment"],
                ["textStyle", "showIcon"],
            ],
            [WIDGET_TOOLBAR.SETTINGS]: [["image", "imageAdjust"]],
            [WIDGET_TOOLBAR.STYLE]: [["colorsWithIcon", "border", "corners"], ["colorsWithIcon"]],
        },
    },
    BUTTON: {
        name: "button",
        icon: "button-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        ...Object.values(WELCOME_SEQUENCE),
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [
            WIDGET_TOOLBAR.LAYOUT,
            WIDGET_TOOLBAR.SETTINGS,
            WIDGET_TOOLBAR.STYLE,
            WIDGET_TOOLBAR.ACTIONS,
            WIDGET_TOOLBAR.DELETE,
        ],
        configurations: {
            [WIDGET_TOOLBAR.LAYOUT]: [["text", "icon", "alignment"]],
            [WIDGET_TOOLBAR.SETTINGS]: [["image", "imageAdjust"]],
            [WIDGET_TOOLBAR.STYLE]: [
                [
                    "colorsWithIcon",
                    ["style", "disabledBgColor"],
                    ["style", "disabledFgColor"],
                    "border",
                    "corners",
                    ["style", "disabledBorderColor"],
                    ["style", "disabledBorderWidth"],
                ],
            ],
        },
    },
    CALENDAR: {
        name: "calendar",
        icon: "booking",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: { [DEVICE.TYPE.TV]: { w: 16, h: 13 } },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["colors", "border", "corners"]],
        },
    },
    CAROUSEL: {
        name: "carousel",
        icon: "images",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["border", "corners"]],
        },
    },
    CHATBUTTON: {
        name: "chat",
        icon: "language",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
        toolbar: [
            WIDGET_TOOLBAR.LAYOUT,
            WIDGET_TOOLBAR.SETTINGS,
            WIDGET_TOOLBAR.STYLE,
            WIDGET_TOOLBAR.INFO,
            WIDGET_TOOLBAR.DELETE,
        ],
    },
    CLOCK: {
        name: "clock",
        icon: "clock",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        layout: { [DEVICE.TYPE.TV]: { w: 10, h: 6 } },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["colorsWithIcon", "border", "corners"]],
        },
    },
    CONTAINER: {
        name: "container",
        icon: "checkbox-unchecked",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 13 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 13 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 8 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.SETTINGS]: [["style", "images"]],
            [WIDGET_TOOLBAR.STYLE]: [["style", "bgColor"], "border", "corners"],
        },
    },
    HTML: {
        name: "HTML",
        icon: "embed2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["border", "corners"]],
        },
    },
    ICON: {
        name: "icon",
        icon: "add_circle",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 4, h: 4 },
            [DEVICE.TYPE.DESKTOP]: { w: 4, h: 4 },
            [DEVICE.TYPE.MOBILE]: { w: 2, h: 2 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.SETTINGS]: [
                [
                    ["data", "icon"],
                    ["data", "lib"],
                    ["style", "size"],
                ],
            ],
            [WIDGET_TOOLBAR.STYLE]: [["colors", "border", "corners"]],
        },
    },
    IFRAME: {
        name: "iframe",
        icon: "embed",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["border", "corners"]],
        },
    },
    IMAGE: {
        name: "image",
        icon: "image",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.SETTINGS]: [["image", "imageAdjust"]],
            [WIDGET_TOOLBAR.STYLE]: [[["style", "bgColor"], ["style", "imageOpacity"], "border", "corners"]],
        },
    },
    LANGSELECTION: {
        name: "langselector",
        icon: "language",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE],
    },
    LOGO: {
        name: "logo",
        icon: "logo",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
            [DEVICE.TYPE.DESKTOP]: { w: 10, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 7, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["border", "corners"]],
        },
    },
    MESSAGES: {
        name: "messages",
        icon: "messaging-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 10, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 7, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        ...Object.values(WELCOME_SEQUENCE),
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
        toolbar: [WIDGET_TOOLBAR.LAYOUT, WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.LAYOUT]: [["text", "icon", "alignment"]],
            [WIDGET_TOOLBAR.SETTINGS]: [["image", "imageAdjust"]],
            [WIDGET_TOOLBAR.STYLE]: [["colorsWithIcon", "border", "corners"]],
        },
    },
    QR: {
        name: "qr code",
        icon: "qrcode",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 7, h: 7 },
            [DEVICE.TYPE.DESKTOP]: { w: 7, h: 7 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV, DEVICE.TYPE.DESKTOP],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.SETTINGS]: [
                [
                    ["data", "predefined"],
                    ["data", "url"],
                ],
            ],
            [WIDGET_TOOLBAR.STYLE]: [["border", "corners"]],
        },
    },
    ROOMS: {
        name: "add room",
        icon: "rooms",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        layout: { [DEVICE.TYPE.MOBILE]: { w: 2, h: 12 } },
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.INFO],
    },
    SHOPITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    SHOPORDERITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
    },
    SLIDER: {
        name: "slider",
        icon: "widget-slider",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.TV, SCREEN.TYPE.WELCOME_WIDGETS, SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.TV, DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.NAVIGATION, WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["colorsWithBullets", "border", "corners"]],
        },
    },
    TEXT: {
        name: "text",
        icon: "font-size",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 6 },
            [DEVICE.TYPE.DESKTOP]: { w: 16, h: 6 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 3 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [[["style", "bgColor"], "border", "corners", ["style", "margin"]]],
        },
    },
    TOPBAR: {
        name: "screen title",
        icon: "font-size",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        layout: {
            [DEVICE.TYPE.MOBILE]: { w: 2, h: 12 },
        },
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.STYLE],
    },
    TVMENU: {
        name: "Menu",
        hidden: true,
        isResizable: true,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        layout: { [DEVICE.TYPE.TV]: { w: 36, h: 6 } },
        availability: {
            screenTypes: [SCREEN.TYPE.TV],
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE],
        configurations: {
            [WIDGET_TOOLBAR.SETTINGS]: [
                [
                    ["data", "menuType"],
                    ["data", "buttonRC"],
                    ["data", "blurMainScreen"],
                ],
            ],
            [WIDGET_TOOLBAR.STYLE]: [["colorsWithIcon", "corners"]],
        },
    },
    TVMENUNAV: {
        name: "tvmenunav",
        icon: "navigation-horizontal",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 24, h: 6 },
        },
        availability: {
            screenTypes: [SCREEN.TYPE.TV],
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
            tvMenuExclusive: true,
        },
        toolbar: [WIDGET_TOOLBAR.NAVIGATION, WIDGET_TOOLBAR.LAYOUT, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
    },
    VIDEO: {
        name: "video",
        icon: "play2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 16, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 6 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [["border"]],
        },
    },
    WEATHER: {
        name: "weather",
        icon: "sun",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        layout: {
            [DEVICE.TYPE.TV]: { w: 24, h: 9 },
            [DEVICE.TYPE.MOBILE]: { w: 10, h: 4 },
        },
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE, WIDGET_TOOLBAR.ACTIONS, WIDGET_TOOLBAR.DELETE],
        configurations: {
            [WIDGET_TOOLBAR.STYLE]: [[["style", "bgColor"], "border", "corners"]],
        },
    },
    WIFILOGIN: {
        name: "loginsection",
        icon: "Wifi-access",
        hidden: true,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [],
            deviceTypes: [],
            tvMenu: false,
        },
        toolbar: [WIDGET_TOOLBAR.SETTINGS, WIDGET_TOOLBAR.STYLE],
    },
};

export const getWidgets = ({ deviceType, screenType, tvmenu }) => {
    return WIDGETS
        ? Object.keys(WIDGETS)
              .map((type) => {
                  const widget = WIDGETS[type];
                  return {
                      type: WIDGET.TYPE[type],
                      ...widget,
                  };
              })
              .filter((w) => {
                  const requireDevice = w?.availability?.deviceTypes;
                  const requireScreen = w?.availability?.screenTypes;

                  const checkHidden = !w?.hidden;
                  const checkDevice = !requireDevice || !deviceType || requireDevice.includes(deviceType);
                  const checkScreen = !requireScreen || !screenType || requireScreen.includes(screenType);
                  const checkTvMenu = !tvmenu || w?.availability?.tvMenu === tvmenu;

                  if (w?.availability?.tvMenuExclusive && !tvmenu) {
                      return false;
                  }

                  return checkHidden && checkDevice && checkScreen && checkTvMenu;
              })
        : [];
};
