import React, { useContext, useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { GlobalContext } from "contexts/Global";

import Button from "components/Button";
import Icon from "components/Icon";
import Loading from "components/Loading";
import ErrorInfo from "components/ErrorInfo";
import Warning from "components/Warning";

/**
 * This component is a modal
 * @param {JSX.Element} children The content of the modal
 * @param {string} id The id of the modal (required)
 * @param {string} title The title of the modal
 * @param {JSX.Element} footer The footer of the modal
 * @param {number} width The width of the modal (style attribute value)
 * @param {number} minWidth The minimum width of the modal (style attribute value)
 * @param {boolean} maxWidth The maximum width of the modal (style attribute value)
 * @param {function} onClose The function to call when the modal is closed
 * @param {object} onKeyPress The key press events to handle
 * @param {string} className The class name of the modal
 * @param {boolean} loading The flag to hide content between loading
 * @param {string} error The error information to show
 * @param {boolean} internal This flag indicates that the modal is exclusive for ES Staff
 * @param {boolean} overlay The flag to show the overlay (default: true)
 * @param {string} type The size of the modal (medium, large, fullHeight)
 * @returns {JSX.Element} The UI of the component
 * @example <Modal title="Title" buttons={<Button>Close</Button>} onClose={close}>Content</Modal>
 */

export const ModalSize = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
};

const Modal = forwardRef(
    (
        {
            children,
            id,
            title,
            footer,
            width,
            minWidth,
            maxWidth,
            onClose,
            onKeyPress,
            className,
            loading,
            error,
            internal,
            overlay = true,
            fullHeight = false,
            size,
        },
        ref
    ) => {
        const { highlightComponent } = useContext(GlobalContext);

        const [forceLoading, setForceLoading] = useState(null);

        const { close: modalClose } = useModal({ onKeyPress });

        if (!id) {
            console.warn("The modal must have an id");
        }

        const close = () => {
            if (onClose) {
                onClose();
            } else if (modalClose) {
                modalClose();
            }
        };

        useImperativeHandle(ref, () => ({
            close,
            setLoading: setForceLoading,
        }));

        const titleElement =
            title && typeof title === "string" ? (
                <h2 id="modal-title" className={`section-title ${children || footer ? "mb-5" : ""}`}>
                    {title}
                </h2>
            ) : (
                title
            );

        const overlayClass = classNames({
            "fixed inset-0": true,
            "bg-black bg-opacity-50": overlay,
        });

        const modalClass = classNames({
            "bg-white rounded-lg shadow-lg border border-gray-200 overflow-auto": true,
            "p-10": !className?.match(/p-\d/),
            "min-w-140 max-w-7xl max-h-80": true,
            "w-1/4": size === ModalSize.SMALL,
            "w-1/2": size === ModalSize.MEDIUM,
            "w-full": size === ModalSize.LARGE,
            "h-full ": fullHeight,
            "highlight-component": highlightComponent,
            [className]: true,
        });

        const isLoading = forceLoading !== null ? forceLoading : loading;

        return (
            <div
                id={id ? `modal-overlay-${id}` : null}
                onClick={close}
                className={overlayClass}
                style={{ zIndex: 998 }}
            >
                <div className="absolute inset-0 flex justify-center items-center mx-auto" style={{ maxWidth: "95vw" }}>
                    <div
                        id={id ? `modal-body-${id}` : null}
                        onClick={(e) => e.stopPropagation()}
                        className={modalClass}
                        style={{ width, minWidth, maxWidth }}
                    >
                        {!size ? (
                            <Warning id="modal-warn-dev" title="Refactor needs" design="refactor">
                                Please, specify a size for this modal
                            </Warning>
                        ) : null}
                        {isLoading || error ? (
                            <>
                                {error ? titleElement : null}
                                {error ? <ErrorInfo className="mt-3">{error}</ErrorInfo> : <Loading adjust="full" />}
                            </>
                        ) : null}
                        <div
                            className={
                                isLoading || error
                                    ? "opacity-0 pointer-events-none flex flex-col h-full"
                                    : "flex flex-col h-full"
                            }
                        >
                            {titleElement}
                            <div className="flex-grow min-h-5 h-full">{children}</div>
                            {footer ? (
                                <div id="modal-buttons" className={`pt-5 text-center space-x-3 flex justify-end`}>
                                    {footer}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {internal ? (
                    <Icon
                        type="superuser"
                        style={{
                            pointerEvents: "none",
                            position: "absolute",
                            color: "white",
                            lineHeight: "1em",
                            fontSize: "5rem",
                            bottom: "1rem",
                            left: "calc(50vw - 2.5rem)",
                            opacity: 0.05,
                        }}
                    />
                ) : null}
            </div>
        );
    }
);

/**
 * This hook is used to open and close modals
 */
export const useModal = ({ onKeyPress } = {}) => {
    const { openModal, closeModal, closeAllModals, setOnKeyPress } = useContext(GlobalContext);

    useEffect(() => {
        if (onKeyPress) {
            setOnKeyPress(onKeyPress);
        }
    }, []);

    return {
        open: openModal,
        close: closeModal,
        closeAllModals,
    };
};

/**
 * This hook is used to show alerts to the user
 */
export const useAlert = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    return {
        open: (msg, title, { size } = {}) => {
            open(
                <Modal
                    id="alert"
                    title={title}
                    footer={
                        <Button id="alert-accept" design="blue" onClick={close}>
                            {t("accept")}
                        </Button>
                    }
                    size={size || ModalSize.SMALL}
                    className="p-5"
                    onKeyPress={{ Enter: close }}
                >
                    <div className="text-lg text-left">{msg}</div>
                </Modal>
            );
        },
        close,
    };
};

/**
 * This hook is used to show confirmations to the user
 */
export const useConfirm = () => {
    const { t } = useTranslation();
    const { open, close } = useModal();
    return {
        open: ({ id, message, warning, title, onConfirm, confirmText, sensitive, size, className }) => {
            const confirm = () => {
                close();
                if (onConfirm) {
                    onConfirm();
                }
            };

            open(
                <Modal
                    id={id}
                    title={title}
                    className={className}
                    footer={
                        <>
                            <Button id="confirm-cancel" design="basic" onClick={close}>
                                {t("cancel")}
                            </Button>
                            <Button id="confirm-accept" design={sensitive ? "red" : "blue"} onClick={confirm}>
                                {confirmText || t("confirm")}
                            </Button>
                        </>
                    }
                    onKeyPress={{
                        Enter: !sensitive ? confirm : null,
                        Delete: sensitive ? confirm : null,
                    }}
                    size={size || ModalSize.SMALL}
                >
                    <div className="text-lg text-left">{message}</div>
                    {warning ? (
                        <div className="text-lg text-left my-5">
                            <Icon type="warning" size={2} text={warning} />
                        </div>
                    ) : null}
                </Modal>
            );
        },
        close,
    };
};

/**
 * This component is a container for modals that can be opened and closed dynamically by the user
 * @param {Array} modals The list of modals to show
 * @param {function} onClose The function to call when a modal is closed
 */
export const ModalsContainer = ({ modals, onClose, onKeyPress }) => {
    const currentModal = modals ? modals[modals.length - 1] : null;
    const showCurrentAlone = currentModal?.alone || currentModal?.props?.alone;
    const showCurrentOverlay = currentModal?.overlay ?? currentModal?.props?.overlay ?? true;
    const customKeyPress = currentModal?.onKeyPress || currentModal?.props?.onKeyPress || onKeyPress;

    const close = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleKeyPress = (e) => {
        const key = e?.key;
        if (customKeyPress && key in customKeyPress) {
            if (customKeyPress[key]) {
                if (typeof customKeyPress[key] === "function") {
                    customKeyPress[key]();
                } else {
                    throw new Error("The onKeyPress value must be a function");
                }
            }
        } else if (key === "Escape") {
            close();
        }
    };

    // Close the modal when the escape key is pressed
    useEffect(() => {
        if (currentModal) {
            // Add the event listener to the document only when a modal is open
            document.addEventListener("keydown", handleKeyPress);

            // Remove the focus from the currently focused element to avoid unwanted events
            const focusedElement = document.activeElement;
            if (typeof focusedElement?.blur === "function") {
                focusedElement.blur();
            }
        }

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [currentModal, handleKeyPress]);

    if (!modals?.length) {
        return null;
    }

    return modals.map((modal, index) => {
        // Skip all modals except the current modal if the config is set to show the current alone
        const isCurrent = index === modals.length - 1;
        if (showCurrentAlone && !isCurrent) {
            return null;
        }

        const key = modal?.id || modal?.props?.id || `modal-${index}`;

        return parseModalInput(modal, {
            key,
            overlay: isCurrent && showCurrentOverlay !== false,
            onClose: close,
        });
    });
};

const parseModalInput = (input, props) => {
    const onClose = input?.onClose || input?.props?.onClose;
    const overlay = input?.overlay ?? input?.props?.overlay ?? true;
    const inputProps = {
        ...props,
        overlay: overlay && props?.overlay,
        onClose: (...args) => {
            if (props?.onClose) {
                props.onClose(...args);
            }
            if (onClose) {
                onClose(...args);
            }
        },
    };

    if (React.isValidElement(input)) {
        return React.cloneElement(input, inputProps);
    }

    if (typeof input === "object") {
        return <Modal {...input} {...inputProps} />;
    }

    if (typeof input === "string") {
        return <Modal title={input} {...inputProps} />;
    }

    throw new Error("Invalid modal type", input);
};

export default Modal;
