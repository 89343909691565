import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import { useHomePage } from "../../graphql/useTickerTapes";
import { useListBanners } from "../../graphql/useBanners";
import Button from "components/Button";
import { BANNER_SIZES, POSITIONS } from "../../constants";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import classNames from "classnames";
import { DestinationType } from "../modalDestination/Destination/utils/utils";

const ButtonsSection = ({ containerRef, contentAction, t }) => {
    const buttonsRef = useRef();

    if (window.document.getElementById("modal-preview-banners-buttons-container")) {
        if (buttonsRef?.current?.offsetWidth >= containerRef?.current?.offsetWidth * 0.25) {
            window.document.getElementById("modal-preview-banners-buttons-container").style.flexDirection = "row";
        }
    }
    return (
        <div
            ref={buttonsRef}
            id="modal-preview-banners-buttons-container"
            className={classNames({
                " flex items-center justify-center bg-white rounded-sm": true,
            })}
            style={{
                height: "fit-content",
                gridRow: 2,
                flexDirection: "column",
                fontSize: "clamp(14px, 1.5vw, 15px)",
                padding: `${containerRef.current.offsetWidth * 0.0125}px`,
                gap: `${containerRef.current.offsetWidth * 0.0125}px`,
            }}
        >
            <Button
                id={"close-preview"}
                style={{
                    dislay: "block",
                    minWidth: `${containerRef?.current?.offsetWidth * 0.1167}px`,
                }}
                design={!contentAction ? "blue-outline" : "blue"}
                className={"w-full"}
            >
                {t("close")}
            </Button>
            {!contentAction ? (
                <Button
                    id={"show-more-preview"}
                    style={{
                        minWidth: `${containerRef?.current?.offsetWidth * 0.1167}px`,
                    }}
                    design={"blue"}
                    className={"w-full"}
                >
                    {t("show-more")}
                </Button>
            ) : null}
        </div>
    );
};

const ModalPreviewBanners = ({ id, close }) => {
    const { t } = useTranslation();
    const containerRef = useRef();

    const [loading, setLoading] = useState(false);
    const { getBanners, banners, idDesignApplied } = useListBanners();
    const {
        fetch: fetchHomePage,
        backgroundImage,
        backgroundColor,
        backgroundImageExternal,
    } = useHomePage({
        onCompleted: () => {
            setLoading(false);
        },
    });

    const fetchBanners = () => {
        getBanners({ variables: { id } });
    };

    useEffect(() => {
        setLoading(true);
        fetchBanners();
    }, []);

    useEffect(() => {
        if (idDesignApplied) {
            fetchHomePage({ id: idDesignApplied });
        }
    }, [idDesignApplied]);

    const handleCloseClick = () => {
        if (typeof close === "function") {
            close();
        }
    };
    const size = banners?.[0]?.displaySize;
    const bannerImage = banners?.[0]?.contentImage?.libraryRef || banners?.[0]?.contentImage?.externalUrl;
    const contentAction =
        banners?.[0]?.contentAction?.type === DestinationType.DISABLED || !banners?.[0]?.contentAction;

    return (
        <Modal
            title={banners?.[0]?.name && !loading ? `${banners?.[0]?.name} - ${t("preview")}` : ""}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
            size={"large"}
            loading={loading}
        >
            <div className="flex justify-center">
                <div
                    id="modal-preview-banners"
                    ref={containerRef}
                    className="relative w-full"
                    style={{
                        backgroundColor: backgroundColor ?? "",
                        aspectRatio: "16/9",
                    }}
                >
                    {(backgroundImage || backgroundImageExternal) && (
                        <img
                            id={"modal-preview-banners-background"}
                            style={{
                                aspectRatio: "16/9",
                            }}
                            src={backgroundImage ? Session.getDasUrl(`${backgroundImage}`) : backgroundImageExternal}
                            className="absolute top-0 left-0 w-full h-full object-cover opacity-75"
                        />
                    )}
                    {bannerImage && (
                        <div
                            className="fadein absolute grid grid-cols-1 justify-center items-center bg-white rounded-md"
                            style={{
                                ...POSITIONS[banners?.[0]?.displayPosition],
                                zIndex: 999,
                                maxWidth: `${containerRef.current.offsetWidth * BANNER_SIZES[size].maxWidth}px`,
                                height: "fit-content",
                                width: "fit-content",
                            }}
                        >
                            <div
                                style={{
                                    gridRow: 1,
                                    maxWidth: `${containerRef.current.offsetWidth * BANNER_SIZES[size].maxWidth}px`,
                                    height: `${containerRef.current.offsetHeight * BANNER_SIZES[size].height}px`,
                                }}
                                className=" flex justify-center items-center bg-black rounded-t-md"
                            >
                                <img
                                    style={{
                                        height: "100%",
                                        width: "auto",
                                        display: "block",
                                        backgroundColor: "white",
                                    }}
                                    src={
                                        banners?.[0]?.contentImage?.libraryRef
                                            ? Session.getDasUrl(`${bannerImage}`)
                                            : bannerImage
                                    }
                                />
                            </div>
                            <ButtonsSection
                                containerRef={containerRef}
                                contentAction={contentAction}
                                t={t}
                            ></ButtonsSection>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export const useModalPreviewBannners = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalPreviewBanners {...newProps} />);
        },
    };
};
