import Button from "components/Button";
import DateInput from "components/Inputs/DateInput";
import { HourInput } from "components/Inputs/HourInput";
import Loading from "components/Loading";
import { useModal } from "components/Modal";
import Select from "components/Select";
import { useSelectContent } from "hooks/GraphqlCalls/Customers/useSelectContentAlarm";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const NewAlarm = ({ refetch, setLoadingRefresh }) => {
    const [recipient, setRecipient] = useState("room");
    const [roomOrGuest, setRoomOrGuest] = useState(null);
    const [alarmType, setAlarmType] = useState(null);
    const [roomOrGuestValidation, setRoomOrGuestValidation] = useState({});
    const [alarmTypeValidation, setAlarmTypeValidation] = useState({});
    const [tvChannelValidation, setTvChannelValidation] = useState({});
    const [hourValidation, setHourValidation] = useState(true);
    const [tvChannel, setTvChannel] = useState(null);

    const initialDate = new Date(new window.ZDate().toISOString());
    const currentHour = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    const currentDay = new Date().toISOString().split("T")[0];
    const [finalDate, setFinalDate] = useState(initialDate);
    const [date, setDate] = useState(initialDate);
    const [hour, setHour] = useState(new window.ZDate(initialDate)?.toLocaleTimeString().slice(0, 5));

    const { t } = useTranslation();
    const { rooms, guests, loading, createAlarm } = useSelectContent();
    const [selectOptions, setSelectOptions] = useState(rooms?.length > 0 && rooms);
    const { channels } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);

    const hasChannels = permissions?.product?.channels;
    const hasGuests = permissions?.product?.guest;

    const { close } = useModal();

    const getChannels = (channels) => {
        return channels.map((channel) => {
            return {
                id: channel.id,
                value: channel.name,
                label: channel.name,
            };
        });
    };

    useEffect(() => {
        const finalDate = date ? new window.ZDate(date?.toUTCString()) : null;
        if (finalDate && hour) {
            const [hours, minutes] = hour.split(":").map(Number);
            finalDate.setHours(hours || 0);
            finalDate.setMinutes(minutes || 0);
        }
        setFinalDate(finalDate);
    }, [date, hour]);

    useEffect(() => {
        if (recipient === "room" && rooms?.length > 0) {
            setSelectOptions(rooms);
        } else if (recipient === "guest" && guests?.length > 0) {
            setSelectOptions(guests);
        }
    }, [recipient, rooms, guests]);

    const handleSave = () => {
        if (
            !roomOrGuest ||
            !alarmType ||
            (alarmType === "TVCHANNEL" && !tvChannel) ||
            ((!hour || hour < currentHour) && currentDay === date.toISOString().split("T")[0])
        ) {
            if (!roomOrGuest) {
                setRoomOrGuestValidation({ validation: "border border-red-100" });
            }
            if (!alarmType) {
                setAlarmTypeValidation({ validation: "border border-red-100" });
            }
            if (alarmType === "TVCHANNEL" && !tvChannel) {
                setTvChannelValidation({ validation: "border border-red-100" });
            }
            if ((!hour || hour < currentHour) && currentDay === date.toISOString().split("T")[0]) {
                setHourValidation(false);
            } else {
                setHourValidation(true);
            }
            toast.error(t("errors-in-red"));
            return;
        } else {
            close();
            createAlarm({
                recipient,
                roomOrGuest,
                alarmType,
                tvChannel,
                isoDate: finalDate?.toISOString(),
            });
            setLoadingRefresh(true);
            setTimeout(() => {
                refetch();
                setLoadingRefresh(false);
            }, 1200);
            toast.success(
                t("toast-alarm", {
                    action: t("{{lowercase, lowercase}}", { lowercase: t("created", { context: "female" }) }),
                })
            );
        }
    };

    useEffect(() => {
        if (roomOrGuest) {
            setRoomOrGuestValidation({});
        }
        if (alarmType) {
            setAlarmTypeValidation({});
        }
        if (tvChannel) {
            setTvChannelValidation({});
        }
        if (hour >= currentHour) {
            setHourValidation(true);
        }
    }, [roomOrGuest, alarmType, tvChannel, hour]);

    const handleSelectId = (value) => {
        const id =
            selectOptions?.length > 0 &&
            (selectOptions[0]?.rooms
                ? selectOptions?.find((option) => option.value === value)?.rooms[0]?.id
                : selectOptions?.find((option) => option.value === value)?.value || null);
        setRoomOrGuest(id);
    };

    const handleSaveTVChannelId = (value) => {
        const id = channels?.find((channel) => channel.name === value)?.id;
        setTvChannel(id);
    };

    return !loading ? (
        <>
            <main>
                <div
                    className="mt-10"
                    dangerouslySetInnerHTML={{
                        __html: hasGuests ? t("new-alarm-warning") : t("no-guest-new-alarm-text"),
                    }}
                ></div>
                {hasGuests && (
                    <div className="my-4">
                        <label className="mb-2">{capitalizeFirst(t("recipient-type"))}</label>
                        <Select
                            id="recipient-type"
                            value={"room"}
                            allowUnselect={false}
                            optionsContainerClass="w-full"
                            options={[
                                { value: "room", label: t("room") },
                                { value: "guest", label: t("guest") },
                            ]}
                            onChange={(value) => setRecipient(value)}
                        />
                    </div>
                )}
                <div className="my-4">
                    <label className="mb-2">{capitalizeFirst(t(recipient))} *</label>
                    <Select
                        id="select-room-or-guest"
                        designClass={roomOrGuestValidation}
                        optionsContainerClass="w-full"
                        allowUnselect={false}
                        placeholder={recipient === "room" ? t("select-room") : t("select guest")}
                        options={selectOptions}
                        onChange={handleSelectId}
                    />
                </div>
                <div className="my-4">
                    <label className="mb-2">{capitalizeFirst(t("set-alarm"))} *</label>
                    <div className="flex items-center gap-4">
                        <div id="date-alarm">
                            <DateInput
                                id="date-alarm"
                                onChange={setDate}
                                value={date}
                                disabledDays={[{ before: new window.ZDate() }]}
                            />
                        </div>
                        <HourInput
                            id={"modal-new-alarm"}
                            onChange={setHour}
                            hour={hour}
                            validation={hourValidation}
                            clearable={false}
                            date={date}
                        />
                    </div>
                </div>
                {hasChannels && (
                    <div className="my-4">
                        <label className="mb-2">{t("type")} *</label>
                        <Select
                            id="alarm-type"
                            allowUnselect={false}
                            optionsContainerClass="w-full"
                            placeholder={t("select-alarm-type")}
                            designClass={alarmTypeValidation}
                            options={[
                                { value: "BEEP", label: t("beep") },
                                { value: "TVCHANNEL", label: t("tv-channel") },
                            ]}
                            onChange={(value) => setAlarmType(value)}
                        />
                    </div>
                )}
                {alarmType === "TVCHANNEL" ? (
                    <div className="my-4">
                        <label className="mb-2">{t("select-channel")} *</label>
                        <Select
                            id="alarm-type-tvChannel"
                            allowUnselect={false}
                            designClass={tvChannelValidation}
                            optionsContainerClass="w-full"
                            placeholder={t("select-channel")}
                            options={getChannels(channels) || []}
                            onChange={handleSaveTVChannelId}
                        />
                    </div>
                ) : null}
            </main>
            <footer className="flex justify-end gap-4 mt-12">
                <Button id="cancel" className="btn btn-white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="save" className="btn btn-blue" onClick={handleSave}>
                    {t("save")}
                </Button>
            </footer>
        </>
    ) : (
        <Loading adjust="section-loading" />
    );
};
