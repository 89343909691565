import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Table from "components/ZafiroTable";
import UseSectionHeader from "components/useSectionHeader";
import {
    useListBanners,
    useDeleteBanner,
    useDuplicateBanner,
    useSetAvailabilityBanner,
    useUpdateBanner,
} from "../graphql/useBanners";
import {
    stateFilterOptions,
    FILTER_VALUES,
    INITIAL_BATCH_ACTIONS,
    HEADERS,
    COLUMNS_TICKER_TAPES,
    BANNER_TYPE,
} from "../constants";
import { NameColumn, FiltersColumn, StatusColumn } from "../TableColumns";
import { ScheduledDateColumn, getCurrentTime } from "../utils";
import { UseModalAddTickerTapeBanner } from "../modals/useModalAddTickerTapeBanner";
import { UseModalDelete } from "../modals/useModalDelete";
import { UseModalChangeName } from "../modals/useModalChangeName";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";
import { UseModalDuplicate } from "../modals/useModalDuplicate";
import { UseModalFilters } from "../modals/useModalFilters";

const MapRows = (props) => {
    const {
        t,
        data,
        modals,
        useDeleteHook,
        useChangeAvailabilityHook,
        useDuplicateHook,
        useChangeNameHook,
        navigate,
        getBanners,
    } = props;

    const {
        id,
        name,
        destinationInfo,
        filtersNum,
        scheduleStart,
        scheduleEnd,
        available,
        ongoing,
        timeRanges,
        contentImage,
        contentAction,
        navigationLostAlert,
    } = data;

    const {
        openModalFilters,
        openModalChangeName,
        openModalDeleteBanner,
        openModalChangeAvailability,
        openModalDuplicateBanner,
    } = modals;

    const lastShowRange = timeRanges?.sort((a, b) => b?.startTime?.localeCompare(a.startTime))?.[0]?.endTime;
    const lastDate = timeRanges ? new window.ZDate(`${scheduleEnd}T${lastShowRange}`) : new window.ZDate(scheduleEnd);

    const expired = scheduleEnd && new window.ZDate() > lastDate;
    const numberOfFilters = filtersNum;
    const couldBeAvailable =
        (contentImage?.libraryRef || contentImage?.externalUrl) && scheduleStart && numberOfFilters > 0;

    return {
        id,
        data,
        expired,
        onGoing: ongoing,
        name: NameColumn({
            t,
            name,
            id,
            navigate,
            section: "banners",
            navigationLostAlert,
        }),
        schedule_end: ScheduledDateColumn({ scheduleStart, scheduleEnd }),
        filters_num: FiltersColumn({
            t,
            name,
            filters: destinationInfo,
            numberOfFilters,
            type: BANNER_TYPE,
            openModalFilters,
            id,
        }),
        available: StatusColumn({ t, available, onGoing: ongoing, expired }),
        rowConfig: {
            actions: [
                {
                    id: "edit-name",
                    label: t("edit-name"),
                    onClick: () => {
                        openModalChangeName({
                            name,
                            id,
                            type: BANNER_TYPE,
                            useChangeNameHook,
                            callback: () => {
                                getBanners();
                            },
                        });
                    },
                },
                {
                    id: "edit-banner",
                    label: t("edit-banner"),
                    onClick: () => {
                        navigate(`/design/advertising/banners/details/${id}`);
                    },
                },
                ...(available
                    ? [
                          {
                              id: "set-as-unavailable",
                              label: t("set-as-unavailable"),
                              onClick: () => {
                                  openModalChangeAvailability({
                                      id,
                                      name,
                                      type: BANNER_TYPE,
                                      available,
                                      useChangeAvailabilityHook: useChangeAvailabilityHook,
                                      callback: () => {
                                          getBanners();
                                      },
                                  });
                              },
                          },
                      ]
                    : [
                          {
                              id: "set-as-available",
                              label: t("set-as-available"),
                              disabled: !couldBeAvailable,
                              tooltip: !couldBeAvailable ? t("the-banner-will-be-able-to-set-as-available") : null,
                              onClick: () => {
                                  openModalChangeAvailability({
                                      id,
                                      name,
                                      type: BANNER_TYPE,
                                      available,
                                      useChangeAvailabilityHook: useChangeAvailabilityHook,
                                      callback: () => {
                                          getBanners();
                                      },
                                  });
                              },
                          },
                      ]),
                {
                    id: "duplicate",
                    label: t("duplicate"),
                    onClick: () => {
                        openModalDuplicateBanner({
                            id,
                            name,
                            type: BANNER_TYPE,
                            useDuplicateHook,
                            callback: () => {
                                getBanners();
                            },
                        });
                    },
                },
                {
                    id: "delete",
                    label: t("delete"),
                    onClick: () => {
                        openModalDeleteBanner({
                            id,
                            name,
                            type: BANNER_TYPE,
                            useDeleteHook,
                            onGoing: ongoing,
                            callback: () => {
                                getBanners();
                            },
                        });
                    },
                },
            ],
        },
    };
};

const Banners = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tableBannersRef = useRef(null);

    const { getBanners: listBanners, banners, loading } = useListBanners();

    const { open: openModalAddBanner } = UseModalAddTickerTapeBanner();
    const { open: openModalDeleteBanner } = UseModalDelete();
    const { open: openModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalDuplicateBanner } = UseModalDuplicate();
    const { open: openModalChangeName } = UseModalChangeName();
    const { open: openModalFilters } = UseModalFilters();

    const [customSort, setCustomSort] = useState({ field: "id", criteria: "desc" });
    const [stateFilter, setStateFilter] = useState({
        available: null,
        onlyExpired: null,
        onlyOnGoing: null,
    });
    const [customSearch, setCustomSearch] = useState(null);
    const [customDateRangeFilter, setCustomDateRangeFilter] = useState({ from: null, to: null });
    const [batchActions, setBatchActions] = useState(INITIAL_BATCH_ACTIONS({ t }));

    function getBanners() {
        const date = getCurrentTime();
        listBanners({
            variables: {
                currentTime: date,
                orderBy: customSort,
                state: stateFilter?.state,
                available: stateFilter?.available,
                onlyOnGoing: stateFilter?.onlyOnGoing,
                onlyExpired: stateFilter?.onlyExpired,
                search: customSearch,
                scheduleFrom: customDateRangeFilter?.from
                    ? customDateRangeFilter.from.toISOString().split("T")[0]
                    : null,
                scheduleTo: customDateRangeFilter?.to ? customDateRangeFilter.to.toISOString().split("T")[0] : null,
            },
        });
        tableBannersRef.current.resetSelectedRows();
    }

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        if (!customSort) {
            setCustomSort({ field: "id", criteria: "desc" });
        }
        getBanners();
    }, [customSort, stateFilter, customSearch, customDateRangeFilter]);

    return (
        <>
            <UseSectionHeader title={["banners"]} value={"demo"} />
            <Table
                id="banners-table"
                cols={COLUMNS_TICKER_TAPES}
                search
                paginate
                showCount
                perPage={8}
                className="main-container"
                ref={tableBannersRef}
                batchActions={{
                    options: Object.values(batchActions),
                    onChange: (action, rows, callback) => {
                        if (action === batchActions?.delete?.value) {
                            openModalDeleteBanner({
                                type: BANNER_TYPE,
                                batch: true,
                                onGoing: rows.some((val) => val.onGoing),
                                batchList: rows.map((val) => ({
                                    name: val.name.props.children,
                                    id: val.id,
                                })),
                                useDeleteHook: useDeleteBanner,
                                callback: () => {
                                    callback();
                                    getBanners();
                                },
                            });
                        }
                        if (action === batchActions?.available?.value) {
                            openModalChangeAvailability({
                                batch: true,
                                type: BANNER_TYPE,
                                available: false,
                                batchList: rows.map((val) => ({
                                    name: val.name.props.children,
                                    id: val.id,
                                })),
                                useChangeAvailabilityHook: useSetAvailabilityBanner,
                                callback: () => {
                                    callback();
                                    getBanners();
                                },
                            });
                        }
                        if (action === batchActions?.unavailable?.value) {
                            openModalChangeAvailability({
                                batch: true,
                                type: BANNER_TYPE,
                                available: true,
                                batchList: rows.map((val) => ({
                                    name: val.name.props.children,
                                    id: val.id,
                                })),
                                useChangeAvailabilityHook: useSetAvailabilityBanner,
                                callback: () => {
                                    callback();
                                    getBanners();
                                },
                            });
                        }
                        if (action === batchActions?.duplicate?.value) {
                            openModalDuplicateBanner({
                                batch: true,
                                type: BANNER_TYPE,
                                batchList: rows.map((val) => ({
                                    name: val.name.props.children,
                                    id: val.id,
                                })),
                                useDuplicateHook: useDuplicateBanner,
                                callback: () => {
                                    callback();
                                    getBanners();
                                },
                            });
                        }
                    },
                }}
                topRightCorner={
                    <Button
                        design="blue"
                        id="add-banner-button"
                        onClick={() => {
                            openModalAddBanner({ getBanners, navigate, type: BANNER_TYPE });
                        }}
                    >
                        {t("add-banner")}
                    </Button>
                }
                header={HEADERS({ t })}
                rows={
                    banners?.map((item) =>
                        MapRows({
                            t,
                            data: item,
                            modals: {
                                openModalChangeName,
                                openModalDeleteBanner,
                                openModalChangeAvailability,
                                openModalDuplicateBanner,
                                openModalFilters,
                            },
                            navigate,
                            useDeleteHook: useDeleteBanner,
                            useChangeAvailabilityHook: useSetAvailabilityBanner,
                            useDuplicateHook: useDuplicateBanner,
                            useChangeNameHook: useUpdateBanner,
                            getBanners,
                        })
                    ) || []
                }
                filters={[
                    {
                        id: "filter-by-state",
                        title: t("state"),
                        multiple: true,
                        options: stateFilterOptions({ t }),
                        onChange: (val) => {
                            const filters = {
                                available: null,
                                onlyExpired: val?.includes(FILTER_VALUES.ONLY_EXPIRED) || null,
                                onlyOnGoing: val?.includes(FILTER_VALUES.ONLY_ON_GOING) || null,
                            };

                            const includesAvailable =
                                val?.includes(FILTER_VALUES.AVAILABLE) ||
                                val?.includes(FILTER_VALUES.ONLY_ON_GOING) ||
                                val?.includes(FILTER_VALUES.ONLY_EXPIRED);

                            const includesUnavailable = val?.includes(FILTER_VALUES.UNAVAILABLE);

                            if (includesAvailable || includesUnavailable) {
                                filters.available = [];
                                if (includesAvailable) filters.available.push(true);
                                if (includesUnavailable) filters.available.push(false);
                            }

                            setStateFilter(filters);
                        },
                    },
                ]}
                onChangeSelectedRows={(rows) => {
                    const disabledFilters = rows?.reduce(
                        (acc, curr) => {
                            const { type, value } = curr?.data?.contentAction || {};
                            const { libraryRef, externalUrl } = curr?.data?.contentImage || {};
                            const hasContent = type && value && (libraryRef || externalUrl);
                            const hasFilters = curr?.data?.filtersNum > 0;

                            if (!hasContent) {
                                acc.hasNotContent = true;
                                console.log("a");
                            }
                            if (!hasFilters) {
                                acc.hasNotFilters = true;
                                console.log("b");
                            }

                            return acc;
                        },
                        { hasNotContent: false, hasNotFilters: false }
                    );
                    setBatchActions((val) => {
                        return {
                            duplicate: { ...val.duplicate },
                            available: {
                                ...val.available,
                                disabled: disabledFilters?.hasNotContent || disabledFilters?.hasNotFilters,
                            },
                            unavailable: {
                                ...val.unavailable,
                            },
                            delete: { ...val.delete },
                        };
                    });
                }}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customRangeSelector={{
                    value: customDateRangeFilter,
                    onChange: setCustomDateRangeFilter,
                    hideTimePicker: true,
                    hideFutureDates: false,
                }}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            />
        </>
    );
};

export default Banners;
