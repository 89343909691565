import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";

export const HourInput = ({ id, hour, disabled, validation, onChange, clearable = true, date }) => {
    const { t } = useTranslation();
    const [valueHour, setValueHour] = useState(hour);

    const onHourPickerSet = (date) => {
        if (date) {
            const hour_value = formatDate(date);
            setValueHour(hour_value);
            onChange(hour_value);
        } else {
            setValueHour(null);
            onChange(null);
        }
    };

    const padFix = (n) => {
        return ("00" + n).match(/\d{2}$/);
    };

    const formatDate = (date) => {
        return padFix(date.getHours()) + ":" + padFix(date.getMinutes());
    };

    const isPastTime = (time) => {
        if (!date) return false;
        const selectedDate = new Date(date);
        const currentDate = new Date();
        const selectedTime = new Date(selectedDate.setHours(time.getHours(), time.getMinutes(), 0, 0));
        return selectedTime > currentDate;
    };

    const HourInput = forwardRef(({ value, onClick, onChange, onFocus, disabled }, ref) => (
        <div className={`relative w-full`}>
            <InputMask
                id={id ? `${id}-hour` : null}
                mask={"99:99"}
                required
                className={`t-filter-input pl-10 h-12 w-full mr-0
                ${!validation ? "border border-red-100" : ""}  ${disabled ? "bg-gray-300" : ""}`}
                onClick={onClick}
                onFocus={onFocus}
                onChange={onChange}
                disabled={disabled}
                value={value}
                ref={ref}
                tabIndex={0}
                placeholder={"--:--"}
            />
            <i className="absolute left-4 text-gray-700 icon icon-clock text-lg" style={{ top: "13px" }}></i>
        </div>
    ));

    return (
        <div>
            <DatePicker
                selected={valueHour ? new Date(new window.ZDate("2000-01-01T" + valueHour).toDate()) : null}
                onChange={onHourPickerSet}
                dateFormat="HH:mm"
                timeCaption={t("{{capitalize, capitalize}}", { capitalize: t("hour") })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                disabled={disabled}
                name="hour"
                isClearable={clearable && !disabled}
                filterTime={isPastTime}
                customInput={<HourInput />}
                shouldCloseOnSelect={true}
            />
        </div>
    );
};
