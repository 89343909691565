import React, { Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import useModalFeedbackHistory from "hooks/GraphqlCalls/Customers/Satisfaction/modals/useModalFeedbackHistory";
import { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { getSatisfactionValues } from "hooks/GraphqlCalls/Customers/models/Guest";

import { useMutation } from "react-apollo";
import Button from "components/Button";
import Icon from "components/Icon";
import { AssignToGroup, Checkout, MoveRoom } from "components/Section/Customers/Guest/GuestListActions";
import {
    ADD_BOOKING,
    ADD_CHECKIN,
    ASSIGN_TO_GROUP,
    CHANGE_ROOM,
    CHECKOUT,
    DELETE_UPCOMING_CHECK_IN,
    DELETE_UPCOMING_CHECK_INS,
    FILTERS_INFO,
    GET_GUESTS,
    GUEST_GROUPS,
    GUEST_GROUPS_SELECTED,
    RESEND_SELF_CHECK_IN,
    stayBookingQuery,
} from "hooks/GraphqlCalls/Customers/utils/guestsQueries";
import {
    extractGroupList,
    formatDate,
    getState,
    getUpcomingActions,
    extractRoomsList,
} from "hooks/GraphqlCalls/Customers/utils/guestsUtils";
import useGQLQuery from "hooks/useQuery";

//REVISAR
export const useGuestGroupss = () => {
    const [getGuestsGroups, { data: guestsGroups }] = useLazyQuery(GUEST_GROUPS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => getGuestsGroups(), []);

    return { guestGroups: guestsGroups?.data?.results || [] };
};

export const useGuests = () => {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const [filters, setFilters] = useState({ rooms: [], groups: [] });
    const { open: openModalFeedbackHistory } = useModalFeedbackHistory();
    const { open } = useModal();

    const q = useGQLQuery(GET_GUESTS);

    const [getFiltersInfo, { data: filtersInfo }] = useLazyQuery(FILTERS_INFO, {
        pollInterval: 60000,
    });

    useEffect(() => getFiltersInfo(), []);
    useEffect(() => {
        const guestRows = q?.data?.guests?.results.map((guest, index) => {
            const { name, surname, email, guestGroups, rooms, loyaltyRef, lastSatisfactionLevel, id } = guest;
            const satisfactionIcon = getSatisfactionValues(lastSatisfactionLevel);
            const hab = rooms?.length > 0 ? rooms[0].number : ""; // CAMBIARLO
            const roomID = rooms?.length > 0 ? rooms?.map((room) => room.roomID) : "";
            const stayFrom = rooms?.length > 0 && `${formatDate(rooms[0].stayFrom)}`;
            const stayTo = rooms?.length > 0 && `${rooms[0].stayTo ? formatDate(rooms[0].stayTo) : "-"}`;

            return {
                id,
                roomName: `${name} ${surname}`,
                roomNumber: hab,
                roomID,
                guestRooms: rooms,
                name: (
                    <Button
                        id={`guest-name-${index}`}
                        className="t-link font-bold"
                        to={`/customers/guests/guest-list/guest/${id}`}
                    >
                        {loyaltyRef && <Icon type="loyal-customers" className="text-zafiro-500" size="base" />}
                        <span>
                            {name} {surname}
                        </span>
                    </Button>
                ),
                email,
                guestGroups: guestGroups?.length > 0 ? guestGroups.map((g) => g.name).join(" - ") : "",
                rooms: (
                    <div className="flex flex-wrap">
                        {rooms?.map((room, pos) => (
                            <Fragment key={room.roomID} className="overflow-auto">
                                <Button
                                    id={`guest-rooms-${index}${pos}`}
                                    className="t-link font-bold"
                                    to={`/hotel/rooms/room/detail/${room.roomID}`}
                                >
                                    {room.number}
                                </Button>
                                {index < rooms.length - 1 && <span className="mx-1">-</span>}
                            </Fragment>
                        ))}
                    </div>
                ),
                staydate: (
                    <div>
                        <p>{stayFrom}</p>
                        <p>{stayTo}</p>
                    </div>
                ),
                feedback: <Icon type={satisfactionIcon?.icon} style={satisfactionIcon?.style} />,
                rowConfig: {
                    actions: [
                        {
                            id: "view-guest",
                            label: t("view-guest"),
                            onClick: () => (window.location.href = `#/customers/guests/guest-list/guest/${id}`),
                        },
                        {
                            id: "change-room",
                            label: t("change-room"),
                            onClick: () =>
                                open(
                                    <MoveRoom
                                        name={`${name} ${surname}`}
                                        rooms={rooms}
                                        oldID={roomID}
                                        stayGuestID={id}
                                        refetch={q.refetch}
                                    />
                                ),
                        },
                        {
                            id: "assign-to-group",
                            label: t("assign-to-group"),
                            onClick: () =>
                                open(
                                    <AssignToGroup
                                        name={`${name} ${surname}`}
                                        guestID={id}
                                        selectedGroups={guestGroups}
                                        refetch={q.refetch}
                                    />
                                ),
                        },
                        {
                            id: "feedback-history",
                            label: t("feedback-history"),
                            disabled: lastSatisfactionLevel === null,
                            onClick: () => openModalFeedbackHistory({ guestID: id }),
                        },
                        {
                            id: "check-out",
                            label: t("check-out"),
                            onClick: () => open(<Checkout guest={guest} refetch={q.refetch} />),
                        },
                    ],
                },
            };
        });
        setRows(guestRows);

        if (filtersInfo) {
            const filters = filtersInfo?.rooms;
            const rooms = extractRoomsList(filters);
            const groups = extractGroupList(filters);

            setFilters({ rooms, groups });
        }
    }, [q?.data, filtersInfo]);

    return {
        getGuests: q?.load,
        rows,
        filters,
        refetch: q?.refetch,
        ready: q?.ready,
        count: q?.data?.guests?.info?.count,
    };
};

export const useChangeRoom = () => {
    const [changeRoom] = useMutation(CHANGE_ROOM);

    return {
        changeRoom: (oldRoomID, newRoomID) => changeRoom({ variables: { oldRoomID, newRoomID } }),
    };
};
//REVISAR DUPLICADOS
export const useGuestGroups = (options) => {
    const { onSuccess, onError } = options || {};
    const [customCallbacks, setCustomCallbacks] = useState({});

    const q = useGQLQuery(GUEST_GROUPS_SELECTED, {
        onSuccess: (response) => {
            if (onSuccess) {
                onSuccess(response);
            }
            if (customCallbacks?.onSuccess) {
                customCallbacks.onSuccess(response);
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error);
            }
            if (customCallbacks?.onError) {
                customCallbacks.onError(error);
            }
        },
    });

    return {
        ...q,
        load: (id, options) => {
            const { onSuccess, onError } = options || {};
            setCustomCallbacks({ onSuccess, onError });
            q.load({ id });
        },
    };
};

export const useAssignToGroup = () => {
    const [assignToGroup] = useMutation(ASSIGN_TO_GROUP);

    return {
        assignToGroup: (id, groups) => assignToGroup({ variables: { id, groups } }),
    };
};

export const useUpcomingCheckIns = () => {
    const { t } = useTranslation();
    const { open } = useModal();

    const q = useGQLQuery(stayBookingQuery);

    const rows = q?.data?.guests?.results?.length
        ? q?.data?.guests?.results.map((guest) => {
              const {
                  id,
                  code,
                  arrivalDate,
                  departureDate,
                  numberOfGuests,
                  numberOfRooms,
                  state,
                  stayHolderEmail,
                  stayHolderName,
                  stayHolderSurname,
              } = guest;

              const name = `${stayHolderName} ${stayHolderSurname}`;
              const stayFrom = formatDate(arrivalDate);
              const stayTo = formatDate(departureDate);
              return {
                  id,
                  bookingCode: code,
                  number_of_guests: (
                      <div className="flex flex-col items-center justify-center">
                          <div className="flex gap-5">
                              <Icon type="room" size="xl" />
                              <p>{numberOfRooms}</p>
                          </div>
                          <div className="flex gap-5">
                              <Icon type="user-regular" size="2xl" />
                              <p>{numberOfGuests}</p>
                          </div>
                      </div>
                  ),
                  arrival_date: (
                      <div>
                          <p>{stayFrom}</p>
                          <p>{stayTo}</p>
                      </div>
                  ),
                  "guests.name": name,
                  "guests.email": stayHolderEmail,
                  code: (
                      <Button
                          id="booking-code"
                          to={`/customers/guests/guest-list/reservation-details/${id}`}
                          className="t-link font-bold"
                      >
                          {code}
                      </Button>
                  ),
                  state: getState(state, t),
                  rowConfig: {
                      actions: getUpcomingActions(guest, t, open, q?.refetch),
                  },
              };
          })
        : [];

    return {
        getUpcomingCheckIns: q?.load,
        upcomingCheckInsRows: rows,
        upcomingCount: q?.data?.guests?.info?.count,
        upcomingRefetch: q?.refetch,
        upcomingReady: q?.ready,
    };
};

export const useCheckout = () => {
    const [checkOut] = useMutation(CHECKOUT);

    return {
        checkOut: (rooms) => checkOut({ variables: { rooms } }),
    };
};

export const useDeleteUpcomingCheckIn = () => {
    const [deleteUpcomingCheckIn] = useMutation(DELETE_UPCOMING_CHECK_IN);

    return {
        deleteUpcomingCheckIn: (id) => deleteUpcomingCheckIn({ variables: { id } }),
    };
};

export const useDeleteUpcomingCheckIns = () => {
    const [deleteUpcomingCheckIns] = useMutation(DELETE_UPCOMING_CHECK_INS);

    return {
        deleteUpcomingCheckIns: (ids) => deleteUpcomingCheckIns({ variables: { ids } }),
    };
};

export const useResendPreCheckIn = () => {
    const [resendSelfCheckIn] = useMutation(RESEND_SELF_CHECK_IN);

    return {
        resendSelfCheckIn: (id) => resendSelfCheckIn({ variables: { id } }),
    };
};

export const useAddBooking = () => {
    const [addBooking] = useMutation(ADD_BOOKING);

    return {
        addBooking: (
            code,
            numberOfRooms,
            numberOfGuests,
            arrivalDate,
            departureDate,
            stayHolderSurname,
            stayHolderName,
            stayHolderEmail,
            pmsID
        ) =>
            addBooking({
                variables: {
                    code,
                    numberOfRooms,
                    numberOfGuests,
                    arrivalDate,
                    departureDate,
                    stayHolderSurname,
                    stayHolderName,
                    stayHolderEmail,
                    pmsID,
                },
            }),
    };
};

export const useAddCheckin = () => {
    const [addCheckin] = useMutation(ADD_CHECKIN);

    return {
        addCheckin: (stayBookingID, mainGuestID, rooms) =>
            addCheckin({ variables: { stayBookingID, mainGuestID, rooms } }),
    };
};
