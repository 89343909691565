import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSelectOption from "./useSelectOption";
import UseButton from "../useButton";
//Actions
import { openModal, setModalContent } from "../../actions/uiActions";
import { changeGlobalAction } from "../../actions/globalActions";

//Utils
import useComponentVisible from "../useComponentVisible";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Icon from "components/Icon";

const UseBatchSelect = () => {
    //Store data
    const batchActiveItems = useSelector((state) => state.table.batchActive);
    const { langStrings } = useSelector((state) => state.ui);
    const { results, checkedItems, customConfig } = useSelector((state) => state.table);
    const { t } = useTranslation();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Data
    let [availableBatchActions, setAvailableBatchActions] = useState(batchActiveItems);

    useEffect(() => {
        // No batch actions available if there are no items selected
        setAvailableBatchActions(checkedItems.length ? batchActiveItems : []);
    }, [checkedItems, batchActiveItems]);

    //States
    const [activeAction, setActiveAction] = useState();
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });

    //Actions
    const dispatch = useDispatch();

    const executeAction = (e) => {
        setIsComponentVisible(false);
        setActiveAction(e.currentTarget.dataset.id);
    };

    const applyBatchAction = (e) => {
        if (checkedItems.length === 0) {
            toast.error(langStrings["error-no-items-selected"]);
            return;
        }
        const batchAction = availableBatchActions.filter((action) => action.name === activeAction)[0];
        if (batchAction.customModal) {
            batchAction.customModal({ results, checkedItems, ...batchAction.customProps });
            setActiveAction(null);
            return;
        }
        const modal = {
            title: activeAction,
            bodyAdjust: batchAction?.bodyAdjust ? batchAction.bodyAdjust : "w-6/12",
            text: "",
            value: null,
            executeSectionQuery: batchAction.executeSectionQuery,
            inputs: [],
            buttons: [],
        };
        if (batchAction.buttons) {
            batchAction.buttons.forEach((button) => {
                modal.buttons.push(button);
            });
        } else {
            modal.buttons.push({ name: "cancel", style: "white", action: "close" });
            let buttonLabel = batchAction.name;
            if (batchAction.name === "chromecast-change-pairing") {
                buttonLabel = "change";
            }
            modal.buttons.push({
                name: buttonLabel,
                style: buttonLabel === "delete" ? "red" : "blue",
                action: batchAction.action,
            });
        }

        modal.inputs = batchAction.modalInputs;

        dispatch(
            changeGlobalAction({
                actionName: batchAction.action,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
        setActiveAction(null);
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);
    return (
        <>
            {batchActiveItems ? (
                <>
                    <Icon type="refactor" tooltip="Please, use new Select component" />
                    <div id="batch-row" className="t-filters-row batch-row">
                        <div
                            ref={ref}
                            className={`t-filter-input ${
                                customConfig && customConfig.batchStyle ? customConfig.batchStyle : "w-2/12"
                            } ${selectActive.container}`}
                        >
                            <div
                                id="batch-actions-table"
                                className={`t-filter-ph first-capital ${selectActive.text} ${
                                    availableBatchActions.length === 0 && "disabled"
                                } ${
                                    availableBatchActions.filter((action) => action.name === activeAction).length > 0
                                        ? "text-gray-900"
                                        : ""
                                }`}
                                onClick={
                                    availableBatchActions.length > 0 && checkedItems.length
                                        ? () => setIsComponentVisible(!isComponentVisible ? true : false)
                                        : null
                                }
                            >
                                {activeAction &&
                                availableBatchActions.filter((action) => action.name === activeAction).length > 0
                                    ? langStrings[activeAction] || t(activeAction)
                                    : langStrings["batch-title"]}
                            </div>
                            <span
                                id={`batch-actions-icon`}
                                className={`field-icon icon-chevron animated ${selectActive.icon} ${
                                    availableBatchActions.length === 0 && "disabled"
                                }`}
                                onClick={
                                    availableBatchActions.length > 0
                                        ? () => setIsComponentVisible(!isComponentVisible ? true : false)
                                        : null
                                }
                            ></span>
                            {isComponentVisible ? (
                                <div className="t-options-container p-2">
                                    {availableBatchActions.length > 0 ? (
                                        availableBatchActions.map((availableBatchAction, index) => (
                                            <div
                                                className="h-10 w-full hover:bg-gray-100"
                                                id={availableBatchAction.name}
                                                key={index}
                                                onClick={executeAction}
                                                data-id={availableBatchAction.name}
                                            >
                                                <UseSelectOption
                                                    id={availableBatchAction.name}
                                                    filterValue={
                                                        langStrings[availableBatchAction.name] ||
                                                        t(availableBatchAction.name)
                                                    }
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <div className="py-2 px-4">{langStrings["no-batch"]}</div>
                                    )}
                                </div>
                            ) : null}
                        </div>
                        {activeAction &&
                        !availableBatchActions.filter((action) => action.name === activeAction)?.[0]
                            ?.noReadyToExecute &&
                        availableBatchActions.filter((action) => action.name === activeAction).length > 0 ? (
                            <UseButton
                                id={"apply-button-batch-table"}
                                buttonName="apply"
                                buttonColor="btn-blue"
                                action={applyBatchAction}
                                value="demo"
                            />
                        ) : (
                            <UseButton
                                id={"apply-button-batch-table"}
                                buttonName="apply"
                                buttonColor="btn-gray"
                                value="demo"
                            />
                        )}
                    </div>
                </>
            ) : null}
        </>
    );
};

export default UseBatchSelect;
