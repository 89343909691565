import React, { useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Select from "components/Select";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { useAuth } from "hooks/Session/auth";

const GET_TUNE_GROUPS = gql`
    query GetTuneGroups {
        tuneGroups {
            id
            name
        }
    }
`;

const ADD_TUNE_GROUP_TO_GRID_BATCH = gql`
    mutation AddTuneGroupToGridBatch($tuneGroupIDs: [Int64!]!, $tuneGridID: Int64!) {
        addTuneGroupToGridBatch(tuneGroupIDs: $tuneGroupIDs, tuneGridID: $tuneGridID) {
            id
            error
            ok
        }
    }
`;

const CREATE_TUNE_GROUP = gql`
    mutation CreateTuneGroup($name: [TranslateString]!, $tuneIDs: [Int64]) {
        createTuneGroup(name: $name, tuneIDs: $tuneIDs) {
            id
            error
            ok
        }
    }
`;

const NewGroupToGrid = ({ data, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { languages: projectLangs } = useAuth();
    const { langDefault } = getProjectLangs(projectLangs, t);

    const { id: gridId, tuneGroups } = data?.tuneGridsSummary?.results?.[0] || {};

    const [selectedOption, setSelectedOption] = useState("group-list");
    const [selectedGroupIDs, setSelectedGroupIDs] = useState(tuneGroups ? tuneGroups.map((group) => group.id) : []);
    const [newGroupName, setNewGroupName] = useState("");

    const { data: groupsData } = useQuery(GET_TUNE_GROUPS);

    const queryOptions = (groupsData?.tuneGroups || []).map((group) => ({
        value: group.id,
        label: group.name,
    }));

    const availableOptions = queryOptions.filter(
        (option) => !(tuneGroups && tuneGroups.some((gridGroup) => gridGroup.id === option.value))
    );

    const selectProps = {
        id: "select-group",
        placeholder: t("select-group"),
        value: selectedGroupIDs,
        options: availableOptions,
        multiple: true,
        search: true,
        onChange: (selected) => setSelectedGroupIDs(selected),
        className: "mt-4",
    };

    const [addGroupToGrid] = useMutation(ADD_TUNE_GROUP_TO_GRID_BATCH, {
        onCompleted: (res) => {
            if (res.addTuneGroupToGridBatch.ok) {
                toast.success(t("operation-successful"));
                refetch();
                close();
            }
        },
    });

    const [createGroup] = useMutation(CREATE_TUNE_GROUP, {
        onCompleted: (res) => {
            if (res.createTuneGroup.ok) {
                const newGroupId = res.createTuneGroup.id;
                addGroupToGrid({
                    variables: {
                        tuneGroupIDs: newGroupId,
                        tuneGridID: gridId,
                    },
                });
            }
        },
    });

    const handleAdd = () => {
        if (selectedOption === "group-list") {
            if (!selectedGroupIDs || selectedGroupIDs.length === 0) {
                toast.error(t("input error"));
                return;
            }
            const initialGroupIDs = tuneGroups ? tuneGroups.map((group) => group.id) : [];
            const sortedInitial = [...initialGroupIDs].sort((a, b) => a - b);
            const sortedSelected = [...selectedGroupIDs].sort((a, b) => a - b);

            if (sortedInitial === sortedSelected) {
                toast.success(t("operation-successful"));
                refetch();
                close();
                return;
            }

            addGroupToGrid({
                variables: {
                    tuneGroupIDs: selectedGroupIDs,
                    tuneGridID: gridId,
                },
            });
        } else {
            if (!newGroupName.trim()) {
                toast.error(t("input error"));
                return;
            }
            createGroup({
                variables: {
                    name: [{ lang: langDefault, text: newGroupName }],
                    tuneIDs: [],
                },
            });
        }
    };

    return (
        <Modal
            id="add-group"
            size="medium"
            title={<h1 className="text-2xl font-bold">{t("add-group")}</h1>}
            minWidth="30rem"
            className="w-1/3 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="modal-button-cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="modal-button-add" design="blue" onClick={handleAdd}>
                        {t("add")}
                    </Button>
                </footer>
            }
        >
            <div className="mt-6">
                <div className="mb-4">{t("select-one-or-more-groups-to-add-to-the-grid")}</div>
            </div>

            {/* Opción: Seleccionar grupos existentes */}
            <div className="mt-6">
                <Radiobutton
                    id="add-group-to-grid-radio-group-list"
                    value="group-list"
                    label={t("group-list")}
                    className="mb-4"
                    checked={selectedOption === "group-list"}
                    onChange={() => setSelectedOption("group-list")}
                />
                {selectedOption === "group-list" && (
                    <Select {...selectProps} hideSelectedOptions={true} className="mt-8" />
                )}
            </div>

            {/* Opción: Crear grupo nuevo */}
            <div className="mt-6">
                <Radiobutton
                    id="add-group-to-grid-radio-new-group"
                    value="new-group"
                    label={t("new-group")}
                    className="mb-4"
                    checked={selectedOption === "new-group"}
                    onChange={() => setSelectedOption("new-group")}
                />
                {selectedOption === "new-group" && (
                    <input
                        type="text"
                        className="w-full rounded py-1 bg-gray-200 border px-4"
                        placeholder={t("name")}
                        value={newGroupName}
                        onChange={(e) => setNewGroupName(e.target.value)}
                    />
                )}
            </div>
        </Modal>
    );
};

export default NewGroupToGrid;
