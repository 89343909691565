import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PreviewRoom from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomPreview";

import { GlobalContext } from "contexts/Global";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { gql } from "apollo-boost";
import { useAuth } from "hooks/Session/auth";

const RoomPreview = () => {
    const { roomtvid, lang: forceLang, guest } = useParams();
    const { lang, project } = useAuth();
    const [roomName, setRoomName] = useState("");

    const GET_ROOMS_INFO = gql`
        query GetRoomsInfo {
            rooms(filter: { type: GUESTROOM }) {
                results {
                    id
                    name
                    number
                    roomTVs {
                        name
                        id
                    }
                }
            }
        }
    `;

    const [executeQueryRoom, { data: dataRoom }] = useMSQuery(GET_ROOMS_INFO, { fetchPolicy: "network-only" });

    useEffect(() => {
        executeQueryRoom();
    }, [executeQueryRoom]);

    useEffect(() => {
        if (dataRoom && dataRoom.rooms && dataRoom.rooms.results) {
            const room = dataRoom.rooms.results.find((room) =>
                room.roomTVs.find((roomtv) => roomtv.id === parseInt(roomtvid))
            );

            if (room) {
                const roomName = room.name;
                setRoomName(roomName);
            }
        }
    }, [dataRoom, roomtvid]);

    PreviewRoom(roomtvid, forceLang || lang, guest, roomName, project);

    return (
        <div className="w-full h-full bg-gray-100">
            <iframe
                width="1280"
                height="720"
                id="roomPreviewFrame"
                title="roomPreviewFrame"
                className=" mx-auto overflow-hidden"
            ></iframe>
        </div>
    );
};

export default RoomPreview;
