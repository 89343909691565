import { useState, useEffect } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_MAPS, GET_MAPS_AND_INTEREST_POINTS } from "./queries/maps";
import {
    CREATE_MAP,
    DELETE_MAP,
    DUPLICATE_MAP,
    UPDATE_MAP,
    CREATE_MAP_INTEREST_POINT,
    SET_INTEREST_POINT_ORDER_IN_MAP,
    UPDATE_PINS_INTEREST_POINT,
    DELETE_MAP_INTEREST_POINT,
} from "./mutations/maps";
import { useScreenLinkAlert } from "../../Advertising/modals/modalDestination/Destination/Hooks/useScreenLinkAlert";

export const useListMaps = (props) => {
    const [maps, setMaps] = useState([]);
    const { loading: loadingScreenLinkAlert, renderMapNavigationAlert } = useScreenLinkAlert();

    const [getMaps, { loading, error, data }] = useLazyQuery(GET_MAPS, {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (props.onCompleted && typeof props.onCompleted == "function") {
                props.onCompleted();
            }
        },
        onError: () => {
            if (props.onError && typeof props.onError == "function") {
                props.onError();
            }
        },
    });

    useEffect(() => {
        if (!data?.maps || loadingScreenLinkAlert) return;

        const updatedMaps = data.maps.results.map((map) => {
            if (map.interestPoints?.length > 0) {
                return {
                    ...map,
                    navigationAlert: renderMapNavigationAlert(map.interestPoints),
                };
            }
            return map;
        });

        setMaps(updatedMaps);
    }, [data, loadingScreenLinkAlert]);

    return {
        getMaps,
        loading,
        error,
        maps,
    };
};

export const useListMapsAndInterestPoints = (props) => {
    const [maps, setMaps] = useState([]);
    const [interestPoints, setInterestPoints] = useState([]);

    const [getMapsAndInterestPoints, { loading, error, data }] = useLazyQuery(GET_MAPS_AND_INTEREST_POINTS, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });

    useEffect(() => {
        if (data?.maps) {
            setMaps(data.maps.results);
        }
        if (data?.mapInterestPoints) {
            setInterestPoints(data.mapInterestPoints.results);
        }

        if (data && props.onCompleted && typeof props.onCompleted === "function") {
            props.onCompleted();
        }
    }, [data, props]);
    useEffect(() => {
        if (error && props.onError && typeof props.onError === "function") {
            props.onError(error);
        }
    }, [error, props]);

    return {
        getMapsAndInterestPoints,
        loading,
        error,
        maps,
        interestPoints,
    };
};

export const useUpdateMap = (props) => {
    const [executeMutation, { loading }] = useMutation(UPDATE_MAP, {
        onCompleted() {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted();
            }
        },
        onError(err) {
            if (props.onError && typeof props.onError === "function") {
                props.onError(err);
            }
        },
    });
    return {
        update: executeMutation,
        loading,
    };
};

export const useAddMap = (props) => {
    const [executeMutation, { loading }] = useMutation(CREATE_MAP, {
        onCompleted({ createMap }) {
            props.onCompleted({ id: createMap?.id });
        },
    });
    return {
        add: executeMutation,
        loading,
    };
};

export const useDeleteMap = (props) => {
    const [executeMutation, { loading }] = useMutation(DELETE_MAP, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        delete: executeMutation,
        loading,
    };
};

export const useDuplicateMap = (props) => {
    const [executeMutation, { loading }] = useMutation(DUPLICATE_MAP, {
        onCompleted() {
            props.onCompleted();
        },
    });

    return {
        duplicate: executeMutation,
        loading,
    };
};

export const useCreateMapInterestPoint = (props) => {
    const [executeMutation, { loading, error, data }] = useMutation(CREATE_MAP_INTEREST_POINT, {
        onCompleted() {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted();
            }
        },
        onError(error) {
            if (props.onError && typeof props.onError === "function") {
                props.onError(error);
            }
        },
    });

    return {
        create: executeMutation,
        loading,
        error,
        data,
    };
};

export const useSetInterestPointOrderInMap = (props) => {
    const [executeMutation, { loading, error, data }] = useMutation(SET_INTEREST_POINT_ORDER_IN_MAP, {
        onCompleted: (response) => {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted(response);
            }
        },
        onError: (err) => {
            if (props.onError && typeof props.onError === "function") {
                props.onError(err);
            }
        },
    });

    return {
        setInterestPointOrder: executeMutation,
        loading,
        error,
        data,
    };
};

export const useUpdatePinsInterestPoint = (props) => {
    const [executeMutation, { loading, error, data }] = useMutation(UPDATE_PINS_INTEREST_POINT, {
        onCompleted: (response) => {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted(response);
            }
        },
        onError: (err) => {
            if (props.onError && typeof props.onError === "function") {
                props.onError(err);
            }
        },
    });

    return {
        updatePinsInterestPoint: executeMutation,
        loading,
        error,
        data,
    };
};

export const useDeleteMapInterestPoint = (props) => {
    const [executeMutation, { loading, error, data }] = useMutation(DELETE_MAP_INTEREST_POINT, {
        onCompleted: (response) => {
            if (props.onCompleted && typeof props.onCompleted === "function") {
                props.onCompleted(response);
            }
        },
        onError: (err) => {
            if (props.onError && typeof props.onError === "function") {
                props.onError(err);
            }
        },
    });

    return {
        deleteMapInterestPoint: executeMutation,
        loading,
        error,
        data,
    };
};
