import React from "react";
import { DEVICE_FUNCTIONS } from "../../../../Utils/Hotel/Monitor/ListDevicesUtils";
import { LogOutUsers } from "../modals/LogOutUsers";
import { DeviceKind } from "constants/devices";

export const DevicesCards = (data, permissions, dataWifiLicenses, open) => {
    const wifiLicensesAvailable = dataWifiLicenses?.available ? Number(dataWifiLicenses.available) : 0;
    const wifiLicensesUsed = dataWifiLicenses?.used ? Number(dataWifiLicenses.used) : 0;
    const percentLicenses = Math.floor(wifiLicensesAvailable * 0.95);
    const { tvWarnigns, chromecastWarnings } = countWarnings(data);

    const makeCards = () => {
        let _cards = [];
        if (permissions?.product?.tv) {
            _cards.push({
                icon: "tv",
                count: tvWarnigns,
                title: "warnings",
                subtitle: "tv-devices",
                textColor: tvWarnigns >= 1 ? "text-red-100" : null,
                noShadow: true,
            });
        }
        if (permissions?.product?.cast) {
            _cards.push({
                icon: "chromecast",
                count: chromecastWarnings,
                title: "warnings",
                subtitle: "chromecasts",
                textColor: chromecastWarnings >= 1 ? "text-red-100" : null,
                noShadow: true,
            });
        }

        if (permissions?.product?.wifi) {
            _cards.push({
                icon: "Wifi-access",
                count: `${wifiLicensesUsed} `,
                title: `/ ${wifiLicensesAvailable}`,
                textColor: wifiLicensesUsed >= percentLicenses ? "text-red-100" : null,
                subtitle: "WiFi Users",
                actions: [
                    {
                        label: "reset-wifi-licenses",
                        onClick: () => open(<LogOutUsers />),
                    },
                ],
                noShadow: true,
            });
        }

        return _cards;
    };

    return makeCards();
};

const countWarnings = (data) => {
    let tvWarnigns = 0;
    let chromecastWarnings = 0;
    if (data.length > 0) {
        data.forEach((device) => {
            const warningLevel = device?.warning?.level;
            if (warningLevel < 2) {
                if (device.function === DEVICE_FUNCTIONS.tv.value) {
                    tvWarnigns++;
                } else if (device.function === "TV+CAST" || device.function === DEVICE_FUNCTIONS.cast.value) {
                    chromecastWarnings++;
                }
            }
        });
    }
    return { tvWarnigns, chromecastWarnings };
};
