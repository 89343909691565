import React, { forwardRef, createRef } from "react";
import Icon from "./Icon";
import Dropdown from "./Dropdown";
import classNames from "classnames";
import Button from "./Button";

export const ItemActions = forwardRef(({ actions, id, icon = "row-actions" }, ref) => {
    const dropdownRef = ref || createRef();

    const closeActions = () => {
        if (dropdownRef.current) {
            dropdownRef.current.close();
        }
    };

    const someEnabled = actions?.some((a) => a && !a?.disabled);

    return (
        <Dropdown
            id={`row-actions-${id}`}
            ref={dropdownRef}
            float={true}
            showArrow={false}
            minWidth="fit-content"
            designClass={{ dropdown: "justify-end" }}
            handler={
                <Icon
                    type={icon}
                    size="xl"
                    className={classNames({
                        "p-2": true,
                        "hover:text-zafiro-300": someEnabled,
                        "opacity-50": !someEnabled,
                    })}
                />
            }
        >
            <div className="select-none rounded shadow-lg text-left border bg-white border-gray-100 text-gray-900 flex-row">
                {actions
                    ?.filter((a) => a)
                    .map((action, index) => {
                        const actionID = (action.id || "").toLowerCase() || index + 1;
                        return (
                            <Button
                                id={`opt-row-actions-${actionID}`}
                                key={actionID}
                                to={action.to}
                                disabled={action.disabled}
                                design="text-link"
                                onClick={(e) => {
                                    if (e) {
                                        e.stopPropagation();
                                    }
                                    if (action.onClick) {
                                        action.onClick(e);
                                    }
                                    closeActions();
                                }}
                                tooltip={action.tooltip}
                                className={classNames({
                                    "px-4 py-2 w-full": true,
                                    "text-gray-500 hover:text-gray-500": action.disabled,
                                })}
                                internal={action.internal}
                            >
                                {action.label}
                            </Button>
                        );
                    })}
            </div>
        </Dropdown>
    );
});
