import Icon from "components/Icon";
import NumberInput from "components/NumberInput";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React from "react";
import { useTranslation } from "react-i18next";

export const Duration = ({ tooltipText, text, durationDays, durationHours, validation, value }) => {
    const { t } = useTranslation();
    return (
        <div className="mt-2 ml-6">
            <div className="flex items-center gap-1 ml-1">
                <p>{capitalizeFirst(text)}</p>
                <Icon type="info" size="2xl" tooltip={tooltipText} />
            </div>
            <div className="flex items-center gap-3 ml-1 mt-2 w-2/5">
                <div className="flex flex-col w-1/2">
                    <label>{capitalizeFirst(t("days"))}</label>
                    <NumberInput
                        id="days-duration"
                        type="number"
                        error={validation?.[value]}
                        value={1}
                        className="mt-2"
                        onChange={(value) => {
                            durationDays(value);
                        }}
                    />
                </div>
                <div className="flex flex-col w-1/2">
                    <label>{capitalizeFirst(t("hours"))}</label>
                    <NumberInput
                        id="hours-duration"
                        type="number"
                        error={validation?.[value]}
                        value={0}
                        className="mt-2"
                        onChange={(value) => {
                            durationHours(value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
