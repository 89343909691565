import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import Switch from "components/Switch";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import Icon from "components/Icon";

const SET_TV_SETTING = gql`
    mutation SetTVSetting($epg: Boolean!) {
        setTVSettings(epg: $epg) {
            error
            ok
        }
    }
`;

const GET_DATA = gql`
    {
        tvSetting: tvSettings {
            epg
        }
    }
`;

function ChannelsGeneralSettingsEPG() {
    const { t } = useTranslation();
    const [epgEnabled, setEpgEnabled] = useState(false);
    const [setTVSettings] = useMutation(SET_TV_SETTING);

    const [executeQuery, { data }] = useMSQuery(GET_DATA);

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data && data.tvSetting) {
            setEpgEnabled(data.tvSetting.epg);
        }
    }, [data]);

    const handleSwitchChange = async () => {
        try {
            const { data } = await setTVSettings({ variables: { epg: !epgEnabled } });
            if (data.setTVSettings.ok) {
                setEpgEnabled(!epgEnabled);
                toast.success(t("operation-successful"));
            }
        } catch (error) {
            console.error("Mutation error:", error);
        }
    };

    return (
        <div className="h-full">
            <div className="w-full flex items-center justify-between mb-5">
                <div className="flex items-center gap-2">
                    <Icon type="superuser" size="3xl" />
                    <span className="font-bold text-gray-900 text-2xl">{t("Show-EPG")}</span>
                </div>
                <Switch id="switch-EPG" checked={epgEnabled} onChange={handleSwitchChange} design="switch" />
            </div>
            <div className="w-full text-left mb-10">
                <span className="block">{t("Activate-option-EPG")}</span>
                <span className="block mt-4">{t("TV-guide-and-programming")}</span>
            </div>
        </div>
    );
}

export default ChannelsGeneralSettingsEPG;
