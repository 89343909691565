import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import { useAddTickerTape } from "../graphql/useTickerTapes";
import { toast } from "react-toastify";
import Button from "components/Button";
import TextInput from "components/TextInput";
import Loading from "components/Loading";
import { BANNER_TYPE } from "../constants";
import { useAddBanner } from "../graphql/useBanners";

const ModalAddTickerTapeBanner = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState(props.name);
    const [loading, setLoading] = useState(false);
    const [validations, setValidations] = useState({ name: true });

    const isBanner = props.type === BANNER_TYPE;

    function handleOnCompleted({ id }) {
        setLoading(false);

        if (isBanner && typeof props.getBanners === "function") {
            props.getBanners();
        } else if (typeof props.getTickerTapes === "function") {
            props.getTickerTapes();
        }

        if (typeof props.navigate === "function") {
            props.navigate(`/design/advertising/${isBanner ? "banners" : "tickertapes"}/details/${id}`);
        }

        if (typeof props.close === "function") {
            props.close();
        }
    }

    const { addTickerTape } = useAddTickerTape({
        onCompleted: handleOnCompleted,
    });

    const { addBanner } = useAddBanner({
        onCompleted: handleOnCompleted,
    });

    function validateName(inputName) {
        const isValid = !!inputName;
        setValidations((val) => ({ ...val, name: isValid }));
        return !isValid;
    }

    const handleSaveClick = () => {
        const hasError = validateName(name);

        if (!hasError) {
            if (isBanner) {
                addBanner({ variables: { name } });
            } else {
                addTickerTape({ variables: { name } });
            }
            setLoading(true);
        } else {
            toast.error(t("errors-in-red"));
        }
    };

    const handleCloseClick = () => {
        if (typeof props.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={isBanner ? t("add-banner") : t("add-ticker-tape")}
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button design="blue" id="modal-button-add" disabled={loading} onClick={handleSaveClick}>
                        {t("add")}
                    </Button>
                </>
            }
            size={"small"}
            className="p-10"
        >
            {loading ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p className="font-bold">{t("name")}*</p>
                    <p>{isBanner ? t("write-the-name-of-the-banner") : t("write-the-name-of-the-ticker-tape")}</p>
                    <TextInput
                        id={`${props.type}-input-name`}
                        error={!validations.name}
                        value={name}
                        onChange={(val) => {
                            setName(val);
                            validateName(val);
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};

export const UseModalAddTickerTapeBanner = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddTickerTapeBanner {...newProps} />);
        },
    };
};
