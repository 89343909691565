import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import Button from "components/Button";
import Modal, { useModal } from "components/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const UPDATE_GRID_NAME = gql`
    mutation UpdateTuneGrid($id: Int64!, $name: String!) {
        updateTuneGrid(id: $id, name: $name) {
            id
            error
            ok
        }
    }
`;

const EditGridName = ({ data, nameGrid, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();

    const { id } = data?.tuneGridsSummary?.results?.[0] || {};
    const [gridName, setGridName] = useState(nameGrid || "");
    const [isError, setIsError] = useState(false);

    const [updateGridName, { loading }] = useMutation(UPDATE_GRID_NAME, {
        onCompleted: (response) => {
            if (response?.updateTuneGrid?.ok) {
                if (refetch) {
                    refetch();
                }
                toast.success(t("operation-successful"));
            }
            close();
        },
    });

    const handleSave = () => {
        if (!gridName.trim()) {
            setIsError(true);
            toast.error(t("input error"));
            return;
        }
        setIsError(false);

        try {
            updateGridName({ variables: { id, name: gridName } });
        } catch (err) {
            console.error("Error en la mutación:", err);
        }
    };

    return (
        <Modal
            id="edit-grid-name"
            size="medium"
            title={
                <h1 className="text-2xl font-bold">
                    {nameGrid} - {t("edit-name")}
                </h1>
            }
            minWidth="30rem"
            className="w-1/3 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="modal-button-cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="modal-button-save" design="blue" onClick={handleSave} disabled={loading}>
                        {t("save")}
                    </Button>
                </footer>
            }
        >
            <div className="mt-6">
                <div className="mb-4">{t("edit-the-name-of-the-grid")}</div>
                <input
                    type="text"
                    id="input-grid-edit-name"
                    value={gridName}
                    onChange={(e) => setGridName(e.target.value)}
                    className={`w-full rounded py-1 leading-7 bg-gray-200 border px-4 
                        ${isError ? "border-red-500" : "border-gray-300"}`}
                />
            </div>
        </Modal>
    );
};

export default EditGridName;
