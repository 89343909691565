import React, { useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import Table from "components/ZafiroTable";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import {
    UseListSignages,
    useDeleteSignages,
    useUpdateSignage,
    useAvailabilitySignage,
    useDuplicateSignage,
} from "./graphql/useSignages";
import { UseModalChangeName } from "./modals/useModalChangeName";
import { UseModalDuplicate } from "./modals/useModalDuplicate";
import { UseModalDelete } from "./modals/useModalDelete";
import { UseModalChangeAvailability } from "./modals/useModalChangeAvailability";
import { UseModalAddSequence } from "./modals/signages/useModalAddSequence";
import { UseModalMakeDefaultSequence } from "./modals/signages/useModalMakeDefaultSequence";
import { UseModalFilters } from "./modals/useModalFilters";
import { useNavigate } from "react-router-dom";
import { ScheduledDateColumn, getCurrentTime } from "./utils";
import { NameColumn, DefaultColumn, FiltersColumn, StatusColumn } from "./TableColumns";
import {
    stateFilterOptions,
    FILTER_VALUES,
    INITIAL_BATCH_ACTIONS,
    HEADERS,
    COLUMNS_SIGNAGE,
    SIGNAGE_TYPE,
} from "./constants";

const MapRows = (props) => {
    const { t } = props;
    const {
        name,
        isDefault,
        destinationInfo,
        filtersNum,
        scheduleStart,
        scheduleEnd,
        id,
        contents,
        scheduleDailyType,
        available,
        ongoing,
        timeRanges,
        navigationLostAlert,
    } = props.data;
    const {
        openModalChangeAvailabity,
        openModalChangeName,
        openModalMakeDefault,
        openModalDuplicateSequence,
        openModalDeleteSequence,
        openModalFilters,
    } = props.modals;

    const lastShowRange = timeRanges?.sort(function (a, b) {
        return b?.startTime?.localeCompare(a.startTime);
    })?.[0]?.endTime;
    const lastDate = timeRanges
        ? new window.ZDate(`${scheduleEnd}T${lastShowRange}`)
        : new window.ZDate(`${scheduleEnd}`);
    const expired = scheduleEnd && new window.ZDate() > lastDate;
    const numberOfFilters = filtersNum;
    const couldBeAvailable = contents?.length > 0 && scheduleDailyType && numberOfFilters > 0;

    return {
        id: id,
        data: props.data,
        expired,
        onGoing: ongoing,
        name: NameColumn({ t, name, id, navigate: props.navigate, section: "signage", navigationLostAlert }),
        schedule_end: ScheduledDateColumn({ scheduleStart, scheduleEnd }),
        filters_num: FiltersColumn({
            t,
            name,
            filters: destinationInfo,
            numberOfFilters,
            isDefault,
            openModalFilters,
            type: SIGNAGE_TYPE,
            id,
        }),
        available: StatusColumn({ t, available, onGoing: ongoing, expired }),
        default: DefaultColumn({
            id,
            isDefault,
            t,
            name,
            openModal: openModalMakeDefault,
            getSignages: props.getSignages,
        }),
        rowConfig: {
            actions: [
                {
                    id: "edit-name",
                    label: t("edit-name"),
                    onClick: () => {
                        openModalChangeName({
                            name,
                            id,
                            type: SIGNAGE_TYPE,
                            useChangeNameHook: useUpdateSignage,
                            callback: () => {
                                props.getSignages();
                            },
                        });
                    },
                },
                {
                    id: "edit-sequence",
                    label: t("edit-sequence"),
                    onClick: () => {
                        props.navigate(`/design/advertising/signage/details/${id}`);
                    },
                },
                ...(!isDefault && available
                    ? [
                          {
                              id: "set-as-unavailable",
                              label: t("set-as-unavailable"),
                              onClick: () => {
                                  openModalChangeAvailabity({
                                      name,
                                      available,
                                      id,
                                      type: SIGNAGE_TYPE,
                                      useChangeAvailabilityHook: useAvailabilitySignage,
                                      callback: () => {
                                          props.getSignages();
                                      },
                                  });
                              },
                          },
                      ]
                    : !isDefault && !available
                    ? [
                          {
                              id: "set-as-available",
                              label: t("set-as-available"),
                              disabled: !couldBeAvailable,
                              tooltip: !couldBeAvailable ? t("the-sequence-will-be-able-to-set-as-available") : null,
                              onClick: () => {
                                  openModalChangeAvailabity({
                                      type: SIGNAGE_TYPE,
                                      name,
                                      available,
                                      id,
                                      useChangeAvailabilityHook: useAvailabilitySignage,
                                      callback: () => {
                                          props.getSignages();
                                      },
                                  });
                              },
                          },
                      ]
                    : []),

                {
                    id: "duplicate",
                    label: t("duplicate"),
                    onClick: () => {
                        openModalDuplicateSequence({
                            name,
                            id,
                            type: SIGNAGE_TYPE,
                            useDuplicateHook: useDuplicateSignage,
                            callback: () => {
                                props.getSignages();
                            },
                        });
                    },
                },
                {
                    id: "delete",
                    disabled: isDefault,
                    tooltip: isDefault ? t("default-sequence-cannot-be-deleted") : null,
                    label: t("delete"),
                    onClick: () => {
                        openModalDeleteSequence({
                            name,
                            id,
                            type: SIGNAGE_TYPE,
                            onGoing: ongoing,
                            useDeleteHook: useDeleteSignages,
                            callback: () => {
                                props.getSignages();
                            },
                        });
                    },
                },
            ],
        },
    };
};

const Signage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getSignages: listSignages, signages, loading } = UseListSignages();
    const [customSort, setCustomSort] = useState({ field: "is_default", criteria: "desc" });
    const [stateFilter, setStateFilter] = useState({
        available: null,
        onlyExpired: null,
        onlyOnGoing: null,
    });
    const [customSearch, setCustomSearch] = useState(null);
    const [customDateRangeFilter, setCustomDateRangeFilter] = useState({ from: null, to: null });
    const [batchActions, setBatchActions] = useState(INITIAL_BATCH_ACTIONS({ t }));

    const { open: openModalDuplicateSequence } = UseModalDuplicate();
    const { open: openModalDeleteSequence } = UseModalDelete();
    const { open: openModalChangeAvailabity } = UseModalChangeAvailability();
    const { open: openModalAddSequence } = UseModalAddSequence();
    const { open: openModalChangeName } = UseModalChangeName();
    const { open: openModalMakeDefault } = UseModalMakeDefaultSequence();
    const { open: openModalFilters } = UseModalFilters();

    function getSignages() {
        const date = getCurrentTime();

        listSignages({
            variables: {
                currentTime: date,
                orderBy: customSort,
                state: stateFilter?.state,
                available: stateFilter?.available,
                onlyOnGoing: stateFilter?.onlyOnGoing,
                onlyExpired: stateFilter?.onlyExpired,
                search: customSearch,
                scheduleFrom: customDateRangeFilter?.from
                    ? customDateRangeFilter.from.toISOString().split("T")[0]
                    : null,
                scheduleTo: customDateRangeFilter?.to ? customDateRangeFilter.to.toISOString().split("T")[0] : null,
            },
        });
    }

    useEffect(() => {
        getSignages();
    }, []);

    useEffect(() => {
        if (!customSort) {
            setCustomSort({ field: "is_default", criteria: "desc" });
        }
        getSignages();
    }, [customSort, stateFilter, customSearch, customDateRangeFilter]);

    return (
        <>
            <UseSectionHeader title={["signage"]} value={"demo"} />
            <Table
                id={"signage-table"}
                cols={COLUMNS_SIGNAGE}
                search={true}
                paginate={true}
                showCount={true}
                perPage={8}
                className={"main-container"}
                batchActions={{
                    options: Object.values(batchActions),
                    onChange: (action, rows, callback) => {
                        if (action == batchActions?.duplicate?.value) {
                            openModalDuplicateSequence({
                                type: SIGNAGE_TYPE,
                                batch: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                                useDuplicateHook: useDuplicateSignage,
                                callback: () => {
                                    callback();
                                    getSignages();
                                },
                            });
                        }
                        if (action == batchActions?.delete?.value) {
                            openModalDeleteSequence({
                                type: SIGNAGE_TYPE,
                                batch: true,
                                onGoing: rows.map((val) => val.onGoing).includes(true),
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                                useDeleteHook: useDeleteSignages,
                                callback: () => {
                                    callback();
                                    getSignages();
                                },
                            });
                        }
                        if (action == batchActions?.available?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                available: false,
                                type: SIGNAGE_TYPE,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                                useChangeAvailabilityHook: useAvailabilitySignage,
                                callback: () => {
                                    callback();
                                    getSignages();
                                },
                            });
                        }
                        if (action == batchActions?.unavailable?.value) {
                            openModalChangeAvailabity({
                                batch: true,
                                type: SIGNAGE_TYPE,
                                available: true,
                                batchList: rows.map((val) => {
                                    return { name: val.name.props.children, id: val.id };
                                }),
                                useChangeAvailabilityHook: useAvailabilitySignage,
                                callback: () => {
                                    callback();
                                    getSignages();
                                },
                            });
                        }
                    },
                }}
                topRightCorner={
                    <Button
                        design={"blue"}
                        id={"add-sequence-button"}
                        onClick={() => {
                            openModalAddSequence({ getSignages, firstSequence: signages.length == 0 });
                        }}
                    >
                        {t("add-sequence")}
                    </Button>
                }
                header={HEADERS({ t })}
                rows={
                    signages?.map((val) => {
                        return MapRows({
                            t,
                            data: val,
                            modals: {
                                openModalChangeAvailabity,
                                openModalDeleteSequence,
                                openModalDuplicateSequence,
                                openModalChangeName,
                                openModalMakeDefault,
                                openModalFilters,
                            },
                            navigate,
                            getSignages,
                        });
                    }) || []
                }
                filters={[
                    {
                        id: "filter-by-state",
                        title: t("state"),
                        multiple: true,
                        options: stateFilterOptions({ t }),
                        onChange: (val) => {
                            const filters = {
                                available: null,
                                onlyExpired: val?.includes(FILTER_VALUES.ONLY_EXPIRED) || null,
                                onlyOnGoing: val?.includes(FILTER_VALUES.ONLY_ON_GOING) || null,
                            };

                            const includesAvailable =
                                val?.includes(FILTER_VALUES.AVAILABLE) ||
                                val?.includes(FILTER_VALUES.ONLY_ON_GOING) ||
                                val?.includes(FILTER_VALUES.ONLY_EXPIRED);
                            const includesUnavailable = val?.includes(FILTER_VALUES.UNAVAILABLE);

                            if (includesAvailable || includesUnavailable) {
                                filters.available = [];
                                if (includesAvailable) filters.available.push(true);
                                if (includesUnavailable) filters.available.push(false);
                            }

                            setStateFilter(filters);
                        },
                    },
                ]}
                onChangeSelectedRows={(rows) => {
                    const disabledFilters = rows?.reduce(
                        (acc, curr) => {
                            if (curr?.data?.isDefault) {
                                acc.isDefault = true;
                            }
                            if (curr?.data?.contents?.length == 0) {
                                acc.hasNotContent = true;
                            }
                            if (curr?.data?.filtersNum == 0) {
                                acc.hasNotFilters = true;
                            }
                            return acc;
                        },
                        { isDefault: false, hasNotContent: false, hasNotFilters: false }
                    );
                    setBatchActions((val) => {
                        return {
                            duplicate: { ...val.duplicate },
                            available: {
                                ...val.available,
                                disabled: disabledFilters?.hasNotContent || disabledFilters?.hasNotFilters,
                            },
                            unavailable: {
                                ...val.unavailable,
                                disabled: disabledFilters?.isDefault,
                            },
                            delete: { ...val.delete, disabled: disabledFilters?.isDefault },
                        };
                    });
                }}
                customSort={{
                    value: customSort,
                    onChange: setCustomSort,
                }}
                customRangeSelector={{
                    value: customDateRangeFilter,
                    onChange: setCustomDateRangeFilter,
                    hideTimePicker: true,
                    hideFutureDates: false,
                }}
                customSearch={{
                    value: customSearch,
                    onChange: setCustomSearch,
                }}
            ></Table>
        </>
    );
};

export default Signage;
